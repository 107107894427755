import React from 'react';
import FullscreenLoading from '../../components/FullscreenLoading';
import { useHookstate } from '@hookstate/core';
import {
  ExclamationCircleIcon,
  LockClosedIcon,
  ArrowLeftIcon,
} from '@heroicons/react/solid';
import AuthService from '../../services/auth';
import UserService from '../../services/user';
import { Persistence } from '@hookstate/persistence';
import { globalRole, globalUser, globalAuthToken } from '../../state';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { error } from '@bctc/components';

interface Props {}

function isValidPassword(password: string) {
  if (password.length < 6) {
    return {
      success: false,
      issue: 'Your password must be at least 6 characters long',
    };
  }
  return { success: true };
}

const ForgotPasswordPage: React.FC<Props> = (props: Props) => {
  const authToken = useHookstate(globalAuthToken);
  const roleState = useHookstate(globalRole);
  const userState = useHookstate(globalUser);
  const history = useHistory();
  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (stepNumber.value === 0) {
      const { data } = await AuthService.sendForgotPasswordEmail({
        email: email.value,
      });
      if (!data) return;
      stepNumber.set(1);
    } else if (stepNumber.value === 1) {
      const { data } = await AuthService.confirmForgotPasswordCode({
        email: email.value,
        code: code.value,
      });
      if (!data) return;
      stepNumber.set(2);
    } else if (stepNumber.value === 2) {
      if (isValidPassword(newPassword.text.value).success) {
        const { data } = await AuthService.resetForgottenPassword({
          email: email.value,
          code: code.value,
          password: newPassword.text.value,
        });
        if (!data) return;
        const { data: authResponse } = await AuthService.signIn({
          username: email.value,
          password: newPassword.text.value,
        });

        if (!authResponse) return;

        globalAuthToken.set(authResponse);
        authToken.attach(Persistence('state.authToken'));

        const { data: userResponse } = await AuthService.getUserInfoByToken(
          authResponse
        );

        if (!userResponse) return;

        const { role, uid } = userResponse;
        const { data: response } = await UserService[
          role === 'student' ? 'fetchStudentByObjectId' : 'fetchTeacherById'
        ](uid as never);

        if (!response) return;
        globalRole.set(role);
        globalUser.set(response);
        roleState.attach(Persistence('state.role'));
        userState.attach(Persistence('state.user'));
        history.push('/');
      } else {
        error(isValidPassword(newPassword.text.value).issue);
      }
    }
  };
  const pastStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (stepNumber.value === 1) {
      stepNumber.set(0);
    }
  };
  const email = useHookstate('');
  const stepNumber = useHookstate(0);
  const code = useHookstate('');
  const newPassword = useHookstate({ text: '', show: false });
  return (
    <div className='inset-0 absolute flex flex-col justify-center items-center w-full h-full z-0 gap-4'>
      <div className='-z-1 absolute inset-0'>
        <FullscreenLoading />
      </div>
      <div className='bg-white shadow rounded-lg max-w-xs'>
        <div
          className={`${
            stepNumber.value === 0 ? '' : 'hidden'
          } px-4 py-5 sm:p-6`}
        >
          <div className='flex'>
            <Link
              className='bg-white rounded-full h-6 w-6 hover:bg-gray-100 transition-all'
              to='/login'
            >
              <ArrowLeftIcon className='h-4 w-4 mx-auto my-1' />
            </Link>
            <h3 className='ml-2 text-lg leading-6 font-medium text-gray-900'>
              Forgot Password
            </h3>
          </div>
          <div className='mt-2 max-w-sm text-sm text-gray-500'>
            <p>
              Put the email you used to create this account below. An email will
              be sent to reset your password.
            </p>
          </div>
          <form className='mt-5 sm:flex sm:items-center'>
            <div className='w-full sm:max-w-xs'>
              <label htmlFor='email' className='sr-only'>
                Email
              </label>
              <input
                value={email.value}
                onChange={(e) => email.set(e.target.value)}
                type='email'
                name='email'
                id='email'
                className='shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
                placeholder='Email'
              />
            </div>
            <button
              onClick={nextStep}
              type='submit'
              className='mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
            >
              Next
            </button>
          </form>
        </div>
        <div
          className={`${
            stepNumber.value === 1 ? '' : 'hidden'
          } px-4 py-5 sm:p-6 flex flex-col items-center gap-4`}
        >
          <input
            value={code.value}
            onChange={(e) => code.set(e.target.value.toUpperCase())}
            type='text'
            autoComplete='one-time-code'
            className='text-3xl text-center shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-48 border-gray-300 rounded-md'
            placeholder='Code'
            maxLength={6}
          />
          <div className='mt-2 w-48 text-sm text-gray-500'>
            <p>
              A verification code has been sent to your email. Type that code
              above and hit continue to reset your password.
            </p>
          </div>
          <div className='flex justify-between w-full'>
            <button
              onClick={pastStep}
              type='submit'
              className='px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm'
            >
              Back
            </button>
            <button
              onClick={nextStep}
              type='submit'
              className='px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm'
            >
              Next
            </button>
          </div>
        </div>
        <div
          className={`${
            stepNumber.value === 2 ? '' : 'hidden'
          } px-4 py-5 sm:p-6`}
        >
          <h3 className='block text-lg font-medium text-gray-900'>
            New Password
          </h3>
          <div className='mt-2 relative rounded-md shadow-sm'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <LockClosedIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              type={newPassword.show.value ? 'text' : 'password'}
              autoComplete='new-password'
              className={
                (isValidPassword(newPassword.text.value).success ||
                newPassword.text.value === ''
                  ? 'border-gray-300 focus:ring-sky-500 focus:border-sky-500 pr-3'
                  : 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 pr-10') +
                ' focus:outline-none block w-full sm:text-sm rounded-md pl-10 transition-colors duration-300'
              }
              aria-invalid='true'
              aria-describedby='password-error'
              value={newPassword.text.value}
              onChange={(e) => newPassword.text.set(e.target.value)}
            />
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
              <ExclamationCircleIcon
                className={`${
                  isValidPassword(newPassword.text.value).success ||
                  newPassword.text.value === ''
                    ? 'opacity-0 '
                    : ''
                }h-5 w-5 text-red-500 transition-opacity duration-300`}
                aria-hidden='true'
              />
            </div>
          </div>
          <div className='mt-2'>
            <input
              id='showpassword'
              type='checkbox'
              className='focus:ring-0 rounded w-4 h-4'
              onChange={() => newPassword.show.set(!newPassword.show.value)}
            />
            <label htmlFor='showpassword' className='pl-2 text-gray-500'>
              Show Password
            </label>
          </div>
          <p
            className={`${
              isValidPassword(newPassword.text.value).success ||
              newPassword.text.value === ''
                ? 'opacity-0 -mt-5'
                : 'mt-2'
            } pointer-events-none text-sm text-red-600 transition-all duration-300`}
          >
            Your password must be at least 6 characters
          </p>
          <button
            onClick={nextStep}
            type='submit'
            className='mt-3 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm'
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
