import React from 'react';
import { Spinner } from '@bctc/components';
interface Props {
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}
const Loading: React.FC<Props> = ({ pt, pb, pl, pr }) => {
  const constantPadding = 6;
  return (
    <div
      className={`flex items-center justify-center space-x-1 pt-${
        pt || pt == 0 ? pt : constantPadding
      } pb-${pb || pb == 0 ? pb : constantPadding} pl-${
        pl || pl == 0 ? pl : constantPadding
      } pr-${
        pr || pr == 0 ? pr : constantPadding
      } bg-white rounded-md max-w-7xl mx-auto w-full`}
    >
      <Spinner color='dark' />
    </div>
  );
};

export default Loading;
