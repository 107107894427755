import React, { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { clearToken } from '../../services/config';
import { useHookstate } from '@hookstate/core';
import { globalAuthToken } from '../../state';
import { datadogRum } from '@datadog/browser-rum';

interface Props {}
const LogoutComponent: React.FC<Props> = () => {
  const authToken = useHookstate(globalAuthToken);

  const auth = useAuth();
  useEffect(() => {
    authToken.set(null);
    clearToken();
    datadogRum.clearUser();
    datadogRum.stopSessionReplayRecording();
    auth.signOut().then(() => {
      window.location.replace('/login');
    });
  }, []);
  return (
    <div>
      <h1>Logging you out...</h1>
    </div>
  );
};

export default LogoutComponent;
