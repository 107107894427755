/* eslint-disable react/prop-types*/
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useState } from 'react';

interface Props {
  open: boolean;
  close: () => void;
  className?: string;
  width?: string;
  title?: string;
  buttonText?: string;
  onSubmit?: () => void;
  children: ReactNode;
}

const Modal: React.FC<Props> = ({
  open,
  close,
  className = '',
  width = '',
  title = '',
  buttonText = '',
  onSubmit = () => {},
  children,
}) => {
  const [show, setShow] = useState(true);
  const closeConfirm = () => {
    if (show) {
      setTimeout(() => {
        close();
        setShow(true);
      }, 300);
      setShow(false);
    }
  };
  return (
    <div
      className={`${
        open && show ? '' : 'pointer-events-none opacity-0'
      } fixed z-10 inset-0 overflow-y-auto transition duration-300`}
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
          onClick={closeConfirm}
        ></div>

        <div
          className={
            className
              ? className
              : `sm:w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 ${
                  width ? width : 'sm:max-w-lg'
                }`
          }
        >
          <div>
            {title ? (
              <h3
                className='text-lg leading-6 font-medium text-gray-900 text-center mb-3'
                id='modal-title'
              >
                {title}
              </h3>
            ) : null}
            {children}
          </div>
          <div
            className={`${
              buttonText ? 'sm:grid-cols-2' : 'sm:grid-cols-1'
            } mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense`}
          >
            {buttonText ? (
              <button
                onClick={onSubmit}
                type='button'
                className='bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm'
              >
                {buttonText}
              </button>
            ) : null}
            <button
              onClick={closeConfirm}
              type='button'
              className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
