import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { green, yellow, blue } from './constants';
import Empty from '../../components/Empty';

interface Props {
  classRankingData?: {
    grade: number;
    name: string;
    submissionGrade: number;
    attendanceGrade: number;
  }[];
}

const ClassRanking: React.FunctionComponent<Props> = ({
  classRankingData,
}: Props) => {
  return classRankingData.length ? (
    <div className='p-6 pt-0'>
      <ResponsiveContainer
        width='100%'
        height={100 * (classRankingData ? classRankingData.length : 0)}
      >
        <BarChart layout='vertical' data={classRankingData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis type='number' />
          <YAxis dataKey='name' type='category' fontSize={11} />
          <Tooltip />
          <Legend />
          <Bar dataKey='grade' name='Final Grade' fill={blue} />
          <Bar
            dataKey='submissionGrade'
            name='Submissions Grade'
            fill={green}
          />
          <Bar
            dataKey='attendanceGrade'
            name='Attendance Grade'
            fill={yellow}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Empty pt={0} />
  );
};

export default ClassRanking;
