import { unScreamingSnakeCase } from '@bctc/components';
import { useHookstate } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import Tag from '../../../components/Tag';
import { globalRole } from '../../../state';
import { CohortStatus } from '../../../types';

type Props = {
  setStatus: (status: CohortStatus) => void;
  counters: {
    ongoing: number;
    upcoming: number;
    ended: number;
    all: number;
    total: number;
  };
  status: CohortStatus;
};

const Header: React.FC<Props> = ({ setStatus, status, counters }) => {
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const whenFilterButtonsClicked =
    'z-50 outline-none ring-1 ring-blue-500 border-blue-500 border-2 border-blue-500';
  const visibleStatuses = Object.keys(CohortStatus).filter(
    (status) => role.value !== 'student' || status === 'ALL_MY_CLASSES'
  ) as CohortStatus[];
  const statusCounters = {
    ALL_CLASSES: counters.total,
    ALL_MY_CLASSES: counters.all,
    IN_SESSION: counters.ongoing,
    UPCOMING: counters.upcoming,
    ENDED: counters.ended,
  };

  // const [allTeachers, setAllTeachers] = useState([]);

  // const getTeachers = async () => {
  //   const result = await (
  //     await TeacherServices.handleGetAllTeachers()
  //   ).data.data;
  //   const arr = [];
  //   result.map((v) => {
  //     arr.push({
  //       id: v.id,
  //       title:
  //         v.profile.firstName +
  //         (v.profile.lastName === null ? '' : ' ' + v.profile.lastName),
  //     });
  //     //Some teachers don't have a lastName
  //   });
  //   setAllTeachers(arr);
  // };
  // allTeachers.length === 0 && getTeachers();

  return (
    <div className='flex flex-wrap items-center justify-between px-4 py-5 bg-white border-b border-gray-200 sm:px-6 sm:flex-nowrap'>
      <h3 className='mb-2 text-lg font-medium leading-6 text-gray-900 sm:my-auto'>
        {unScreamingSnakeCase(status)}
      </h3>
      {/* <div>
        <MultiSearchInput
          data={allTeachers}
          onChange={(v) => {
            setSelectedTeachers(v);
          }}
          value={selectedTeachers}
        />
      </div> */}
      <span className='relative z-0 inline-flex overflow-auto rounded-md shadow-sm sm:overflow-visible'>
        {visibleStatuses.map((data) => (
          <button
            key={nanoid()}
            type='button'
            className={classNames(
              'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 whitespace-nowrap',
              {
                [whenFilterButtonsClicked]: data === status,
              }
            )}
            onClick={() => {
              setStatus(data);
            }}
          >
            {unScreamingSnakeCase(data)}
            <Tag text={statusCounters[data]} />
          </button>
        ))}
      </span>
    </div>
  );
};

export default Header;
