import React, { useEffect, useState } from 'react';
import { useHookstate } from '@hookstate/core';
import { multipleChoice, text, multipleChoiceAnswer } from './questions';
import { globalNotifications } from '../../state';
import Layout from '../Layout';
import { globalBreadcrumbs } from '../../state';
import ClassService from '../../services/class';
import SurveyService from '../../services/survey';

interface Props {}
const SurveyPage: React.FunctionComponent<Props> = (props: Props) => {
  const notification = useHookstate(globalNotifications);
  const [classInfo, setClassInfo] = useState(null);
  const [option, setOption] = useState(
    new Array(multipleChoice.length).fill({ question: '', answer: '' })
  );
  const [content, setContent] = useState(
    new Array(text.length).fill({ question: '', answer: '' })
  );
  const [filledSurvey, setFilledSurvey] = useState(null);
  const classId = window.location.href.split('/survey/')[1].split('/')[0];
  const studentId = window.location.href.split('/survey/')[1].split('/')[1];

  useEffect(() => {
    const newOption = [];
    for (let i = 0; i < multipleChoice.length; i++) {
      newOption.push({ question: multipleChoice[i], answer: '' });
    }
    setOption([...newOption]);
    const newText = [];
    for (let i = 0; i < text.length; i++) {
      newText.push({ question: text[i], answer: '' });
    }
    setContent([...newText]);
  }, []);

  useEffect(() => {
    const fetchCLassInfo = async () => {
      try {
        const { data } = await ClassService.getClassInfoByClassId(+classId);
        if (data.success) {
          setClassInfo(data.data);
        } else {
          throw 'Fetch class info failed.';
        }
      } catch (err) {
        notification.set({
          duration: 3000,
          title: 'Error',
          description: err?.message || err,
          show: true,
          type: 'error',
        });
      }
    };
    const checkOldSurvey = async () => {
      try {
        const verifyResult = await SurveyService.checkSurvey(
          classId,
          studentId
        );

        if (verifyResult.data.success) {
          if (verifyResult.data.data !== true) {
            setFilledSurvey(verifyResult.data.data);
          }
        }
      } catch (err) {
        notification.set({
          duration: 3000,
          title: 'Error',
          description: err?.message || err,
          show: true,
          type: 'error',
        });
      }
    };
    fetchCLassInfo();
    checkOldSurvey();
  }, []);

  useEffect(() => {
    globalBreadcrumbs.set([
      { title: 'Home', href: '/class' },
      {
        title: classInfo
          ? `${classInfo?.title} Cohort ${classInfo?.cohort}`
          : 'Loading...',
        href: classInfo ? `/class/${classId}` : '#',
      },
      {
        title: 'Survey',
        href: classInfo ? `/survey/${classId}/${studentId}` : '#',
        isCurrent: true,
      },
    ]);
  }, [classInfo]);
  const handleChangeRadio = (index, value) => {
    const newArr = option;
    newArr[index].answer = value;
    setOption([...newArr]);
  };
  const handleChangeText = (index, value) => {
    const newArr = [...content];
    newArr[index].answer = value;
    setContent(newArr);
  };
  const handleSubmit = async () => {
    try {
      option.forEach((v) => {
        if (v.answer === '') {
          throw 'Please fill in all questions in part I.';
        }
      });

      const surveyResult = await SurveyService.createSurvey({
        classId,
        studentId,
        multipleChoice: option,
        text: content,
      });

      if (surveyResult.data.success) {
        setFilledSurvey(surveyResult.data.data);
      } else {
        throw 'Submit survey failed';
      }
    } catch (error) {
      notification.set({
        duration: 3000,
        title: 'Error',
        description: error?.message || error,
        show: true,
        type: 'error',
      });
    }
  };
  return (
    <Layout bg>
      <div className='max-w-3xl px-8 lg:max-w-7xl mx-auto'>
        <div className='bg-white p-4 sm:px-6 rounded-md lg:p-8'>
          <div className=' max-w-2xl mx-auto'>
            <p className='text-2xl mb-6 text-center'>
              Future Sphere Satisfaction Survey
            </p>
            {filledSurvey ? (
              <div className='border-dotted border-4 border-light-blue-500 rounded-md p-2 mb-4'>
                <p className='text-gray-900 text-lg'>
                  You have already submitted this survey.
                </p>
                <p className='text-gray-400 text-sm'>
                  Thank you for your great support and feedback on our courses,
                  we will read your valuable suggestions carefully. Here is your
                  discount code for $10 off on your next registration.
                </p>
                <p className='text-2xl bg-gray-300 rounded-md p-2 w-min mx-auto my-2'>
                  {filledSurvey.couponCode}
                </p>
              </div>
            ) : null}
            <p className='text-lg mb-1 text-center font-bold'>Part I</p>
            {multipleChoice.map((question, index) => (
              <div key={index} className='mb-5 mx-auto'>
                <label className='text-base font-medium text-gray-400'>
                  Question {index + 1}:
                </label>
                <p className='text-base font-medium text-gray-900'>
                  {question}
                </p>
                <fieldset className='mt-2'>
                  <legend className='sr-only'>{question}</legend>
                  <div className='space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'>
                    {multipleChoiceAnswer.map((choice) => (
                      <div key={choice.value} className='flex items-center'>
                        <input
                          onChange={() => {
                            handleChangeRadio(index, choice.value);
                          }}
                          checked={
                            filledSurvey
                              ? filledSurvey.multipleChoice[index].answer ==
                                choice.value
                              : option[index].answer === choice.value
                          }
                          disabled={filledSurvey}
                          id={choice.text + question}
                          name={question}
                          type='radio'
                          className='h-4 w-4 text-blue-600 border-gray-300'
                        />
                        <label
                          htmlFor={choice.text + question}
                          className='ml-3 block text-sm font-medium text-gray-700'
                        >
                          {choice.text}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            ))}
            <p className='text-lg mb-1 text-center'>Part II</p>
            {text.map((question, index) => (
              <div key={index} className='mb-5 mx-auto'>
                <label className='text-base font-medium text-gray-400'>
                  Question {index + 1}:
                </label>
                <p className='text-base font-medium text-gray-900 mb-2'>
                  {question}
                </p>
                <textarea
                  onChange={(e) => {
                    handleChangeText(index, e.target.value);
                  }}
                  disabled={filledSurvey}
                  value={
                    filledSurvey
                      ? filledSurvey.text[index].answer
                        ? filledSurvey.text[index].answer
                        : 'You did not fill this question.'
                      : content[index].answer
                  }
                  id={question}
                  name='short-answer'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:outline-none sm:text-sm border border-gray-300 rounded-md'
                />
              </div>
            ))}
            {filledSurvey ? null : (
              <button
                onClick={() => {
                  handleSubmit();
                }}
                type='button'
                className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none'
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SurveyPage;
