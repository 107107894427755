import React from 'react';
import { classNames } from '../../helpers/className';

interface Props {}

const tabs = [
  { name: 'Recent', current: true },
  { name: 'Old Posts', current: false },
  { name: 'Most Popular', current: false },
];

const StatusUpdates: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className='mt-6 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
      <div className='px-4 sm:px-0'>
        <div className='sm:hidden'>
          <label htmlFor='question-tabs' className='sr-only'>
            Select a tab
          </label>
          <select
            id='question-tabs'
            className='block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500'
            defaultValue={tabs.find((tab) => tab.current)?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav
            className='relative z-0 rounded-lg shadow flex divide-x divide-gray-200'
            aria-label='Tabs'
          >
            {tabs.map((tab, tabIdx) => (
              <div
                key={tab.name}
                aria-current={tab.current ? 'page' : undefined}
                className={classNames(
                  tab.current
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden='true'
                  className={classNames(
                    tab.current ? 'bg-blue-500' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default StatusUpdates;
