export const monthAbbreviations = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const weekdaysName = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const scoringStandards = [
  {
    score: 100,
    description: 'Correct and clean code',
  },
  {
    score: 98,
    description: 'Correct code that runs but not clean enough',
  },
  {
    score: 95,
    description: 'Mostly correct code that does not runs',
  },
  {
    score: 92,
    description: 'Half correct code that does not run',
  },
  {
    score: 90,
    description: 'Half correct code that does not make sense',
  },
  {
    score: 88,
    description: 'Incorrect code but on the right track',
  },
  {
    score: 85,
    description: 'Incorrect code and incomplete homework',
  },
  {
    score: 80,
    description: 'Incomplete homework that does not make sense',
  },
  {
    score: 0,
    description: 'Empty or missing homework',
  },
];

export const feedbackTemplates = [
  {
    title: 'Congratulations on good work',
    content:
      'Very good job {name}! You have delivered a phenomenonal result in this homework.',
  },
  {
    title: 'Missing Homework',
    content:
      "Hi {name}, I don't think this is the correct homework you have submitted. I'm going to temporarily give you a zero before you submit the correct homework and let me know.",
  },
  {
    title: 'Incorrect Link',
    content:
      "Hi {name}, I don't think this is the correct link you have submitted. Please double check your link before submitting it, you can check out the student manuel for instructions on how to submit the correct link.",
  },
  {
    title: "Encouragement on homework that's close to perfect",
    content:
      'Hi {name}, very good job on this homework, however, there is a minor issue with the code ..., can you try ... and resubmit the homework for another review? I believe in you, you can do this!',
  },
  {
    title: 'Critical Feedback',
    content:
      "{name}, I don't think you fully understand the homework and I don't like what I'm seeing here, please re-watch the recording and try this homework again, I believe you can do it if you re-watch the recording and try a little harder.",
  },
];
