import React from 'react';
import { useHookstate } from '@hookstate/core';
import { globalUser } from '../../state';
import { useState } from 'react';
import a from '../../services/config';
import { useEffect } from 'react';
import { ILessonMaterial } from '../../types';
import Layout from '../Layout';
import PageNotAuthorized from '../../components/NotAuthorized';

const LessonMaterialPage: React.FC = () => {
  const [authorized, setAuthorized] = useState(false);
  const lessonMaterialID =
    window.location.pathname.split('/lessonMaterials/')[1];
  const user = useHookstate(globalUser);
  const [lessonMaterial, setLessonMaterial] = useState<ILessonMaterial>();
  const [loading, setLoading] = useState(false);

  const fetchLessonMaterial = async () => {
    setLoading(true);
    const response = await a.get(`/lessonMaterials/${lessonMaterialID}`);
    setLessonMaterial(response.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchingAuthorization = async () => {
      if (user.value?.profile.teacherId) {
        setAuthorized(true);
        fetchLessonMaterial();
      } else {
        try {
          const response = await a.get(
            `/lessonMaterials/${lessonMaterialID}/${user.value.id}`,
          );
          setAuthorized(response.data.authorized);
          fetchLessonMaterial();
        } catch (error) {
          console.error('Error fetching authorization:', error);
          setAuthorized(false);
        }
      }
    };

    fetchingAuthorization();
    setLoading(false);
  }, [lessonMaterialID]);

  return (
    <>
      {!loading ? (
        <>
          {authorized ? (
            <Layout bg>
              {lessonMaterial ? (
                <div className='bg-white p-8 mx-48 rounded-md shadow-md min-h-screen'>
                  <div className='border-b border-gray-200 pb-4 mb-8 flex justify-between'>
                    <h2 className='text-4xl font-bold leading-tight'>
                      {lessonMaterial?.title}
                    </h2>
                    <p className='text-gray-600 text-lg mt-2 mr-4'>
                      {lessonMaterial?.type}
                    </p>
                  </div>
                  <div className='prose marker:text-black'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lessonMaterial?.latestContent,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className='flex justify-center mt-36'>
                  <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600'></div>
                </div>
              )}
            </Layout>
          ) : (
            <PageNotAuthorized />
          )}
        </>
      ) : null}
    </>
  );
};
export default LessonMaterialPage;
