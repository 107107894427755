import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
} from 'react';
import { useHookstate } from '@hookstate/core';
import { globalUser } from '../../state';
import { Persistence } from '@hookstate/persistence';
import Layout from '../Layout';
import PanelCard from '../../components/PanelCard';
import AnnouncementService from '../../services/announcements';
import { AnnouncementWithAnnouncer } from '../../types/';
import Modal from '../../components/Modal';
import dayjs from 'dayjs';
import UserService from '../../services/user';
import Loading from '../../components/Loading';
import Feeds from './Feeds';
import ReactHtmlParser from 'react-html-parser';
import DashboardProfilePanel from '../../components/DashboardProfilePanel';
import Empty from '../../components/Empty';
import { books } from './constants';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import LiveSessionService from '../../services/liveSession';
import { error } from '@bctc/components';
const CODE_LENGTH = 4;
export const discordInvitationLink = 'https://discord.com/invite/GtcJGw64mN';
export const studentManualLink =
  'https://bctc-files.nyc3.digitaloceanspaces.com/future-sphere-general-assests/Future_Sphere_Student_Manual.pdf';
export const deviceRecommendationLink =
  'https://bctc-files.nyc3.cdn.digitaloceanspaces.com/future-sphere-general-assests/Device%20Recommendation.pdf';

const currentDate = dayjs().format('MMDDYY');

export interface Props {}

interface CurrentAnnouncement {
  title: string;
  description: string;
  announcer: string;
  date: Date;
}

const Dashboard: React.FC<Props> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [joinCode, setJoinCode] = useState(Array(CODE_LENGTH).fill(''));

  const [announcements, setAnnouncements] = useState<
    AnnouncementWithAnnouncer[]
  >([]);
  const [currentAnnouncement, setCurrentAnnouncement] =
    useState<AnnouncementWithAnnouncer | null>(null);
  useEffect(() => {
    async function getAnnouncements() {
      try {
        setLoading(true);
        const { data: result } = await AnnouncementService.getAnnouncements({
          pageSize: 3,
        });
        if (!result) return;
        setAnnouncements(result.data);
        setLoading(false);
      } catch (err) {
        error({
          title: 'Error',
          description: `Couldn't get announcements right now, please try again later.`,
        });
        setLoading(false);
      }
    }
    getAnnouncements();
  }, []);
  const user = useHookstate(globalUser);
  const [showJoinCodeModal, setShowJoinCodeModal] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  user.attach(Persistence('state.user'));

  const handleJoinSession = useCallback(async () => {
    try {
      const code = joinCode.join('');
      if (!code || code.length !== CODE_LENGTH) throw 'Please enter the code';
      const { data } = await LiveSessionService.findFileByJoinCode({
        joinCode: code,
      });
      if (!data) throw 'Session not found';
      if (data.error) throw 'Something went wrong';
      history.push(`/live/${data.id}`);
    } catch (err) {
      error({
        title: 'Error',
        description: `Couldn't join this file right now, please try again later.`,
      });
    }
  }, [joinCode]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, CODE_LENGTH);
  }, []);

  useEffect(() => {
    const code = joinCode.join('');
    if (code.length === CODE_LENGTH) {
      handleJoinSession();
    }
  }, [joinCode, handleJoinSession]);

  const handleVerifyCodeChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (!value) return;
    const newCode = [...joinCode];
    newCode[index] = value;

    if (index < CODE_LENGTH - 1 && !!value) {
      inputRefs.current[index + 1]?.focus();
    }
    setJoinCode(newCode);
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text');

    if (pastedValue.length === CODE_LENGTH) {
      setJoinCode(pastedValue.split('').slice(0, CODE_LENGTH));
      inputRefs.current[CODE_LENGTH - 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      joinCode[CODE_LENGTH - 1] &&
      index === CODE_LENGTH - 1 &&
      event.key !== 'Enter'
    ) {
      const newCode = [...joinCode];
      newCode[index] = '';
      setJoinCode(newCode);
      return;
    }
    if (event.key === 'Backspace' && index >= 0) {
      if (joinCode[index] === '') {
        inputRefs.current[index - 1]?.focus();
      } else {
        const newCode = [...joinCode];
        newCode[index] = '';
        setJoinCode(newCode);
      }
    }
    if (event.key === 'Enter') {
      if (joinCode.join('') === '' || joinCode.join('').length !== CODE_LENGTH)
        return;
      else handleJoinSession();
    }
  };

  return (
    <Layout bg>
      <Transition.Root show={showJoinCodeModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          onClose={setShowJoinCodeModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative p-12 text-left transition-all transform rounded-lg shadow-xl w-fit overflow bg-gray-50 sm:my-8 sm:p-6'>
                  <div className=''>
                    <Dialog.Title
                      as='h3'
                      className='text-2xl font-semibold leading-6 text-center text-gray-800'
                    >
                      Enter the session code
                    </Dialog.Title>
                    <div className='flex justify-between w-full gap-3 mt-5'>
                      {joinCode.map((digit, index) => (
                        <input
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          autoFocus={index === 0}
                          ref={(el) => (inputRefs.current[index] = el)}
                          key={index}
                          type='text'
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleVerifyCodeChange(index, e)}
                          onPaste={handlePaste}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          className='w-16 h-20 text-5xl font-bold text-center text-gray-800 bg-white rounded-md outline-none caret-transparent focus:ring-blue-100 focus:ring-1'
                        />
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
        {/* Main 3 column grid */}
        <div className='grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8'>
          {/* Left column */}
          <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
            {/* Welcome panel */}
            <DashboardProfilePanel
              setShowJoinCodeModal={setShowJoinCodeModal}
            />
            <Feeds />
          </div>

          {/* Right column */}
          <div className='grid grid-cols-1 gap-4'>
            {/* Announcements */}
            <section
              aria-labelledby='announcements-title'
              className='space-y-4'
            >
              <PanelCard headerText='Announcements' divider padding={false}>
                <ul className='divide-y divide-gray-200'>
                  {loading && <Loading />}
                  {!loading ? (
                    announcements.length > 0 ? (
                      announcements.map((announcement) => (
                        <li
                          key={announcement.id}
                          onClick={() => setCurrentAnnouncement(announcement)}
                          className='px-6 py-4 cursor-pointer'
                        >
                          <div className='relative focus-within:ring-2 focus-within:ring-blue-500'>
                            <h3 className='mr-24 text-base font-semibold text-gray-800 truncate'>
                              {announcement.title}
                            </h3>
                            <p className='text-sm text-gray-700 truncate'>
                              {announcement.announcer.profile.firstName}{' '}
                              {announcement.announcer.profile.lastName}
                            </p>
                            <time
                              className='absolute top-0 right-0 flex-shrink-0 text-sm text-gray-500 whitespace-nowrap'
                              dateTime={announcement.createdAt.toString()}
                            >
                              {dayjs(announcement.createdAt).format(
                                'MMDDYY',
                              ) === currentDate
                                ? dayjs(announcement.createdAt).format('h:mm A')
                                : dayjs(announcement.createdAt).format(
                                    'MMM D, YYYY',
                                  )}
                            </time>
                            <p className='mt-1 text-sm text-gray-600 truncate'>
                              {ReactHtmlParser(announcement.content)}
                            </p>
                          </div>
                        </li>
                      ))
                    ) : (
                      <Empty
                        pt={5}
                        message='There is no announcement for now.'
                      />
                    )
                  ) : (
                    <Loading />
                  )}
                </ul>
                {announcements.length > 3 ? (
                  <a
                    href='/announcements'
                    className='flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50'
                  >
                    View all
                  </a>
                ) : null}
              </PanelCard>
              <PanelCard
                headerText="Principal's Recommended Book List"
                divider
                paddingTop
                padding={false}
              >
                <ul role='list' className='divide-y divide-gray-200'>
                  {books.map((book, i) => (
                    <li
                      key={i}
                      className='relative px-6 py-5 bg-white hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600'
                    >
                      <div className='flex justify-between space-x-3'>
                        <div className='flex-1 min-w-0'>
                          <a
                            href={book.link}
                            className='block focus:outline-none'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <span
                              className='absolute inset-0'
                              aria-hidden='true'
                            />
                            <p className='text-sm font-medium text-gray-900 truncate'>
                              {book.title}
                            </p>
                            <p className='text-sm text-gray-500 truncate'>
                              {book.author}
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className='mt-1'>
                        <p className='text-sm text-gray-600 line-clamp-2'>
                          {book.description}
                        </p>
                      </div>
                    </li>
                  ))}
                  <li className='py-0.5 rounded-lg opacity-0'></li>
                </ul>
              </PanelCard>
              <Modal
                open={currentAnnouncement !== null}
                close={() => setCurrentAnnouncement(null)}
                width='sm:max-w-2xl'
              >
                <p className='mt-4 text-2xl font-bold text-gray-900 break-words'>
                  {currentAnnouncement?.title}
                </p>
                <p className='text-lg text-gray-700'>
                  {currentAnnouncement?.announcer?.profile?.firstName}{' '}
                  {currentAnnouncement?.announcer?.profile?.lastName}
                </p>
                <p className='overflow-hidden text-base text-gray-600 break-words overflow-ellipsis'>
                  {ReactHtmlParser(currentAnnouncement?.content)}
                </p>
                <time
                  className='absolute flex-shrink-0 text-sm text-gray-500 whitespace-nowrap top-5 right-5'
                  dateTime={currentAnnouncement?.createdAt.toString()}
                >
                  {dayjs(currentAnnouncement?.createdAt).format('MMDDYY') ===
                  currentDate
                    ? dayjs(currentAnnouncement?.createdAt).format('h:mm A')
                    : dayjs(currentAnnouncement?.createdAt).format(
                        'MMM D, YYYY',
                      )}
                </time>
              </Modal>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
