import { createState } from '@hookstate/core';
import React from 'react';
import { ILink } from '../components/Breadcrumbs';
import { ServerMeta, User } from '../types';

export const globalAuthToken = createState<string | null>(null);

export const globalThirdPartyAccessToken = createState<string | null>(null);

export const globalServerMetaInfo = createState<ServerMeta>({
  host: '',
  caching: false,
  commit: '',
  version: '',
});

export const globalNotifications = createState<{
  show: boolean;
  title: string;
  description?: string;
  duration?: number;
  type: 'error' | 'success' | 'warning';
}>({
  show: false,
  title: '',
  description: '',
  duration: 0,
  type: 'success',
});

export const globalRole = createState<'student' | 'teacher'>('student');

export const globalUser = createState<User | null>(null);

export const globalSelectedTeachers = createState(null);

export const globalBreadcrumbs = createState<ILink[]>([]);

export const globalAlert = createState<{
  show: boolean;
  title: string;
  description?: string;
  type: 'info' | 'warning';
  actionFunction: () => Promise<void>;
}>({
  show: false,
  title: '',
  description: '',
  type: 'info',
  actionFunction: async () => {
    return;
  },
});
