import React, { useState, useEffect, Fragment } from 'react';
import { useHookstate } from '@hookstate/core';
import { globalUser } from '../../state';
import LiveSessionService from '../../services/liveSession';
import { PaginationProps, error, notify } from '@bctc/components';
import { useHistory } from 'react-router-dom';
import { Persistence } from '@hookstate/persistence';
import { CodingFile, PaginatedCodingFile } from '@prisma/client';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import Layout from '../Layout';
import { Pagination as PaginationComponent } from '@bctc/components';

interface Props {}

const FileListPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [createFillName, setCreateFileName] = useState('');
  const [showCreateFile, setShowCreateFile] = useState(false);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const history = useHistory();
  const [files, setFiles] = useState<PaginatedCodingFile>({
    data: [],
    total: 0,
    totalPages: 0,
    page: 0,
  });
  const FILE_LIMIT = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const changePage = async (i: number) => {
    if (i < 1) return;

    setLoading(true);

    const { data: result } = await LiveSessionService.getFilesByUserId({
      selectedOption: selectedOption,
      userId: user.value.profile.id,
      searchQuery: searchTerm,
      page: i,
      pageSize: FILE_LIMIT,
    });

    if (!result || !result.data) return;

    if (!result.data.length && result.total) return changePage(i - 1);

    setFiles({
      ...result,
      page: i,
    });

    setLoading(false);
  };

  useEffect(() => {
    changePage(1);
  }, [searchTerm, selectedOption]);

  const createNewFile = async () => {
    try {
      if (!user.value)
        throw "Couldn't create file at the moment, please try logout and login back.";
      if (!createFillName)
        throw "File name can't be empty, please enter a file name";
      if (createFillName.length > 25)
        throw 'File name must be less than 25 characters';
      if (createFillName.includes(' '))
        throw 'File name must not contain spaces';
      if (createFillName.includes('.')) throw "File name must not contain '.'";
      const { data } = await LiveSessionService.createNewFile({
        filename: createFillName,
        ownerId: user.value.profile.id,
      });
      if (!data.error) {
        notify({
          title: 'File created',
          description: `File ${createFillName} created successfully`,
        });
        navigator.clipboard.writeText(data.joinCode);
        history.push(`/live/${data.id}`);
      } else throw data.error;
    } catch (err) {
      error(
        typeof err === 'string'
          ? err
          : `Couldn't create file at the moment, please try again later.`,
      );
    }
  };

  const handleRemoveFile = async (id: string) => {
    try {
      if (!user.value)
        throw "Couldn't remove file at the moment, please try logout and login back.";
      const { data } = await LiveSessionService.removeFile({
        fileId: id,
      });
      if (!data.error) {
        setFiles((prev) => ({
          ...prev,
          total: prev.total - 1,
          totalPages: Math.ceil((prev.total - 1) / 10 ), 
          data: prev.data.filter((file) => file.id !== id),
        }));
        notify({
          title: 'File removed',
          description: `File removed successfully`,
        });
      } else throw data.error;
    } catch (err) {
      error({
        title: 'Error',
        description: `Couldn't remove file at the moment, please try again later.`,
      });
    }
  };

  const [openEditName, setOpenEditName] = useState(false);
  const [editName, setEditName] = useState('');
  const [fileId, setFileId] = useState('');

  const saveFileName = async () => {
    try {
      if (!user.value)
        throw "Couldn't update file at the moment, please try logout and login back.";
      if (!editName) throw "File name can't be empty, please enter a file name";
      if (editName.length > 25)
        throw 'File name must be less than 25 characters';
      if (editName.includes(' ')) throw 'File name must not contain spaces';
      if (editName.includes('.')) throw "File name must not contain '.'";
      const { data } = await LiveSessionService.changeFileName({
        fileId: fileId,
        filename: editName,
        ownerId: user.value.profile.id,
      });
      if (!data.error) {
        notify({
          title: 'File updated',
          description: `File ${editName} updated successfully`,
        });
        navigator.clipboard.writeText(data.joinCode);
        setFiles((prevFiles) => ({
          ...prevFiles,
          data: prevFiles.data.map((file) => {
            if (file.id === fileId) {
              return { ...file, filename: editName };
            }
            return file;
          }),
        }));
      } else throw data.error;
    } catch (err) {
      error(
        typeof err === 'string'
          ? err
          : `Couldn't update file name at the moment, please try again later.`,
      );
    }
  };

  const fetchFiles = async () => {
    setLoading(true);
    const { data } = await LiveSessionService.getFilesByUserId({
      selectedOption: selectedOption,
      userId: user.value.profile.id,
      searchQuery: searchTerm,
      page: 0,
      pageSize: FILE_LIMIT,
    });
    setFiles(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Layout bg>
      <Transition.Root show={showCreateFile} as={Fragment}>
        <Dialog as='div' className='relative z-40' onClose={setShowCreateFile}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6'>
                  <div>
                    <div className=''>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Create New File
                      </Dialog.Title>
                      <div className='mt-2 flex gap-2'>
                        <div className='w-full mt-4'>
                          <label
                            htmlFor='fileName'
                            className='block text-sm font-medium leading-6 text-gray-900'
                          >
                            File Name
                          </label>
                          <div className='mt-2'>
                            <input
                              value={createFillName}
                              onChange={(e) =>
                                setCreateFileName(e.target.value)
                              }
                              name='fileName'
                              id='fileName'
                              className='w-full block ring-1 ring-gray-200 px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none'
                              placeholder='Please enter a file name'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <button
                      type='button'
                      className='rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm'
                      onClick={() => createNewFile()}
                    >
                      Create
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={openEditName} as={Fragment}>
        <Dialog as='div' className='relative z-40' onClose={setOpenEditName}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6'>
                  <div>
                    <div className=''>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Edit File Name
                      </Dialog.Title>
                      <div className='mt-2 flex gap-2'>
                        <div className='w-full mt-4'>
                          <label
                            htmlFor='fileName'
                            className='block text-sm font-medium leading-6 text-gray-900'
                          >
                            File Name
                          </label>
                          <div className='mt-2'>
                            <input
                              value={editName}
                              onChange={(e) => setEditName(e.target.value)}
                              name='fileName'
                              id='fileName'
                              className='w-full block ring-1 ring-gray-200 px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none'
                              placeholder='Please enter a file name'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <button
                      type='button'
                      className='rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm'
                      onClick={() => {
                        saveFileName();
                        history.push(`/files`);
                        setOpenEditName(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className=' max-w-7xl m-auto px-8'>
        <div className='bg-white rounded-lg px-4 sm:px-6 lg:px-8 py-6'>
          <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
              <h1 className='text-base font-semibold leading-6 text-gray-900'>
                Files
              </h1>
              <p className='mt-2 text-sm text-gray-700'>
                Here is a list of files you have created.
              </p>
            </div>
            <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center'>
              <div className='mr-4'>
                <input
                  type='text'
                  placeholder='Search files...'
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className='h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                />
              </div>
              <div className='relative inline-block text-left mr-4'>
                <div>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    id='sort-menu'
                    aria-expanded='true'
                    aria-haspopup='true'
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selectedOption || 'Sort By'}
                    <svg
                      className='-mr-1 ml-2 h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </div>

                {isOpen && (
                  <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div
                      className='py-1'
                      role='menu'
                      aria-orientation='vertical'
                      aria-labelledby='sort-menu'
                    >
                      <button
                        className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                        role='menuitem'
                        onClick={() => handleOptionClick('Last Modified')}
                      >
                        Last Modified
                      </button>
                      <button
                        className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                        role='menuitem'
                        onClick={() => handleOptionClick('Create Date')}
                      >
                        Create Date
                      </button>
                      <button
                        className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                        role='menuitem'
                        onClick={() => handleOptionClick('Name')}
                      >
                        Name
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  setShowCreateFile(true);
                  setCreateFileName(dayjs().format('MM_DD_YYYY_hh_mm_ss_A'));
                }}
                type='button'
                className='block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Create New File
              </button>
            </div>
          </div>
          <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                {!files?.data?.length && !loading ? (
                  <div className='text-center'>
                    <p className='text-md text-gray-500'>
                      No files yet, create one now!
                    </p>
                  </div>
                ) : (
                  <table className='min-w-full divide-y divide-gray-300 border-separate border-spacing-y-2'>
                    <thead>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                        >
                          Name
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Create Date
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Last Modified
                        </th>
                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-3'
                        >
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      {files &&
                        files.data &&
                        files.data.map((file, index) => (
                          <tr key={index} className='even:bg-gray-50'>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 flex'>
                              <button
                                onClick={() => {
                                  history.push(`/live/${file.id}`);
                                }}
                                className='hover:underline hover:text-blue-600 focus:outline-none'
                              >
                                {file.filename}
                              </button>
                              <button
                                onClick={() => {
                                  setEditName(file.filename);
                                  setOpenEditName(true);
                                  setFileId(file.id);
                                }}
                                className='ml-2 text-sm text-blue-600 hover:text-blue-800 focus:outline-none'
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='h-4 w-4'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                >
                                  <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
                                </svg>
                              </button>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {dayjs(file.createdAt).format('MM/DD/YYYY')}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {dayjs(file.updatedAt).fromNow()}
                            </td>
                            <td>
                              <button
                                onClick={() => handleRemoveFile(file.id)}
                                className='text-red-500'
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {files && files.data && files.data.length > 0 && !loading ? (
                  <PaginationComponent
                    itemsPerPage={10}
                    {...files}
                    setPage={changePage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FileListPage;
