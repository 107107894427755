import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Downgraded, useHookstate } from '@hookstate/core';
import { globalNotifications } from '../../state';
import userService from '../../services/user';
import { useHistory } from 'react-router';
interface Props {}

export interface IClassCal {
  title: string;
  start: string;
  end: string;
}

const CalendarTab: React.FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const curEvents = useHookstate<IClassCal[] | null>(null);
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const setDay = (date: Date, days: number) => {
    const result = new Date(date);
    // ensure never has a negative offset
    result.setDate(
      result.getDate() +
        (days - result.getDay() < 0
          ? days - result.getDay() + 7
          : days - result.getDay())
    );
    return result;
  };
  const setHours = (date: Date, hours: number) => {
    const result = new Date(date);
    result.setHours(hours);
    return result;
  };
  const id = history.location.pathname.split('/')[2];
  useEffect(() => {
    const getCurEvents = async () => {
      try {
        let res;
        if (id.length === 8) {
          res = (await userService.fetchClassesByStudentId(+id)).data;
        } else {
          res = (await userService.fetchClassesByTeacherId(+id)).data;
        }
        if (res.success) {
          const result = res.data
            .map((v) => {
              const result = v.days
                .map((classDay) => {
                  const classes = [];
                  let startDate = setDay(
                    new Date(v.startDate.split('T')[0].split('-')),
                    classDay + 1
                  );
                  for (let i = 0; i < v.classLength; i++) {
                    startDate = addDays(startDate, 7);
                    classes.push({
                      title: v.title,
                      start: setHours(startDate, v.startTime.split(':')[0]),
                      end: setHours(startDate, v.endTime.split(':')[0]),
                    });
                  }
                  return classes;
                })
                .flat();
              return result;
            })
            .flat();
          curEvents.set(result);
        } else {
          throw res.data;
        }
      } catch (e) {
        globalNotifications.set({
          show: true,
          title: 'Error',
          description: e?.message || e,
          duration: 5000,
          type: 'error',
        });
      }
    };
    getCurEvents();
  }, []);
  return (
    <div className='mt-6 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
      <FullCalendar
        events={curEvents.value && curEvents.attach(Downgraded).get()}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView='timeGridWeek'
      />
    </div>
  );
};

export default CalendarTab;
