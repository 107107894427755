import a from './config';
import axios from 'axios';

const uploadFile =
  (uploadUrlEnding: string) =>
  async (
    file: File,
    options?: {
      onUploadProgress?: (progressEvent: ProgressEvent) => any;
      filename?: string;
    },
  ): Promise<void | string> => {
    const { data: urlRequest } = await a.post(
      '/files/' + uploadUrlEnding,
      null,
      {
        params: { filename: options?.filename || file.name },
      },
    );
    if (!urlRequest) return;
    const { uploadURL, publicURL } = urlRequest;
    const { status } = await axios.put(uploadURL, file, {
      onUploadProgress: options?.onUploadProgress,
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    });
    if (status === 200) return publicURL;
  };

export const uploadRecording = uploadFile('recording');
export const uploadAvatar = uploadFile('avatar');
