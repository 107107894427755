import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import a from './config';
import { ThirdPartyInfo } from '../types';

const signIn = (payload: {
  username: string;
  password: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/login/mixed', payload);
};

const thirdPartySinghIn = (payload: {
  thirdParty: ThirdPartyInfo;
}): Promise<AxiosResponse> => {
  return a.post('/auth/login/thirdParty', payload);
};

const addThirdPartyConnection = (payload: {
  email: string;
  thirdParty: ThirdPartyInfo;
}): Promise<AxiosResponse> => {
  return a.post('/auth/addThirdPartyConnection', payload);
};

const getThirdPartyApps = (email: string): Promise<AxiosResponse> => {
  return a.post('/auth/getThirdPartyApps', { email });
};

const removeThirdPartyConnection = (payload: {
  email: string;
  thirdParty: ThirdPartyInfo;
}): Promise<AxiosResponse> => {
  return a.post('/auth/removeThirdPartyConnection', payload);
};

const Register = (payload: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  dob: string;
  gender: string;
  locations: string[];
}): Promise<AxiosResponse> => {
  const { password, ...profile } = payload;
  return a.post('/students', {
    password,
    profile,
  });
};

const thirdPartyRegister = (payload: {
  name: string;
  avatar: string;
  email: string;
  thirdParty: ThirdPartyInfo;
  dob: string;
  gender: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/register/thirdParty', payload);
};

const getUserInfoByToken = (token: string): Promise<AxiosResponse> => {
  return a.post('/auth/verify/token', { token });
};

const getDiscordToken = (payload: {
  client_id: string;
  client_secret: string;
  grant_type: string;
  code: string;
  scope: string;
  redirect_uri: string;
}): Promise<AxiosResponse> => {
  return a.post(
    'https://discord.com/api/oauth2/token',
    queryString.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

const getDiscordUserInfo = (access_token: string): Promise<AxiosResponse> => {
  return a.get('https://discord.com/api/v6/users/@me', {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

const checkServerStatus = (): Promise<AxiosResponse> => {
  return a.get('/health');
};

const checkUserStatus = (email: string): Promise<AxiosResponse> => {
  return a.get('/auth/user/status', { params: { email } });
};

const getGithubToken = (payload: {
  client_id: string;
  client_secret: string;
  code: string;
  redirect_uri: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/githubAccessToken', queryString.stringify(payload));
};

const getGithubUserInfo = (access_token: string): Promise<AxiosResponse> => {
  return a.get('https://api.github.com/user', {
    headers: {
      Authorization: `token ${access_token}`,
    },
  });
};

const getGoogleUserInfo = (access_token: string): Promise<AxiosResponse> => {
  return a.get(
    `https://www.googleapis.com/oauth2/v3/userinfo?id_token=${access_token}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
};

const sendForgotPasswordEmail = (email: {
  email: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/resetPassword/sendCode', email);
};

const confirmForgotPasswordCode = (data: {
  email: string;
  code: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/resetPassword/validateCode', data);
};

const resetForgottenPassword = (data: {
  email: string;
  code: string;
  password: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/resetPassword/code', data);
};

const resetPassword = (payload: {
  email: string;
  oldPassword: string;
  newPassword: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/resetPassword/password', payload);
};

const loginWithSSO = (payload: { token: string }): Promise<AxiosResponse> => {
  return a.post('/auth/sso/login', payload);
};

const linkWithSSO = (payload: {
  token: string;
  password: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/sso/connect', payload);
};

const createInviteToSSO = (payload: {
  studentId?: string;
  teacherId?: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/sso/migrate/existing', payload);
};

const checkExist = (username: string): Promise<AxiosResponse> => {
  return a.post('/auth/login/checkExist', { username });
};

const bindPassword = (payload: {
  username: string;
  password: string;
}): Promise<AxiosResponse> => {
  return a.post('/auth/password/bind', payload);
};

const guestSignIn = (payload: { authCode: string }): Promise<AxiosResponse> => {
  return a.post('/auth/login/guest', payload);
};

const AuthService = {
  checkServerStatus,
  checkUserStatus,
  signIn,
  Register,
  getUserInfoByToken,
  getDiscordToken,
  getDiscordUserInfo,
  getGithubToken,
  getThirdPartyApps,
  addThirdPartyConnection,
  removeThirdPartyConnection,
  getGithubUserInfo,
  getGoogleUserInfo,
  thirdPartySinghIn,
  thirdPartyRegister,
  sendForgotPasswordEmail,
  confirmForgotPasswordCode,
  resetForgottenPassword,
  resetPassword,
  loginWithSSO,
  linkWithSSO,
  createInviteToSSO,
  checkExist,
  bindPassword,
  guestSignIn,
};

export default AuthService;
