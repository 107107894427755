import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { globalRole, globalUser } from '../../state';
import Layout from '../Layout';
import { Persistence } from '@hookstate/persistence';
import UserService from '../../services/user';
import TeacherServices from '../../services/teachers';
import classNames from 'classnames';
import Empty from '../../components/Empty';
import Header from './components/Header';
import CohortsTable from './components/Table';
import { CohortStatus, CohortWithDetails, Pagination } from '../../types';
import { Pagination as PaginationComponent } from '@bctc/components';
import { DropdownData } from '@bctc/components';
interface Props {}

const CLASS_LIMIT = 10;

const ClassesPage: React.FC<Props> = (props: Props) => {
  //get localStorage

  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const isTeacher = role.value === 'teacher';

  const [status, setStatus] = useState<CohortStatus>(
    isTeacher ? 'ALL_CLASSES' : 'ALL_MY_CLASSES',
  );
  const [loading, setLoading] = useState(true);
  const [counters, setCounters] = useState({
    ongoing: 0,
    ended: 0,
    upcoming: 0,
    all: 0,
    total: 0,
  });
  const [classes, setClasses] = useState<Pagination<CohortWithDetails>>({
    data: [],
    page: 1,
    totalPages: 0,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const whenFilterButtonsClicked =
    'z-50 outline-none ring-1 ring-blue-500 border-blue-500 border-2 border-blue-500';
  // const [selectedTeachers, setSelectedTeachers] = useState([]);

  const tabToCounter = {
    ALL_CLASSES: counters.total,
    ALL_MY_CLASSES: counters.all,
    IN_SESSION: counters.ongoing,
    ENDED: counters.ended,
    UPCOMING: counters.upcoming,
  };

  useEffect(() => {
    setPage(1);
    fetchData();
    fetchAllTeachers();
  }, [status]);

  useEffect(() => {
    const getTotalPages = async () => {
      let result;

      if (isTeacher) {
        result = await UserService.getTeacherClassCounter(+user.value.id).then(
          (res) => res.data,
        );
      } else {
        result = await UserService.getStudentClassCounter(
          user.value.id.toString(),
        ).then((res) => res.data);
      }

      if (!result) return;
      setCounters(result);
    };
    getTotalPages();
  }, []);

  const [totalAfterFiltering, setTotalAfterFiltering] = useState(0);
  const [selectedTeachersID, setSelectedTeachersID] = useState<
    DropdownData['id'][]
  >([]);
  const [allTeacherNames, setAllTeacherNames] = useState([]);

  async function fetchData() {
    setLoading(true);
    const formattedStatus = status === 'IN_SESSION' ? 'ONGOING' : status;
    const { data } = await UserService.fetchCohorts({
      page: page,
      pageSize: CLASS_LIMIT,
      status: formattedStatus,
      instructorId:
        isTeacher && status !== 'ALL_CLASSES' ? +user.value.id : undefined,
      studentId: isTeacher ? undefined : user.value.profile.studentId,
      selectedTeachers: selectedTeachers,
      selectedDays: selectedDays,
    });
    if (!data) return;

    setClasses({ data: data.data, page: 1, totalPages: 1, total: 10 });
    setTotalAfterFiltering(data.total);

    setLoading(false);
  }

  async function fetchAllTeachers() {
    setLoading(true);
    const { data } = await TeacherServices.handleGetAllTeachers();
    const allTeacherNames: DropdownData[] = data.data.map((teacher) => {
      const name = teacher.profile.lastName
        ? `${teacher.profile.firstName} ${teacher.profile.lastName}`
        : `${teacher.profile.firstName}`;
      return { id: teacher.id, title: name };
    });
    setAllTeacherNames(allTeacherNames.sort());
    setLoading(false);
  }

  const selectedTeachers = selectedTeachersID
    .map((id) => {
      const teacher = allTeacherNames.find((teacher) => teacher.id === id);
      return teacher ? teacher.title : null;
    })
    .filter((title) => title !== null);

  const handleSelectedTeachersChange = (selectedTeachers) => {
    setSelectedTeachersID(selectedTeachers);
  };


  const [selectedDayIDs, setSelectedDayIDs] = useState<DropdownData[]>([]);
  const handleSelectedDayChange = (selectedDaysIDs) => {
    setSelectedDayIDs(selectedDaysIDs);
  };

  useEffect(() => {
    fetchData();
  }, [page, selectedTeachersID, selectedDayIDs]);

  const selectedDays = selectedDayIDs.map(id => Number(id));

  return (
    <Layout bg>
      {counters.total !== 0 || isTeacher || loading ? (
        // currentPage ? (
        <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
          {/* Main 3 column grid */}
          <div className='grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8'>
            {/* Left column */}
            <div className='grid grid-cols-1 gap-4 lg:col-span-4'>
              <div className='overflow-y-hidden overflow-x-auto sm:overflow-x-hidden bg-white shadow sm:rounded-md'>
                <Header
                  setStatus={setStatus}
                  counters={counters}
                  status={status}
                  // setSelectedTeachers={setSelectedTeachers}
                  // selectedTeachers={selectedTeachers}
                />
                <CohortsTable
                  loading={loading}
                  cohortsData={classes.data}
                  teacherNames={allTeacherNames}
                  onSelectedTeachersChange={handleSelectedTeachersChange}
                  onSelectedDayChange={handleSelectedDayChange}
                />
                {tabToCounter[status] > 10 && classes?.total ? (
                  <div className='flex items-center justify-end w-full px-4 py-3 bg-white border-t border-gray-200 sm:px-0 sm:grid sm:grid-cols-1'>
                    <div className='flex justify-end flex-1 sm:hidden'>
                      <button
                        disabled={page === 0}
                        onClick={() => setPage(page - 1)}
                        className={classNames(
                          page === 0
                            ? 'cursor-not-allowed bg-gray-100'
                            : 'bg-white hover:bg-gray-50',
                          'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700',
                        )}
                      >
                        Previous
                      </button>
                      <button
                        disabled={tabToCounter[status] / 10 <= page + 1}
                        onClick={() => setPage(page + 1)}
                        className={classNames(
                          tabToCounter[status] <= page + 1
                            ? 'cursor-not-allowed bg-gray-100'
                            : 'bg-white hover:bg-gray-50',
                          'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700',
                        )}
                      >
                        Next
                      </button>
                    </div>
                    {selectedTeachersID.length === 0 &&
                    selectedDayIDs.length === 0 ? (
                      <div className='hidden sm:grid'>
                        <PaginationComponent
                          page={page}
                          setPage={setPage}
                          itemsPerPage={CLASS_LIMIT}
                          total={tabToCounter[status]}
                        />
                      </div>
                    ) : (
                      <PaginationComponent
                        page={page}
                        setPage={setPage}
                        itemsPerPage={CLASS_LIMIT}
                        total={totalAfterFiltering}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
          <Empty message='You are currently not enrolled in any classes.' />
        </div>
      )}
    </Layout>
  );
};

export default ClassesPage;
