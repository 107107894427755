import { unScreamingSnakeCase, ToolTip } from '@bctc/components';
import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/outline';
import { nanoid } from 'nanoid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { placeholderAvatar } from '../../constants/assets';
import { CohortWithDetails } from '../../types';
import { weekdaysName } from './constants';

interface Props extends CohortWithDetails {
  role: 'student' | 'teacher';
}

const maxStudentsDisplay = 4;

const ClassListItem: React.FC<Props> = ({
  id,
  role,
  course,
  cohortNumber,
  instructor,
  frequency,
  startDate,
  endDate,
  sessions,
  students,
  locationId,
}: Props) => {
  const history = useHistory();
  const ongoing =
    new Date(startDate).getTime() < new Date().getTime() &&
    new Date().getTime() < new Date(endDate).getTime();

  const upcoming = new Date(startDate).getTime() > new Date().getTime();
  const ended = new Date(endDate).getTime() < new Date().getTime();

  return (
    <tr
      key={nanoid()}
      onClick={() => history.push(`/class/${id}`)}
      className='cursor-pointer hover:bg-gray-50'
    >
      <td className='px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap w-1/100'>
        <div className='flex items-center space-x-3 lg:pl-2'>
          {ongoing && (
            <ToolTip tooltip='Class is ongoing'>
              <div
                className={
                  'flex-shrink-0 w-2.5 h-2.5 rounded-full bg-green-500'
                }
              />
            </ToolTip>
          )}
          {upcoming && (
            <ToolTip tooltip='Class is upcoming'>
              <div
                className={
                  'flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500'
                }
              />
            </ToolTip>
          )}
          {ended && (
            <ToolTip tooltip='Class has ended'>
              <div
                className={'flex-shrink-0 w-2.5 h-2.5 rounded-full bg-red-500'}
              />
            </ToolTip>
          )}

          <a href='#' className='truncate hover:text-gray-600'>
            <span>
              {course.title}{' '}
              <span className='font-normal text-gray-500'>
                C-{cohortNumber}
              </span>
              <span
                className={
                  locationId === 'flushing'
                    ? 'inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-xs font-medium ml-2 p-1 text-purple-800'
                    : locationId === 'brooklyn'
                    ? 'inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium ml-2 p-1 text-yellow-800'
                    : 'inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium ml-2 p-1 text-blue-800'
                }
              >
                {unScreamingSnakeCase(locationId)}
              </span>
            </span>
          </a>
        </div>
        <div>
          <div className='flex pt-2 text-sm font-medium text-blue-600 truncate lg:pl-4'>
            {role === 'teacher' && (
              <div className='items-center ml-2 overflow-auto pb-3 sm:pb-0 flex sm:w-80'>
                {sessions?.map(
                  (v, i) =>
                    ongoing &&
                    (v.recording || v.attendance.length ? (
                      <CheckCircleIcon
                        key={i}
                        className={`mt-2 flex items-center text-sm sm:mt-0 sm:ml-${
                          i === 0 ? '2' : '0'
                        } flex-shrink-0 mr-.5 h-3 w-3 text-green-400`}
                        aria-hidden='true'
                      />
                    ) : (
                      <MinusCircleIcon
                        key={i}
                        className={`mt-2 flex items-center text-sm sm:mt-0 sm:ml-${
                          i === 0 ? '2' : '0'
                        } flex-shrink-0 mr-.5 h-3 w-3 text-gray-400`}
                        aria-hidden='true'
                      />
                    ))
                )}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className='px-6 py-3 text-sm font-medium text-left text-gray-500 table-cell whitespace-nowrap'>
        {instructor.profile.firstName} {instructor.profile.lastName}
      </td>
      <td className='px-6 py-3 text-sm font-medium text-gray-500'>
        <div className='flex items-center space-x-2'>
          <div className='flex flex-shrink-0 -space-x-1'>
            {students.length
              ? students
                  .slice(0, 4)
                  .map((member) => (
                    <img
                      key={nanoid()}
                      className='w-6 h-6 rounded-full shadow-sm max-w-none ring-2 ring-blue-300'
                      src={member.student.profile.avatar || placeholderAvatar}
                      onError={(e) => (e.currentTarget.src = placeholderAvatar)}
                      alt=''
                    />
                  ))
              : 'No students'}
          </div>
          {students.length > maxStudentsDisplay ? (
            <span className='flex-shrink-0 text-xs font-medium leading-5'>
              +{students.length - maxStudentsDisplay}
            </span>
          ) : null}
        </div>
      </td>
      <td className='px-6 py-3 text-sm text-right text-gray-500 table-cell whitespace-nowrap'>
        {frequency[0]
          ? `Every ${weekdaysName[+frequency[0]?.dayOfTheWeek]} At ${
              frequency[0]?.startTime
            } - ${frequency[0]?.endTime} EST`
          : 'No schedule'}
      </td>
    </tr>
  );
};

export default ClassListItem;
