import React from 'react';

interface Props {
  message?: string;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}
const Empty: React.FC<Props> = ({ message, pt, pb, pl, pr }) => {
  const constantPadding = 6;
  return (
    <div className='flex items-center justify-center bg-white rounded-md mx-auto max-w-7xl w-full'>
      <main
        className={`flex-grow flex flex-col pt-${
          pt || pt == 0 ? pt : constantPadding
        } pb-${pb || pb == 0 ? pb : constantPadding} pl-${
          pl || pl == 0 ? pl : constantPadding
        } pr-${pr || pr == 0 ? pr : constantPadding}`}
      >
        <div className='flex-shrink-0 my-auto'>
          <p className='text-sm font-semibold text-blue-500 uppercase tracking-wide'>
            No Data
          </p>
          <p className='mt-1 text-base text-gray-500'>
            {message ? message : 'No data available at this time'}
          </p>
        </div>
      </main>
    </div>
  );
};

export default Empty;
