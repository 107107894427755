export const logoUrl =
  'https://bctc-files.nyc3.digitaloceanspaces.com/portal-v2-images/logo.png';

export const placeholderAvatar =
  'https://bctc-files.nyc3.digitaloceanspaces.com/portal-v2-images/placeholder-avatar.jpg';
export const exampleTeacherAvatar =
  'https://res.cloudinary.com/dtgh01qqo/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1611165217/FutureSphere/jin.jpg';

export const whiteLogoUrl =
  'https://bctc-files.nyc3.digitaloceanspaces.com/portal-v2-images/logowhite.png';
