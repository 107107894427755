import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { datadogLogs } from '@datadog/browser-logs';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { datadogRum } from '@datadog/browser-rum';
const CURRENT_VERSION = import.meta.env.PACKAGE_VERSION;
dayjs.extend(utc);

datadogLogs.init({
  clientToken: 'pubc2269ffc34b6eccb004c55c94cbeb633',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  service: 'fs-portal-v2',
  sampleRate: 100,
  beforeSend: (data) => {
    const user = localStorage.getItem('state.user');
    if (user === null || user === undefined) {
      data.user = {
        id: 'anonymous',
      };
    } else {
      const parsedUser = JSON.parse(user);
      if (parsedUser === null) {
        return;
      }
      data.user = {
        uid: parsedUser._id,
        name: parsedUser.name,
      };
    }
  },
});

datadogRum.init({
  applicationId: '573ddc5c-ea85-46ce-b25c-8ed3ce939435',
  clientToken: 'pubfdde892e661668b54a6af28c230f0b9f',
  site: 'us5.datadoghq.com',
  service: 'fs-portal',
  version: import.meta.env.PACKAGE_VERSION + '.' + __COMMIT_HASH__,
  env: import.meta.env.VITE_NODE_ENV === 'production' ? 'prod' : 'dev',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const isV3 = localStorage.getItem(`isV${CURRENT_VERSION}`);
if (isV3 === null) {
  localStorage.clear();
  window.location.reload();
}
localStorage.setItem(`isV${CURRENT_VERSION}`, 'true');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
