import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import SubmissionsChart from './SubmissionsChart';
import GeneralStats from './GeneralStats';
import ClassRanking from './ClassRanking';
import PanelCard from '../../components/PanelCard';
import { useHookstate } from '@hookstate/core';
import { globalRole, globalUser } from '../../state';
import { Persistence } from '@hookstate/persistence';
import { CohortEnrollmentWithDetails } from '../../types';
import StudentService from '../../services/student';
import { placeholderAvatar } from '../../constants/assets';
import Empty from '../../components/Empty';
import AttendanceStats from './AttendanceStats';
import PageNotFound from '../../components/PageNotFound';

interface Props {}

const GradesPage: React.FunctionComponent<Props> = (props: Props) => {
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const studentId = window.location.href.split('/grades/')[1];
  const classes = user.cohorts.value as CohortEnrollmentWithDetails[];
  const [currentClassIndex, setCurrentClassIndex] = useState<number>(0);
  const [status, setStatus] = useState('ALL_CLASSES');
  const [classInfo, setClassInfo] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const isTeacher = role.value === 'teacher';

  useEffect(() => {
    setLoading(true);
    const fetchGradeData = async () => {
      const { data: response } = await StudentService.fetchGradingData(
        studentId
      );
      if (!response) return;
      setClassInfo(response);
    };
    fetchGradeData();
    setLoading(false);
  }, []);

  const filterClasses = () => {
    if (status === 'IN_SESSION') {
      return classes.filter((v) => new Date(v.cohort.endDate) > new Date());
    } else if (status === 'ENDED') {
      return classes.filter((v) => new Date(v.cohort.endDate) < new Date());
    }
    return classes;
  };

  return !isTeacher ? (
    classInfo && classInfo.length ? (
      <Layout bg>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
          {/* Main 3 column grid */}
          <div className='grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8'>
            {/* Left column */}
            <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
              <GeneralStats
                gradeStats={classInfo[currentClassIndex].generalData}
              />
              <PanelCard headerText='Attendance Statistics' padding={false}>
                <AttendanceStats
                  attendance={classInfo[currentClassIndex].attendanceData}
                />
              </PanelCard>
              <PanelCard headerText='Chronic Submission Grades' padding={false}>
                <SubmissionsChart
                  classInfo={classInfo[currentClassIndex].submissionChartData}
                />
              </PanelCard>
              <PanelCard
                headerText='Class Ranking By Final Grade'
                padding={false}
              >
                <ClassRanking
                  classRankingData={
                    classInfo[currentClassIndex].rankingChartData
                  }
                />
              </PanelCard>
            </div>

            <div>
              <PanelCard headerText='Student Info' padding={false}>
                <div
                  className='px-4 pb-4 flex cursor-pointer rounded-lg'
                  onClick={() => window.open(`/student/${user.id.value}`)}
                >
                  <img
                    className='h-10 w-10 rounded-full'
                    src={user.profile.avatar.value || placeholderAvatar}
                    alt='student avatar'
                  />
                  <div className='ml-3 flex flex-col'>
                    <span className='text-sm font-medium text-gray-900'>
                      {`${user.profile.firstName.value} ${user.profile.lastName.value}`}
                    </span>
                    <span className='text-sm text-gray-500'>
                      {user.id.value}
                    </span>
                  </div>
                </div>
              </PanelCard>
              <div className='bg-white shadow overflow-hidden sm:rounded-lg mt-4'>
                <div className='bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center justify-between flex-wrap sm:flex-nowrap'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900'>
                    Classes
                  </h3>
                  <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                    <button
                      type='button'
                      className='relative inline-flex items-center px-4 py-3 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ring-blue-500'
                      onClick={() => setStatus('ALL_CLASSES')}
                    >
                      All
                    </button>
                    <button
                      type='button'
                      className='-ml-px relative inline-flex items-center px-4 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500'
                      onClick={() => setStatus('IN_SESSION')}
                    >
                      In Session
                    </button>
                    <button
                      type='button'
                      className='-ml-px relative inline-flex items-center px-4 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500'
                      onClick={() => setStatus('ENDED')}
                    >
                      Ended
                    </button>
                  </span>
                </div>
                <ul className='divide-y divide-gray-200'>
                  {classes ? (
                    filterClasses().map((v, i) => (
                      <li key={i}>
                        <div
                          className={`block hover:bg-gray-50 cursor-pointer ${
                            !(i === currentClassIndex) || 'bg-gray-100'
                          }`}
                          onClick={() => setCurrentClassIndex(i)}
                        >
                          <div className='px-4 py-4 sm:px-6'>
                            <div className='flex items-center justify-between'>
                              <p className='text-sm font-medium text-blue-600 truncate'>
                                {`${v.cohort.course.title} Cohort ${v.cohort.cohortNumber}`}
                              </p>
                              {new Date(v.cohort.endDate) > new Date() ? (
                                <div className='ml-2 flex-shrink-0 flex'>
                                  <p className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                                    In session
                                  </p>
                                </div>
                              ) : (
                                <div className='ml-2 flex-shrink-0 flex'>
                                  <p className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800'>
                                    Ended
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className='mt-2 sm:flex sm:justify-between'>
                              <div className='sm:flex'>
                                <p className='flex items-center text-sm text-gray-500 sm:mt-0'>
                                  {`${classInfo[i].generalData.grade}%`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <Empty />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    ) : (
      !loading && (
        <Layout bg>
          <div className='text-center'>
            <img
              src='https://loan365.ie/a-images/home/image-4-6.svg'
              className='mx-auto my-auto'
            />
            <h3 className='text-sm text-center font-medium text-gray-900'>
              No data
            </h3>
            <p className='text-sm text-gray-500 text-center'>
              There is currently no data to display
            </p>
          </div>
        </Layout>
      )
    )
  ) : (
    <PageNotFound />
  );
};

export default GradesPage;
