import React from 'react';
import StudentService from '../../services/student';
import { useHookstate } from '@hookstate/core';
import { globalUser } from '../../state';
import { Persistence } from '@hookstate/persistence';
import { Button, PanelCard, error, notify } from '@bctc/components';
import Navbar from '../../components/Navbar';
import Layout from '../Layout';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

interface IPlacementExamQuestion {
  question: string;
  options: string[];
}

const PlacementExamPage: React.FC = () => {
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const [loading, setLoading] = React.useState(true);
  const [exam, setExam] = React.useState<IPlacementExamQuestion[]>([]);
  const [answers, setAnswers] = React.useState<string[]>([]);
  const [score, setScore] = React.useState<number | null>(null);
  const [timer, setTimer] = React.useState<number | null>(
    localStorage.getItem('placementExamTime:' + user.value.profile.studentId)
      ? parseInt(
          localStorage.getItem(
            'placementExamTime:' + user.value.profile.studentId,
          ),
        )
      : 3600,
  );
  const studentId = user.value.profile.studentId;
  const history = useHistory();

  const fetchExamQuestions = async () => {
    const response = await StudentService.takePlacementExam(studentId);
    setExam(response.data);
    setAnswers(new Array(response.data.length).fill(''));
  };

  const initExam = async () => {
    if (!studentId) {
      history.push('/');
      return error('You cannot take the exam without a student ID');
    }
    //check if the student has taken the exam before
    const lastExamDate = localStorage.getItem('lastExamDate:' + studentId);

    if (lastExamDate) {
      //check if the student has taken the exam in the last 30 days
      if (dayjs().diff(dayjs(lastExamDate), 'day') < 30) {
        setScore(
          parseInt(localStorage.getItem('placementExamScore:' + studentId)),
        );
      } else {
        fetchExamQuestions();
      }
    } else {
      fetchExamQuestions();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    initExam();
  }, []);

  //set one hour timer and display left time, when time is up, submit exam and save the time in local storage
  React.useEffect(() => {
    if (score !== null) return;
    const interval = setInterval(async () => {
      if (timer <= 0) {
        await submitExam();
        notify({
          title: 'Time is up',
          description: 'Your exam has been submitted',
        });
        localStorage.setItem('placementExamTime:' + studentId, '0');
        clearInterval(interval);
      } else {
        setTimer(timer - 1);
        localStorage.setItem(
          'placementExamTime:' + studentId,
          timer.toString(),
        );
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const submitExam = async () => {
    if (!studentId) return error('Student ID not found');
    setLoading(true);
    const response = await StudentService.placementExamScore(
      studentId,
      answers,
    );
    if (!response.data) {
      setLoading(false);
      return error('Failed to submit exam');
    }
    setScore(response.data.score);
    localStorage.removeItem('placementExamTime:' + studentId);
    localStorage.setItem(
      'placementExamScore:' + studentId,
      response.data.score,
    );
    localStorage.setItem('lastExamDate:' + studentId, dayjs().format());
    setLoading(false);
  };
  return (
    <Layout bg>
      {loading ? (
        <p>System responding...</p>
      ) : score == null ? (
        <div className='max-w-7xl m-auto px-8'>
          <h1 className='text-3xl font-bold py-10 text-white'>
            Future Sphere Placement Exam
          </h1>
          <PanelCard
            divider
            headerText='Exam Rules'
            padding
            // headerRight={Math.floor(timer / 60) + ' : ' + (timer % 60)}
            headerRight={dayjs(timer * 1000).format('mm:ss')}
          >
            <ul className='space-y-2'>
              <li>
                - <b>Do not</b> ask for help or use any external resources
              </li>
              <li>
                - You have <b>one hour</b> to finish this exam.
              </li>
              <li>
                - Once you start the exam, you <b>cannot</b> pause it.
              </li>
              <li>- Make sure you have a stable internet connection.</li>
              <li>
                - <b>Good luck!</b>
              </li>
            </ul>
          </PanelCard>
          <div className='my-8 space-y-10'>
            {exam.map((question, index) => (
              <div key={index}>
                <h3 className='mb-2'>
                  {index + 1}. {question.question}
                </h3>
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <input
                      type='radio'
                      name={`question-${index}`}
                      id={`question-${index}-option-${optionIndex}`}
                      value={option}
                      checked={answers[index] === option}
                      onChange={(e) => {
                        const newAnswers = [...answers];
                        newAnswers[index] = option;
                        setAnswers(newAnswers);
                      }}
                      className='mr-2'
                    />
                    <label htmlFor={`question-${index}-option-${optionIndex}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <Button onClick={() => submitExam()}>Submit</Button>
        </div>
      ) : (
        <div className='max-w-7xl m-auto px-8'>
          <PanelCard headerText='Exam Results' padding>
            <h2>
              Your score is{' '}
              <b className='text-green-600 text-3xl ml-1'>{score}</b>
            </h2>
            <p className='mt-2'>
              You can retake the exam after{' '}
              <b>
                {dayjs(localStorage.getItem('lastExamDate:' + studentId))
                  .add(30, 'day')
                  .diff(dayjs(), 'day')}{' '}
                days
              </b>
            </p>
          </PanelCard>
        </div>
      )}
    </Layout>
  );
};
export default PlacementExamPage;
