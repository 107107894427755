import React from 'react';
import Empty from '../../components/Empty';

interface Props {
  attendance?: {
    grade: number;
    present: number;
    late: number;
    absent: number;
    excused: number;
    unmarked: number;
  };
}

const AttendanceStats: React.FunctionComponent<Props> = ({
  attendance,
}: Props) => {
  return attendance ? (
    <div className='grid grid-cols-3 grid-rows-2 gap-4 px-6 pb-6'>
      <div>
        <h6 className='text-gray-600 font-semibold'>Attendance Grade</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.grade}%
        </span>
      </div>
      <div>
        <h6 className='text-gray-600 font-semibold'>Times Present</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.present}
        </span>
      </div>
      <div>
        <h6 className='text-gray-600 font-semibold'>Times Late</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.late}
        </span>
      </div>
      <div>
        <h6 className='text-gray-600 font-semibold'>Times Absent</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.absent}
        </span>
      </div>
      <div>
        <h6 className='text-gray-600 font-semibold'>Times Excused</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.excused}
        </span>
      </div>
      <div>
        <h6 className='text-gray-600 font-semibold'>Times Unmarked</h6>
        <span className='text-xl font-semibold text-blue-600'>
          {attendance.unmarked}
        </span>
      </div>
    </div>
  ) : (
    <Empty message='No attendance data.' />
  );
};

export default AttendanceStats;
