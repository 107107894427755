import { useState, useHookstate } from '@hookstate/core';
import React from 'react';
import AuthService from '../../../services/auth';
import {
  globalAuthToken,
  globalNotifications,
  globalRole,
  globalThirdPartyAccessToken,
  globalUser,
} from '../../../state';
import queryString from 'query-string';
import { Persistence } from '@hookstate/persistence';
import { useHistory } from 'react-router';
import UserService from '../../../services/user';

export interface Props {}

const handleGoogleSignIn = async (token) => {
  const userResponse = await AuthService.getUserInfoByToken(token);

  if (!userResponse.data.success) {
    throw 'Failed to get user info by token.';
  }

  const { role, uid } = userResponse.data.data;
  let response;

  if (role === 'student') {
    response = await UserService.fetchStudentByObjectId(uid);
  } else {
    response = await UserService.fetchTeacherById(uid);
  }

  return {
    role: role,
    user: response.data.data,
  };
};

const GoogleLogin: React.FC<Props> = () => {
  const history = useHistory();
  const authToken = useState(globalAuthToken);
  const roleState = useState(globalRole);
  const userState = useState(globalUser);
  const thirdPartyToken = useState(globalThirdPartyAccessToken);
  const parsed = queryString.parse(location.hash);
  const notification = useState(globalNotifications);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  //Google third party login
  if (parsed.access_token) {
    const token = parsed.access_token;
    AuthService.getGoogleUserInfo(token.toString()).then((googleUserInfo) => {
      const thirdParty = {
        provider: 'google',
        id: googleUserInfo.data.sub.toString(),
      };
      if (window.localStorage.getItem('state.user')) {
        AuthService.addThirdPartyConnection({
          email: user.value.profile.email,
          thirdParty,
        })
          .then((connectionResult) => {
            if (connectionResult.data.success) {
              notification.set({
                duration: 3000,
                title: 'Success!',
                description: 'You have successfully connected!',
                show: true,
                type: 'success',
              });
              history.push('/settings');
            } else {
              throw new Error(connectionResult.data.data);
            }
          })
          .catch((e) => {
            notification.set({
              duration: 5000,
              title: 'Error',
              description: e?.message || e,
              show: true,
              type: 'error',
            });
            history.push('/settings');
          });
      } else {
        AuthService.thirdPartySinghIn({ thirdParty }).then((checkRegister) => {
          if (checkRegister.data.data == 'no student') {
            window.localStorage.clear();
            thirdPartyToken.attach(Persistence('googleThirdPartyRegister'));
            globalThirdPartyAccessToken.set(token.toString());
            history.push('/register');
          } else {
            handleGoogleSignIn(checkRegister.data.data)
              .then((response) => {
                globalRole.set(response.role);
                globalUser.set(response.user);
                globalAuthToken.set(checkRegister.data.data);
                authToken.attach(Persistence('state.authToken'));
                roleState.attach(Persistence('state.role'));
                userState.attach(Persistence('state.user'));
                history.push('/');
              })
              .catch((e) => {
                throw new Error(e);
              });
          }
        });
      }
    });
  }
  return <></>;
};

export default GoogleLogin;
