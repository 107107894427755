import { Pagination as PaginationComponent } from '@bctc/components';
import { Announcement } from '@prisma/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import AnnouncementService from '../../services/announcements';
import {} from '../../types';
import Layout from '../Layout';

interface Props {}

const currentDate = dayjs().format('MMDDYY');

const ANNOUNCEMENT_LIMIT = 10;

const AnnouncementsPage: React.FC<Props> = (props: Props) => {
  const changePage = async (i: number) => {
    if (i < 1) return;
    const { data: result } = await AnnouncementService.getAnnouncements({
      page: i,
      pageSize: ANNOUNCEMENT_LIMIT,
    });
    if (!result) return;
    if (!result.data.length && result.total) return changePage(i - 1);
    setAnnouncements({
      ...result,
      page: i,
    });
  };
  const [announcements, setAnnouncements] = useState<Pagination<Announcement>>({
    data: [],
    total: 0,
    totalPages: 0,
    page: 0,
  });
  const totalPages = Math.ceil(announcements?.total / ANNOUNCEMENT_LIMIT);
  const [currentAnnouncement, setCurrentAnnouncement] =
    useState<Announcement | null>(null);
  useEffect(() => {
    changePage(1);
  }, []);

  return (
    <Layout bg>
      <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
        {announcements.data ? (
          announcements.data.length ? (
            <>
              <ul
                role='list'
                className='divide-y divide-gray-200 rounded-lg overflow-hidden'
              >
                {announcements.data.map((announcement) => (
                  <li
                    key={announcement.id}
                    onClick={() => setCurrentAnnouncement(announcement)}
                    className='cursor-pointer relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600'
                  >
                    <div className='flex justify-between space-x-3'>
                      <div className='min-w-0 flex-1'>
                        <span
                          className='absolute inset-0'
                          aria-hidden='true'
                        ></span>
                        <p className='text-xl font-bold text-gray-900 truncate'>
                          {announcement.title}
                        </p>
                        <p className='text-base text-gray-700 truncate'>
                          Staff
                        </p>
                      </div>
                      <time
                        className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500'
                        dateTime={announcement.createdAt.toString()}
                      >
                        {dayjs(announcement.createdAt).format('MMDDYY') ===
                        currentDate
                          ? dayjs(announcement.createdAt).format('h:mm A')
                          : dayjs(announcement.createdAt).format('MMM D, YYYY')}
                      </time>
                    </div>
                    <div className='mt-1'>
                      <p className='truncate text-sm text-gray-600'>
                        {ReactHtmlParser(announcement.content)}
                      </p>
                    </div>
                  </li>
                ))}
                <div className='bg-white p-2 flex items-center justify-between border-t border-gray-200'>
                  <div className='w-full'>
                    <PaginationComponent
                      itemsPerPage={10}
                      {...announcements}
                      setPage={changePage}
                    />
                  </div>
                </div>
              </ul>
            </>
          ) : (
            <Empty message='There are no announcements right now.' />
          )
        ) : (
          <Loading />
        )}
        <Modal
          open={currentAnnouncement !== null}
          close={() => setCurrentAnnouncement(null)}
          width='sm:max-w-2xl'
        >
          <p className='break-words text-2xl font-bold text-gray-900 mt-4'>
            {currentAnnouncement?.title}
          </p>
          <p className='text-lg text-gray-700'>Staff</p>
          <p className='break-words overflow-ellipsis overflow-hidden text-base text-gray-600'>
            {ReactHtmlParser(currentAnnouncement?.content)}
          </p>
          <time
            className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500 absolute top-5 right-5'
            dateTime={currentAnnouncement?.createdAt.toString()}
          >
            {dayjs(currentAnnouncement?.createdAt).format('MMDDYY') ===
            currentDate
              ? dayjs(currentAnnouncement?.createdAt).format('h:mm A')
              : dayjs(currentAnnouncement?.createdAt).format('MMM D, YYYY')}
          </time>
        </Modal>
      </div>
    </Layout>
  );
};

export default AnnouncementsPage;
