import { notify, showLoading, SingleDropdown, Modal } from '@bctc/components';
import { useHookstate } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { useForm } from '@mantine/form';
import { Gender } from '@prisma/client';
import dayjs from 'dayjs';
import { useAuth } from 'oidc-react';
import React, { useState } from 'react';
import Empty from '../../components/Empty';
import DiscordLogo from '../../components/LogoSvg/Discord';
import GithubLogo from '../../components/LogoSvg/Github';
import GoogleLogo from '../../components/LogoSvg/Google';
import { placeholderAvatar } from '../../constants/assets';
import AuthService from '../../services/auth';
import { uploadAvatar } from '../../services/files';
import StudentService from '../../services/student';
import { globalUser } from '../../state';

interface Props {}

export const thirdParty = [
  {
    key: 'github',
    title: 'Connect with Github',
    logoSvg: GithubLogo,
    link: 'https://github.com/login/oauth/authorize?scope=user&client_id=486ecaa59eaaf587f65c',
  },
  {
    key: 'google',
    title: 'Connect with Google',
    logoSvg: GoogleLogo,
    link: 'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=361513536461-voq4q656qr1k5oiokgfq3oi3bhpuk9qn.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fportal.thefuturesphere.com%2Fgoogle&response_type=token&scope=profile&flowName=GeneralOAuthFlow',
  },
  {
    key: 'discord',
    title: 'Connect with Discord',
    logoSvg: DiscordLogo,
    link: 'https://discord.com/api/oauth2/authorize?client_id=912409772094021714&redirect_uri=https%3A%2F%2Fportal.thefuturesphere.com%2Fdiscord&response_type=code&scope=identify',
  },
];

const StudentProfile: React.FunctionComponent<Props> = (props: Props) => {
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const { studentId, ...mutableData } = user.profile.value;
  const form = useForm({
    initialValues: {
      ...mutableData,
    },
  });
  const showSSOModal = useHookstate(false);
  const showSSOResultModal = useHookstate(false);
  const [ssoResult, setSSOResult] = useState({
    username: '',
    password: '',
  });

  const _auth = useAuth();

  const updateProfile = async () => {
    const { data: response } = await StudentService.updateStudent(
      studentId,
      form.values
    );
    if (!response) return;
    user.profile.set({ ...form.values, studentId });
    notify({ description: 'Successfully updated profile!' });
  };

  const handleUploadAvatar = async (files: FileList) => {
    if (!files.length) return;
    showLoading({ description: 'Uploading avatar...' });
    const url = await uploadAvatar(files[0]);
    if (!url) return;
    form.setFieldValue('avatar', url);
    notify({
      description:
        'Uploaded avatar! Your avatar will not change until you save.',
    });
  };

  const handleMigrateToSSO = async () => {
    const { data: invitation } = await AuthService.createInviteToSSO({
      studentId: user.value.id + '',
    });
    if (!invitation) return;
    showSSOModal.set(false);
    setSSOResult({
      username: invitation.username,
      password: invitation.password,
    });
    showSSOResultModal.set(true);
  };

  return form.values ? (
    <>
      <div className='py-6 lg:pb-8'>
        <div className='sm:border-b sm:border-gray-200 sm:pb-5'>
          <div className='px-6'>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>
              Profile
            </h2>
            <p className='mt-1 text-sm text-gray-500'>
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>
        </div>

        <div className='flex flex-col px-6 mt-6 lg:flex-row'>
          <div className='flex-grow space-y-6'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
              <div>
                <p className='block text-sm font-medium text-gray-700'>
                  First name
                </p>
                <p>{form.values.firstName}</p>
              </div>
              <div>
                <p className='block text-sm font-medium text-gray-700'>
                  Last name
                </p>
                <p>{form.values.lastName}</p>
              </div>
            </div>

            <div>
              <label
                htmlFor='about'
                className='block text-sm font-medium text-gray-700'
              >
                About
              </label>
              <div className='mt-1'>
                <textarea
                  id='about'
                  name='about'
                  rows={3}
                  className='block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
                  {...form.getInputProps('about')}
                />
              </div>
            </div>
          </div>

          <div className='flex-grow mt-6 lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0'>
            <p className='text-sm font-medium text-gray-700' aria-hidden='true'>
              Photo
            </p>
            <div className='mt-1 lg:hidden'>
              <div className='flex items-center'>
                <div
                  className='flex-shrink-0 inline-block w-12 h-12 overflow-hidden rounded-full'
                  aria-hidden='true'
                >
                  <img
                    className='w-full h-full rounded-full'
                    src={form.values.avatar || placeholderAvatar}
                    alt='avatar'
                  />
                </div>

                <div className='ml-5 rounded-md shadow-sm'>
                  <div className='relative flex items-center justify-center px-3 py-2 border border-gray-300 rounded-md group hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500'>
                    <label
                      htmlFor='mobile-user-photo'
                      className='relative text-sm font-medium leading-4 text-gray-700 pointer-events-none'
                    >
                      <span>Change</span>
                      <span className='sr-only'> user photo</span>
                    </label>
                    <input
                      id='mobile-user-photo'
                      name='studentAvatar'
                      type='file'
                      className='absolute w-full h-full border-gray-300 rounded-md opacity-0 cursor-pointer'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleUploadAvatar(e.target.files)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='relative hidden overflow-hidden rounded-full lg:block'>
              <img
                className='relative w-40 h-40 rounded-full'
                src={form.values.avatar || placeholderAvatar}
                alt='avatar'
              />
              <label
                htmlFor='desktop-user-photo'
                className='absolute inset-0 flex items-center justify-center w-full h-full text-sm font-medium text-white bg-black bg-opacity-75 opacity-0 hover:opacity-100 focus-within:opacity-100'
              >
                <span>Change</span>
                <span className='sr-only'> user photo</span>
                <input
                  type='file'
                  accept='image/png, image/jpeg'
                  id='desktop-user-photo'
                  name='studentAvatar'
                  className='absolute inset-0 w-full h-full border-gray-300 rounded-md opacity-0 cursor-pointer'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUploadAvatar(e.target.files)
                  }
                />
              </label>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-12 gap-6 px-6 mt-6 '>
          <div className='col-span-6'>
            <p className='block text-sm font-medium text-gray-700'>Email</p>
            <p>{form.values.email}</p>
          </div>
          <div className='col-span-6'>
            <label
              htmlFor='url'
              className='block text-sm font-medium text-gray-700'
            >
              Discord ID
            </label>
            <input
              type='text'
              name='url'
              id='url'
              className='block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
              {...form.getInputProps('discordId')}
            />
          </div>
          <div className='col-span-6'>
            <label
              htmlFor='url'
              className='block text-sm font-medium text-gray-700'
            >
              Address
            </label>
            <input
              type='text'
              name='url'
              id='url'
              className='block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
              {...form.getInputProps('address')}
            />
          </div>
          <div className='col-span-6'>
            <label
              htmlFor='url'
              className='block text-sm font-medium text-gray-700'
            >
              Personal Website
            </label>
            <input
              type='text'
              name='url'
              id='url'
              className='block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
              {...form.getInputProps('personalWebsite')}
            />
          </div>
          <div className='col-span-6'>
            <label
              htmlFor='url'
              className='block text-sm font-medium text-gray-700'
            >
              Hobbies
            </label>
            <input
              type='text'
              name='url'
              id='url'
              className='block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
              {...form.getInputProps('hobbies')}
            />
          </div>
          <div className='col-span-6'>
            <label
              htmlFor='url'
              className='block text-sm font-medium text-gray-700'
            >
              Github Name
            </label>
            <input
              type='text'
              name='url'
              id='url'
              className='block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
              {...form.getInputProps('githubName')}
            />
          </div>

          <div className='col-span-12 sm:col-span-6'>
            <label className='block text-sm font-medium text-gray-700'>
              Date of Birth
            </label>
            <input
              type='date'
              className='block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
              {...form.getInputProps('dob')}
              value={dayjs(form.values.dob || '2000-01-01').format(
                'YYYY-MM-DD'
              )}
            />
          </div>
          <div className='col-span-12 sm:col-span-6'>
            <label className='block text-sm font-medium text-gray-700'>
              Gender
            </label>
            <SingleDropdown
              data={[
                { id: 'FEMALE', title: 'Female' },
                { id: 'MALE', title: 'Male' },
                { id: 'UNKNOWN', title: 'Unknown' },
              ]}
              onChange={(v: Gender) => form.setFieldValue('gender', v)}
              value={form.values.gender}
            />
          </div>
        </div>
      </div>
      <div className='flex justify-end px-4 py-4 mt-4 sm:px-6'>
        {/* <div>
          {_auth.userData !== null ? (
            <button
              type='submit'
              className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-transparent rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              onClick={() =>
                window.location.replace(
                  'https://sso.thefuturesphere.com/if/user/#/settings'
                )
              }
            >
              Manage SSO Profile
            </button>
          ) : (
            <button
              type='submit'
              className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-transparent rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              onClick={() => showSSOModal.set(true)}
            >
              Migrate to SSO
            </button>
          )}
        </div> */}
        <div>
          <button
            type='button'
            className='inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='inline-flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white bg-blue-700 border border-transparent rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            onClick={() => updateProfile()}
          >
            Save
          </button>
        </div>
      </div>
      <Modal
        show={showSSOModal.value}
        setShow={showSSOModal.set}
        title='You are Migrating to Single Sign-On'
        submitText='Migrate to SSO'
        onSubmit={handleMigrateToSSO}
      >
        <p className='mt-2 text-gray-700'>
          You are about to migrate to Single Sign-On. This will allow you to
          login to all of your FutureSphere accounts with one set of
          credentials. After you migrate, you will no longer be able to login
          using your email and password and will need to use{' '}
          <b>Sign in using SSO</b>.
        </p>
        <p className='mt-2 text-gray-700'>
          Please wait for the migration to complete before you close this
          window. A new window will open to show you your new credentials.
        </p>
        <p className='mt-2 text-gray-700'>
          Please click the button below to migrate to Single Sign-On.
        </p>
      </Modal>
      <Modal
        show={showSSOResultModal.value}
        setShow={showSSOResultModal.set}
        title='You have migrated to Single Sign-On'
        submitText='OK'
        onSubmit={() => {
          window.location.replace('/logout');
        }}
      >
        <p className='mt-2 text-gray-700'>
          You have successfully migrated to Single Sign-On. Please keep this
          credentials safe as you will need them to login to all of your
          FutureSphere accounts.
        </p>
        <p className='mt-2 text-gray-700'>
          Username: <b>{ssoResult.username}</b> <br />
          Password: <b>{ssoResult.password}</b>
        </p>
        <p className='mt-2 text-gray-700'>
          Please click OK below to Logout and sign in using{' '}
          <b>Sign in using SSO</b>
        </p>
      </Modal>
    </>
  ) : (
    <Empty />
  );
};

export default StudentProfile;
