import { AxiosResponse } from 'axios';
import a from './config';

const findFileByJoinCode = (payload: {
  joinCode: string;
}): Promise<AxiosResponse> => a.post('/live/findFileByJoinCode', payload);

const getFileById = (payload: { fileId: string }): Promise<AxiosResponse> =>
  a.post('/live/getFileById', payload);

const changeFileName = (payload: {
  fileId: string;
  filename: string;
  ownerId: string;
}): Promise<AxiosResponse> => a.post('/live/changeFileName', payload);

const requestNewJoinCode = (payload: {
  fileId: string;
}): Promise<AxiosResponse> => a.post('/live/requestNewJoinCode', payload);

const getFilesByUserId = ({
  selectedOption,
  searchQuery,
  userId,
  page,
  pageSize,
}: {
  selectedOption: string;
  searchQuery: string;
  userId: string;
  page?: number;
  pageSize?: number;
}): Promise<AxiosResponse> => {
  return a.post('/live/getFilesByUserId', null, {
    params: {
      selectedOption,
      searchQuery,
      userId,
      page,
      pageSize,
    },
  });
};

const createNewFile = (payload: {
  ownerId: string;
  filename: string;
  classSessionId?: string;
}): Promise<AxiosResponse> => a.post('/live/createFile', payload);

const removeFile = (payload: { fileId: string }): Promise<AxiosResponse> =>
  a.post('/live/removeFile', payload);

const getCodingFileByClassSessionId = (payload: {
  classSessionId: string;
}): Promise<AxiosResponse> =>
  a.post('/live/getCodingFileByClassSessionId', payload);

const LiveSessionService = {
  findFileByJoinCode,
  getFileById,
  changeFileName,
  requestNewJoinCode,
  getFilesByUserId,
  createNewFile,
  removeFile,
  getCodingFileByClassSessionId,
};

export default LiveSessionService;
