export const multipleChoice = [
  "I am satisfied with my child's performance in this course",
  'My child is interested in continuing to learn programming.',
  'I am satisfied with the level of teaching and the sense of responsibility of the instructors.',
  'I am satisfied with the content of the course.',
  'I am satisfied with the communication process of registration, class and after-class tutoring.',
  'I would like to continue to enroll in subsequent programming courses in future sphere.',
  'I would recommend programming courses in future sphere to people around me.',
  'Is the coursework too heavy (too much homework, exams).',
];
export const multipleChoiceAnswer = [
  { value: 4, text: 'Strongly agree' },
  { value: 3, text: 'Agree' },
  { value: 2, text: 'Maybe' },
  { value: 1, text: 'Disagree' },
  { value: 0, text: 'Strongly disagree' },
];
export const text = [
  'Suggestions for improving course content in future sphere.',
  'Suggestions for improvement for future sphere instructors.',
  'Suggestions for improving the communication process for registration, classes and after-class tutoring in the future sphere.',
  'Suggestions for improving the cost of future sphere courses.',
];
