import { AxiosResponse } from 'axios';
import a from './config';

const getAnnouncements = ({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}): Promise<AxiosResponse> => {
  return a.get('/announcements', { params: { page, pageSize } });
};

const addAnnouncement = (announcement: {
  title: string;
  content: string;
  announcer: number;
}): Promise<AxiosResponse> => {
  return a.post('/announcements', announcement);
};

const removeAnnouncement = (id: string): Promise<AxiosResponse> => {
  return a.delete('/announcements/' + id);
};

const editAnnouncement = (announcement: {
  id: string;
  title: string;
  content: string;
}): Promise<AxiosResponse> => {
  return a.put('/announcements/' + announcement.id, announcement);
};

const AnnouncementService = {
  getAnnouncements,
  addAnnouncement,
  removeAnnouncement,
  editAnnouncement,
};

export default AnnouncementService;
