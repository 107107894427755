import { Profile } from '@prisma/client';
import { AxiosResponse } from 'axios';
import a from './config';

const getStudentByKeywordOrId = (query: string): Promise<AxiosResponse> => {
  return a.get(`/students`, {
    params: { searchQuery: query },
  });
};

const getStudentByKeywordOrIdCounter = (
  query: string
): Promise<AxiosResponse> => {
  return a.get('/students/keyword/counter', {
    params: {
      query,
    },
  });
};

const getStudentByKeywordOrIdWithPagination = (payload: {
  query: string;
  skip: number;
  limit: number;
}): Promise<AxiosResponse> => {
  return a.get('/students/keyword', {
    params: payload,
  });
};

const getStudentByStudentId = (studentId: string): Promise<AxiosResponse> => {
  return a.get(`/students/${studentId}`);
};

const getStudentsAvatarByStudentIdList = (
  studentIdList: string[]
): Promise<AxiosResponse> => {
  return a.post('/students/avatar/list', {
    studentIdList,
  });
};

const updateStudent = (
  studentId: string,
  profile: Omit<Profile, 'studentId'>
): Promise<AxiosResponse> => {
  return a.put(`/students/${studentId}`, { profile });
};

const fetchGradingData = (studentId: string): Promise<AxiosResponse> => {
  return a.get(`/students/grades/${studentId}`);
};

const takePlacementExam = (studentId: string): Promise<AxiosResponse> => {
  return a.post(`/students/placement`, { studentId });
};

const placementExamScore = (
  studentId: string,
  answers: string[]
): Promise<AxiosResponse> => {
  return a.post(`/students/getPlacementScore`, { studentId, answers });
};

const StudentService = {
  updateStudent,
  fetchGradingData,
  getStudentByKeywordOrId,
  getStudentByKeywordOrIdCounter,
  getStudentByKeywordOrIdWithPagination,
  getStudentByStudentId,
  getStudentsAvatarByStudentIdList,
  takePlacementExam,
  placementExamScore,
};

export default StudentService;
