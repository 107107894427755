import { Profile } from '@prisma/client';
import dayjs from 'dayjs';
import React from 'react';

const ProfilePage: React.FC<{ profile: Profile }> = ({ profile }) => {
  const info = {
    Email: profile.email,
    'Discord ID': profile.discordId || 'No Discord ID yet',
    Hobbies: profile.hobbies || 'No hobbies yet',

    'Github Profile': profile.githubName || 'No Github Profile yet',
    About: profile.about || 'No about yet',
    Gender:
      profile.gender === 'UNKNOWN' || ''
        ? 'Gender is not known.'
        : profile.gender,
    'Personal Website': profile.personalWebsite || 'No Personal Website yet',

    'Date of birth': profile.dob
      ? dayjs(profile.dob).format('YYYY-MM-DD')
      : 'No Date of Birth yet',
  };

  return (
    <div className='mt-6 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl mb-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
      {Object.entries(info).map((element, i) => (
        <div key={i}>
          <p className='text-sm font-medium text-gray-500'>{element[0]}</p>
          <p className='text-sm'>{element[1]}</p>
        </div>
      ))}
    </div>
  );
};
export default ProfilePage;
