import { useHookstate } from '@hookstate/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { placeholderAvatar } from '../../constants/assets';
import {
  deviceRecommendationLink,
  discordInvitationLink,
  studentManualLink,
} from '../../pages/Dashboard';
import { globalRole, globalUser } from '../../state';
import { GUEST_STUDENT_ID } from '../../constants';
import { SingleDropdown } from '@bctc/components';
import { DropdownData } from '@bctc/components';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  setShowJoinCodeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line no-empty-pattern
const DashboardProfilePanel: React.FC<Props> = ({ setShowJoinCodeModal }) => {
  const user = useHookstate(globalUser);
  const role = useHookstate(globalRole);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section
      className='lg:col-span-9 xl:col-span-6'
      aria-labelledby='profile-overview-title'
    >
      <div className=' bg-white rounded-lg shadow'>
        {user && user.value && (
          <div className='p-6 bg-white'>
            <div className='sm:flex sm:items-center sm:justify-between'>
              <div className='sm:flex sm:space-x-5'>
                <div className='flex-shrink-0'>
                  <img
                    className='w-20 h-20 mx-auto rounded-full'
                    src={user.value.profile.avatar || placeholderAvatar}
                    alt='user avatar'
                  />
                </div>
                <div className='mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'>
                  <p className='text-sm font-medium text-gray-600'>
                    {GUEST_STUDENT_ID === user.value.id
                      ? ' Welcome to Future Student Portal'
                      : 'Welcome back'}
                  </p>
                  {GUEST_STUDENT_ID === user.value.id ? (
                    <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                      Guest Student
                    </p>
                  ) : (
                    <>
                      <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                        {`${user.value.profile.firstName}`}{' '}
                        {user.value.profile.lastName &&
                          `${user.value.profile.lastName}`}
                      </p>
                      <p className='text-sm font-medium text-gray-600 capitalize'>
                        {role.value}
                        {role.value == 'student' ? ' #' + user.value.id : null}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className='flex justify-center mt-5 sm:mt-0'>
                {GUEST_STUDENT_ID === user.value.id ? null : (
                  <>
                    <div className='relative mr-4'>
                      <button
                        type='button'
                        className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        id='sort-menu'
                        aria-expanded={isOpen}
                        aria-haspopup='true'
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {'Action'}
                        <svg
                          className='-mr-1 ml-2 h-5 w-5'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            fillRule='evenodd'
                            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </button>
                      {isOpen && (
                        <div className='absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10'>
                          <div
                            className='py-1 max-h-60 overflow-y-auto'
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='sort-menu'
                          >
                            <button
                              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                              role='menuitem'
                              onClick={() =>
                                (window.location.href = studentManualLink)
                              }
                            >
                              Student Manual
                            </button>
                            <button
                              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                              role='menuitem'
                              onClick={() =>
                                (window.location.href = discordInvitationLink)
                              }
                            >
                              Join Discord
                            </button>
                            <button
                              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                              role='menuitem'
                              onClick={() =>
                                (window.location.href =
                                  deviceRecommendationLink)
                              }
                            >
                              Buying a laptop?
                            </button>
                            <button
                              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                              role='menuitem'
                              onClick={() => history.push('/placement')}
                            >
                              Placement Exam
                            </button>
                            <button
                              className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                              role='menuitem'
                              onClick={() => {
                                setShowJoinCodeModal(true);
                              }}
                            >
                              Join Live Class
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <Link
                        to={`/${globalRole.value}/${user.value.id}`}
                        className='flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50'
                      >
                        View profile
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DashboardProfilePanel;
