import { AxiosResponse } from 'axios';
import a from './config';

const createFileLink = (
  filename: string,
  data: string
): Promise<AxiosResponse> => {
  if ((data && data !== ' ') || data !== '') {
    return a.post(`/files/submission/direct?filename=${filename}`, {
      data,
    });
  }
};

const createLinkForFile = (filename, data) => {
  return a.post(`/files/submission/direct?filename=${filename}`, {
    data,
  });
};
const handleUpsertSubmission = (data: {
  content;
  link;
  attachment;
  type;
  student;
  classSession;
  codingFile;
  score;
}): Promise<AxiosResponse> => {
  return a.post(`/cohorts/submission`, data);
};
const uploadAvatar = (filename: string): Promise<AxiosResponse> => {
  return a.post('/files/avatar', null, { params: { filename } });
};

const UploadService = {
  createFileLink,
  uploadAvatar,
  handleUpsertSubmission,
  createLinkForFile,
};

export default UploadService;
