import { Button, error, PanelCard, Spinner, TextArea } from '@bctc/components';
import ReactMarkdown from 'react-markdown';
import React, { useEffect } from 'react';
import a from '../../services/config';
import Layout from '../Layout';
import remarkGfm from 'remark-gfm';
import { useHookstate } from '@hookstate/core';
import { globalUser } from '../../state';

export const HomeworkHelperPage = () => {
  const [question, setQuestion] = React.useState<string>('');
  const [answer, setAnswer] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tries, setTries] = React.useState<number>(0);

  const user = useHookstate(globalUser);

  useEffect(() => {
    const fetchData = async () => {
      const response = await a.get('/ai/check-tries', {
        params: { studentId: user.value.id },
      });
      if (response.data) {
        setTries(response.data.tries);
      }
    };
    fetchData();
  }, [answer]);

  const handleAsk = async () => {
    // if the request failed, do not add to the usage

    if (
      !question ||
      question.length < 5 ||
      question.length > 1000 ||
      question.trim() === ''
    ) {
      error('Please enter a question.');
      return;
    }
    try {
      setLoading(true);
      const response = await a.post('/ai', {
        query: question,
        studentId: user.value.id,
      });
      setLoading(false);
      if (response.data.result) {
        setAnswer(response.data.result);
      } else {
        error(response.data?.error?.message || 'Something went wrong');
      }
    } catch (err) {
      setLoading(false);
      error('Something went wrong. Please try again later.');
    }
  };
  return (
    <Layout bg>
      <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 space-y-4'>
        <PanelCard
          headerText={'Ask me homework questions'}
          headerRight={
            <p className='text-sm font-semibold flex gap-x-2'>
              Questions left today: {tries || <Spinner color='theme' />}
            </p>
          }
        >
          {loading ? (
            <div className='bg-blue-50 w-full h-full min-h-48 p-6 flex items-center'>
              <p className='text-lg text-gray-500 text-center mx-auto'>
                I am thinking and typing...
              </p>
            </div>
          ) : answer ? (
            <div className='bg-blue-50 w-full h-full min-h-48 p-6'>
              <p className='text-sm text-gray-500 text-left mx-auto'>
                <ReactMarkdown
                  className='prose lg:prose-xl max-w-full'
                  remarkPlugins={[remarkGfm]}
                >
                  {answer}
                </ReactMarkdown>
              </p>
            </div>
          ) : (
            <div className='bg-blue-50 w-full h-full min-h-48 p-6 flex items-center'>
              <p className='text-lg text-gray-500 text-center mx-auto'>
                Ask me any questions about your homework, Python, HTML, CSS,
                Javascript, Java, C++, C#, SQL, and more!
              </p>
            </div>
          )}
        </PanelCard>
        <div className='relative'>
          <TextArea
            className='h-96 text-sm text-left'
            onChange={(value) => setQuestion(value)}
            value={question}
            placeholder='Copy and paste in your full code, the error message from console (if there is any) and your question, and I will help you'
          ></TextArea>
          <Button
            onClick={handleAsk}
            className='absolute bottom-4 right-4 block'
            size='lg'
            disabled={loading}
          >
            {loading ? <Spinner color='light' /> : 'Ask'}
          </Button>
        </div>
      </div>
    </Layout>
  );
};
