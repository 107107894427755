import { DownloadIcon, LinkIcon, PaperClipIcon } from '@heroicons/react/solid';
import React from 'react';
import { useHistory } from 'react-router';
import Loading from '../Loading';
import { useHookstate } from '@hookstate/core';
import { globalRole } from '../../state';
import { weekdaysName } from '../../pages/Classes/constants';
import { CohortWithDetails } from '../../types';
import dayjs from 'dayjs';
import { useMemo } from 'react';

interface Props {
  cohort?: CohortWithDetails;
  showActionButton?: boolean;
  loading?: boolean;
  workstationAccessCode?: string;
}

const ClassDetailPanel: React.FC<Props> = ({
  cohort,
  showActionButton,
  loading,
  workstationAccessCode,
}) => {
  const history = useHistory();
  const role = useHookstate(globalRole);

  const completedSessions = useMemo(() => {
    return cohort.sessions.filter((session) => session.attendance.length);
  }, [cohort.sessions]);

  return (
    <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 bg-white border-b border-gray-200 sm:px-6'>
        <div className='flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap'>
          <div className='mt-2 ml-4'>
            {loading ? (
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Loading...
              </h3>
            ) : (
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                {cohort.course.title + ' '}
                <span className='text-gray-400 text-md pl-1'>
                  C-{cohort.cohortNumber}
                </span>
              </h3>
            )}
          </div>
          {showActionButton && !loading ? (
            <div className='flex-shrink-0 mt-2 ml-4'>
              <button
                type='button'
                className='relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                onClick={() => history.push(`/class/${cohort.id}`)}
              >
                Enter Class
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {loading ? (
        <div className='max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
          <Loading />
        </div>
      ) : (
        <div className='border-t border-gray-200'>
          <dl>
            <div className='px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Meeting time
              </dt>
              {cohort.frequency.length ? (
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Every
                  {` ${weekdaysName[cohort.frequency[0].dayOfTheWeek]} `}
                  At
                  {` ${cohort.frequency[0].startTime} `}-
                  {` ${cohort.frequency[0].endTime}`}
                </dd>
              ) : (
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  No meeting time
                </dd>
              )}
            </div>
            <div className='px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Number of classes
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {completedSessions.length} / {cohort.sessions.length}
              </dd>
            </div>
            <div className='px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>
                Starting Date
              </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {dayjs.utc(cohort.startDate).format('MMMM D, YYYY')}
              </dd>
            </div>
            <div className='px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Ending Date</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {dayjs.utc(cohort.endDate).format('MMMM D, YYYY')}
              </dd>
            </div>
            {cohort.zoomLink ? (
              <div className='px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>Zoom Link</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {cohort.zoomLink ? (
                    <a
                      className='text-blue-500'
                      href={cohort.zoomLink}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <LinkIcon className='inline w-5 h-5 mr-4 text-blue-400' />
                      <span>Zoom Classroom</span>
                    </a>
                  ) : null}
                </dd>
              </div>
            ) : null}
            {cohort.zoomMeetingId ? (
              <div className='px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Zoom Meeting ID
                </dt>

                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {cohort.zoomMeetingId}
                </dd>
              </div>
            ) : null}
            {cohort.zoomPassword ? (
              <div className='px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Zoom Password
                </dt>

                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {cohort.zoomPassword}
                </dd>
              </div>
            ) : null}
            {cohort.locationId !== 'online' &&
            workstationAccessCode !== '' &&
            role.get() === 'teacher' ? (
              <div className='px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Workstation Access Code
                </dt>

                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {workstationAccessCode}
                </dd>
              </div>
            ) : null}
            <div className='px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Attachments</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                <ul className='border border-gray-200 divide-y divide-gray-200 rounded-md'>
                  <li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                    <div className='flex items-center flex-1 w-0'>
                      <PaperClipIcon
                        className='flex-shrink-0 w-5 h-5 text-gray-400'
                        aria-hidden='true'
                      />
                      <span className='flex-1 w-0 ml-2 truncate'>
                        Student Manual
                      </span>
                    </div>
                    <div className='flex-shrink-0 ml-4'>
                      <a
                        href='https://future-sphere-lecture-assets.s3-us-west-2.amazonaws.com/Future+Sphere+Student+Manual.pdf'
                        target='_blank'
                        rel='noreferrer'
                        className='font-medium text-blue-600 hover:text-blue-500'
                      >
                        <DownloadIcon
                          className='flex-shrink-0 w-5 h-5 text-blue-400'
                          aria-hidden='true'
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
};

export default ClassDetailPanel;
