/* eslint-disable react/prop-types */
import React from 'react';

import { classNames } from '../../helpers/className';
import dayjs from 'dayjs';
interface Props {
  id: string;
  author: string;
  date: string;
  title: string;
  url: string;
}

const Feed: React.FC<Props> = ({ id, author, date, title, url }) => {
  // pick a random color out of the list of tailwind colors
  const colors = [
    'bg-blue-50',
    'bg-green-50',
    'bg-indigo-50',
    'bg-yellow-50',
    'bg-red-50',
    'bg-pink-50',
    'bg-purple-50',
    'bg-gray-50',
  ];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  const className = classNames(
    randomColor,
    'px-4 py-6 shadow sm:p-6 sm:rounded-lg',
    `hover:${randomColor.replace('50', '100')}`
  );

  const linkClassName = classNames(
    'mt-4 text-base font-medium text-gray-600 underline-offset-4',
    url ? 'underline' : ''
  );

  return (
    <li key={id} className={className}>
      <article aria-labelledby={'question-title-' + id}>
        <div>
          <div className='flex space-x-3'>
            <div className='min-w-0 flex-1'>
              <p className='text-sm font-medium text-gray-900'>
                author: {author}
              </p>
              <p className='text-sm text-gray-500'>
                <time>{dayjs(+date * 1000).format('MMMM D, YYYY')}</time>
              </p>
            </div>
          </div>
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            id={'question-title-' + id}
            className={linkClassName}
          >
            {title}
          </a>
        </div>
      </article>
    </li>
  );
};

export default Feed;
