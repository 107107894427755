import React, { useState } from 'react';
import {
  PlusIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/solid';

interface Feedback {
  title: string;
  content: string;
}

interface Props {
  formatFeedback: string;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
  v: Feedback;
}

const TemplateFeedback: React.FC<Props> = ({
  formatFeedback,
  setFeedback,
  v,
}) => {
  const [visible, setVisibile] = useState(false);
  const toggleVisibility = () => {
    setVisibile(!visible);
  };
  return (
    <li
      className='py-3 flex justify-between items-center cursor-pointer'
      onClick={toggleVisibility}
    >
      <div className='flex flex-start justify-start'>
        <button
          onClick={toggleVisibility}
          type='button'
          className='h-5 w-5 mr-2 pt-0.5 bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        >
          {visible ? (
            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
          ) : (
            <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
          )}
        </button>
        <div>
          <label className='font-normal text-gray-800 text-sm overflow-hidden overflow-ellipsis cursor-pointer'>
            {v.title}
          </label>
          {visible && <p className='text-gray-500 text-sm'>{formatFeedback}</p>}
        </div>
      </div>
      <button
        onClick={() => setFeedback(formatFeedback.toString())}
        type='button'
        className='ml-6 bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
      >
        <PlusIcon className='h-5 w-5' aria-hidden='true' />
      </button>
    </li>
  );
};
export default TemplateFeedback;
