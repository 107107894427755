import {
  Announcement,
  Attendance,
  ClassSession,
  CodingFile,
  Cohort,
  CohortEnrollment,
  CohortFrequency,
  Course,
  Expense,
  Gender,
  Lesson,
  Profile,
  StoreCreditTransaction,
  Student,
  Submission,
  Teacher,
  TeacherPayment,
} from '@prisma/client';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends any
    ? RecursivePartial<T[P]>
    : T[P];
};

export const CohortStatus: {
  ALL_CLASSES: 'ALL_CLASSES';
  ALL_MY_CLASSES: 'ALL_MY_CLASSES';
  UPCOMING: 'UPCOMING';
  ENDED: 'ENDED';
  IN_SESSION: 'IN_SESSION';
} = {
  ALL_CLASSES: 'ALL_CLASSES',
  ALL_MY_CLASSES: 'ALL_MY_CLASSES',
  UPCOMING: 'UPCOMING',
  ENDED: 'ENDED',
  IN_SESSION: 'IN_SESSION',
};

export type CohortStatus = (typeof CohortStatus)[keyof typeof CohortStatus];

export interface ICohort {
  id: number;
  updatedAt: Date;
  frequency: IFrequency[];
  sessions: ISession[];
  endDate?: Date;
  startDate: Date;
  cohortNumber: number;
  zoomLink?: string;
  zoomPassword?: string;
  zoomMeetingId?: string;
  attachments: any[];
  course: ICourse;
  courseId: number;
  students: IStudentEnrollment[];
  instructor: ITeacher;
  instructorId: number;
  instructorRating?: number;
  instructorPayment?: any;
  location?: any;
  locationId?: number;
  surveys: any[];
  tappingRecord: any;
}

export interface IStudentEnrollment {
  joinDate: Date;
  student: IStudent;
  studentId: string;
  cohort: ICohort;
  cohortId: number;
  tuitionPayment: any;
}

export interface IFrequency {
  id: string;
  dayOfTheWeek: number;
  startTime: string;
  endTime: string;
  room?: any;
  roomId?: string;
  cohort: ICohort;
  cohortId: number;
}

export interface ISession {
  id: string;
  lesson: ILesson;
  lessonId: number;
  disabled: boolean;
  recording?: string;
  submissions: any[];
  comments: any[];
  cohort: ICohort;
  cohortId: number;
  attendance: any[];
}

export interface ILesson {
  id: number;
  updatedAt: Date;
  lessonIndex: number;
  title: string;
  description: string;
  thumbnailImage: string;
  note: string;
  homework: string;
  codaLink: string;
  classSessions: ISession[];
  courseId: string;
  course: any;
}

export interface ILessonMaterial {
  id: string;
  lessonId: number | null;
  title: string;
  latestContent: string;
  version: number;
  createdAt: Date;
  createdBy: number;
  lesson: Lesson;
  type: LessonMaterialType;
  history: LessonMaterialEdit[];
 }
 
 enum LessonMaterialType {
  NOTE,
  HOMEWORK,
  PROJECT,
  PRINTOUT,
  OTHER,
 }
 
 export interface LessonMaterialEdit {
  id: string;
  createdAt: Date;
  createdBy: number;
  version: number;
  changes: ChangeHunk[];
  lessonMaterial: ILessonMaterial;
  lessonMaterialId: string;
 }
 
 interface ChangeHunk {
  id: string;
  oldStart: number;
  oldLines: number;
  newStart: number;
  newLines: number;
  lines: string[];
  lessonMaterialEdit: LessonMaterialEdit;
  lessonMaterialEditId: string;
 }

export interface ICourse {
  id: string;
  updatedAt: Date;
  title: string;
  description: string;
  thumbnailImage: string;
  courseTrackId: string;
  tuition: number;
  track: any;
  recommendedCourses: ICourse[];
  recommendedBy: ICourse[];
  lessons: ILesson[];
  cohorts: ICohort[];
}

export interface ITrack {
  id: string;
  updatedAt: Date;
  name: string;
  description: string;
  thumbnailImage: string;
  courses: ICourse[];
  trialClasses: any[];
  locations: any[];
}

export interface IStudent {
  isActive: boolean;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  discordId?: string;
  location?: string;
  personalWebsite?: string;
  hobbies?: string;
  githubName?: string;
  about?: string;
  username?: string;
  dob?: Date;
  gender?: Gender;
  avatar?: string;
  studentId: string;
  liked_users: string[];
  _id: string;
}

export interface ITeacher extends Teacher {
  firstName: string;
  lastName: string;
  password: string;
  description: string;
  email: string;
  _id: string;
  phone: string;
  dob?: Date;
  username?: string;
  githubName?: string;
  gender?: Gender;
  wechatId: string;
  avatar: string;
  discordId?: string;
  teacherId: string;
  personalWebsite?: string;
  hobbies?: string;
  about?: string;
  location?: string;
}

export interface ThirdPartyInfo {
  provider: string;
  id: string;
}

export interface IClass {
  _id: string;
  teachers: [string];
  students: [string];
  title: string;
  templateId: string;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  days: [number];
  cohort: number;
  classLength: number;
  assetFileType: string;
  zoomId: string;
  zoomLink: string;
  zoomPassword: string;
  ongoing: boolean;
  teacherName: string;
  formatEndDate: string;
  formatStartDate: string;
  _v?: number;
}

export interface IAnnouncements {
  announcements: {
    _id: string;
    title: string;
    description: string;
    announcer: string;
    date: Date;
  }[];
  currentPage: number;
  totalAnnouncements: number;
}

export interface ISubmission {
  attachment?: FileList | string;
  content?: string;
  link: string;

  classId: string;
  classIndex: string;
  type: string;
  classSession: string;
  studentId: string;
}

export interface Pagination<T = any> {
  data: T[];
  page: number;
  total: number;
  totalPages: number;
}

export interface EnrollmentWithDetails extends CohortEnrollment {
  student: StudentWithProfile;
  cohort: Cohort;
}

export interface ClassSessionWithDetails extends ClassSession {
  lesson: Lesson;
  attendance: Attendance[];
  comments: Comment[];
  submissions: (Submission & {
    codingFile: CodingFile;
  })[];
  cohort: CohortWithDetails;
}

export interface CohortWithDetails extends Cohort {
  course: Course;
  students: EnrollmentWithDetails[];
  instructor: TeacherWithDetails;
  sessions: ClassSessionWithDetails[];
  frequency: CohortFrequency[];
  grades?: { [key: string]: number };
}

export interface TeacherWithDetails extends Teacher {
  profile: Profile;
  cohorts: CohortWithDetails[];
  storeCreditTransactionsManaged: StoreCreditTransaction[];
  payment: TeacherPayment[];
  reimbursements?: Expense;
  announcements: Announcement[];
}

export interface CohortEnrollmentWithDetails extends CohortEnrollment {
  cohort: CohortWithDetails;
}

export interface StudentWithProfile extends Student {
  cohorts: CohortEnrollmentWithDetails[];
  profile: Profile;
}

export type User = TeacherWithDetails | StudentWithProfile;

export interface AnnouncementWithAnnouncer extends Announcement {
  announcer: TeacherWithDetails;
}

export interface IProfile {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  locations: [];
  about: string;
  avatar: string;
  disabled: boolean;
  thirdParty: [];
  wechatId: string;
  githubName: string;
  personalWebsite: string;
  teacher: any;
  teacherId: string;
  student: any;
  studentId: string;
  gender: string;
  location: string;
  dob: Date;
  hobbies: string;
  language: [];
  notifications: [];
  sso_id: string;
  tappingRecord: [];
  cardId: string;
}

export interface ServerMeta {
  host: string;
  caching: boolean;
  commit: string;
  version: string;
}
