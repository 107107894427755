/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  headerText?: string;
  headerSubtitle?: string;
  children: ReactNode;
  divider?: boolean;
  footer?: ReactNode;
  headerRight?: ReactNode;
  padding?: boolean;
  paddingTop?: boolean;
}

const PanelCard: React.FC<Props> = ({
  headerText,
  headerSubtitle,
  children,
  divider,
  footer,
  headerRight,
  padding = true,
  paddingTop = true,
}) => {
  return (
    <div
      className={classNames('bg-white shadow rounded-lg', {
        'divide-y divide-gray-200': !!divider,
      })}
    >
      <div className='px-4 py-5 sm:px-6 flex items-center justify-between flex-wrap sm:flex-nowrap'>
        <div>
          {headerText && (
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {headerText}
            </h3>
          )}
          {headerSubtitle && (
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              {headerSubtitle}
            </p>
          )}
        </div>
        {headerRight && headerRight}
      </div>
      <div
        className={classNames('', {
          'pt-0 sm:px-6 sm:pb-6': !paddingTop,
          'px-4 py-5': padding,
        })}
      >
        {children}
      </div>
      {footer && footer}
    </div>
  );
};

export default PanelCard;
