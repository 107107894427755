import { AxiosResponse } from 'axios';
import { RecursivePartial, TeacherWithDetails } from '../types';
import a from './config';

const createTeacher = (data: {
  email;
  name;
  avatar;
  wechat;
  phone;
  description;
  password;
}): Promise<AxiosResponse> => {
  return a.post('/teachers', {
    params: {
      data,
    },
  });
};
const getTeacherByKeyword = (query: string): Promise<AxiosResponse> => {
  return a.get(`/teachers`, {
    params: { searchQuery: query },
  });
};

const handleGetAllTeachers = (): Promise<AxiosResponse> => {
  return a.get('/teachers');
};

const getTeachersByClassId = (classId: string): Promise<AxiosResponse> => {
  return a.get(`/cohorts/${classId}`);
};

const updateTeacherById = (
  id: number,
  data: RecursivePartial<TeacherWithDetails>
): Promise<AxiosResponse> => a.put('/teachers/' + id, data);
const getTeacherById = (id: number): Promise<AxiosResponse> => {
  return a.get(`/teachers/${id}`);
};
const TeacherServices = {
  getTeacherByKeyword,
  handleGetAllTeachers,
  getTeachersByClassId,
  updateTeacherById,
  getTeacherById,
  createTeacher,
};

export default TeacherServices;
