import { useState, useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import AuthService from '../../../services/auth';
import {
  globalAuthToken,
  globalRole,
  globalThirdPartyAccessToken,
  globalUser,
  globalNotifications,
} from '../../../state';
import queryString from 'query-string';
import { Persistence } from '@hookstate/persistence';
import { useHistory } from 'react-router';
import UserService from '../../../services/user';
const githubClientSecret = import.meta.env.VITE_GITHUB_CLIENT_SECRET;

export interface Props {}

const handleGithubSignIn = async (token) => {
  const userResponse = await AuthService.getUserInfoByToken(token);

  if (!userResponse.data.success) {
    throw 'Failed to get user info by token.';
  }

  const { role, uid } = userResponse.data.data;
  let response;

  if (role === 'student') {
    response = await UserService.fetchStudentByObjectId(uid);
  } else {
    response = await UserService.fetchTeacherById(uid);
  }

  return {
    role: role,
    user: response.data.data,
  };
};

const GithubLogin: React.FC<Props> = () => {
  const history = useHistory();
  const authToken = useState(globalAuthToken);
  const roleState = useState(globalRole);
  const userState = useState(globalUser);
  const thirdPartyToken = useState(globalThirdPartyAccessToken);
  const parsed = queryString.parse(location.search);
  const notification = useState(globalNotifications);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  if (parsed.error) {
    history.push('/login');
  }
  useEffect(() => {
    if (parsed.code && githubClientSecret) {
      const code = parsed.code;
      AuthService.getGithubToken({
        client_id: '486ecaa59eaaf587f65c',
        client_secret: githubClientSecret,
        code: code.toString(),
        redirect_uri: 'https://portal.thefuturesphere.com/github',
      })
        .then((response) => {
          const data = queryString.parse(response.data.data);
          const access_token = data.access_token;
          if (access_token) {
            AuthService.getGithubUserInfo(access_token.toString()).then(
              (githubUserInfo) => {
                const thirdParty = {
                  provider: 'github',
                  id: githubUserInfo.data.id.toString(),
                };
                if (window.localStorage.getItem('state.user')) {
                  AuthService.addThirdPartyConnection({
                    email: user.value.profile.email,
                    thirdParty,
                  })
                    .then((connectionResult) => {
                      if (connectionResult.data.success) {
                        notification.set({
                          duration: 3000,
                          title: 'Success!',
                          description: 'You have successfully connected!',
                          show: true,
                          type: 'success',
                        });
                        history.push('/settings');
                      } else {
                        throw new Error(connectionResult.data.data);
                      }
                    })
                    .catch((e) => {
                      notification.set({
                        duration: 5000,
                        title: 'Error',
                        description: e?.message || e,
                        show: true,
                        type: 'error',
                      });
                      history.push('/settings');
                    });
                } else {
                  AuthService.thirdPartySinghIn({ thirdParty }).then(
                    (checkRegister) => {
                      if (checkRegister.data.data == 'no student') {
                        thirdPartyToken.attach(
                          Persistence('githubThirdPartyRegister'),
                        );
                        globalThirdPartyAccessToken.set(
                          access_token.toString(),
                        );
                        history.push('/register');
                      } else {
                        handleGithubSignIn(checkRegister.data.data)
                          .then((response) => {
                            globalRole.set(response.role);
                            globalUser.set(response.user);
                            globalAuthToken.set(checkRegister.data.data);
                            authToken.attach(Persistence('state.authToken'));
                            roleState.attach(Persistence('state.role'));
                            userState.attach(Persistence('state.user'));
                            history.push('/');
                          })
                          .catch((e) => {
                            throw new Error(e);
                          });
                      }
                    },
                  );
                }
              },
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return <></>;
};

export default GithubLogin;
