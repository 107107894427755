import {
  ClassSession,
  Cohort,
  Course,
  Lesson,
  Profile,
  Student,
  Teacher,
} from '@prisma/client';

export const emptyCohort: Cohort = {
  id: 0,
  cohortNumber: 0,
  courseId: '',
  startDate: new Date(),
  endDate: new Date(),
  instructorId: 0,
  instructorRating: null,
  locationId: '',
  staffId: null,
  updatedAt: new Date(),
  zoomLink: null,
  zoomPassword: null,
  zoomMeetingId: null,
};

export const emptyProfile: Profile = {
  about: null,
  avatar: null,
  address: null,
  cardId: null,
  disabled: false,
  discordId: null,
  dob: null,
  email: null,
  firstName: '',
  gender: 'UNKNOWN',
  githubName: null,
  hobbies: null,
  id: '',
  language: [],
  lastName: null,
  personalWebsite: null,
  phone: null,
  sso_id: null,
  studentId: null,
  teacherId: null,
  wechatId: null,
};

export const emptyTeacher: Teacher = {
  active: true,
  authorityLevel: 'TEACHER',
  createdAt: new Date(),
  id: 0,
  updatedAt: new Date(),
  password: null,
  public: false,
  teachingLevel: 'TUTOR',
};

export const emptyCourse: Course = {
  id: '',
  courseTrackId: '',
  description: null,
  locationId: null,
  thumbnailImage: null,
  title: '',
  tuition: 0,
  updatedAt: new Date(),
};

export const emptyClassSession: ClassSession = {
  id: '',
  cohortId: 0,
  lessonId: 0,
  disabled: false,
  recording: null,
};

export const emptyLesson: Lesson = {
  id: 0,
  updatedAt: new Date(),
  lessonIndex: 0,
  description: null,
  courseId: null,
  homework: null,
  note: null,
  thumbnailImage: null,
  title: '',
};

export const emptyStudent: Student = {
  createdAt: new Date(),
  id: '',
  leadId: null,
  password: null,
  updatedAt: new Date(),
  status: 'REGISTERED',
};
