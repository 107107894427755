import React from 'react';

interface Props {
  text: number | string;
}
const Tag: React.FC<Props> = ({ text }) => {
  return (
    <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800'>
      {text}
    </span>
  );
};

export default Tag;
