import { AxiosResponse } from 'axios';
import a from './config';

const markNotificationAsRead = (
  notificationId: string,
): Promise<AxiosResponse> => a.post('/notifications/read/' + notificationId);

const markAllNotificationAsRead = (profileId: string): Promise<AxiosResponse> =>
  a.post('/notifications/readAll/' + profileId);

const NotificationService = {
  markNotificationAsRead,

  markAllNotificationAsRead,
};

export const fetchNotifications = (
  profileId: string,
): Promise<AxiosResponse> => {
  return a.get('/notifications/' + profileId);
};

export default NotificationService;
