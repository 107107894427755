export const books = [
  {
    title: 'Professional JavaScript for Web Developers',
    author: 'Matt Frisbie',
    description:
      'This is the ultimate guide to JavaScript and the only book you need to know to get started with it. It covers all the basics of JavaScript, including how to use the language, how to write code, and how to debug it. It also covers the latest and greatest in JavaScript, including ECMAScript 6, the newest version of JavaScript, and the new features that are coming to JavaScript.',
    link: 'https://www.amazon.com/Professional-JavaScript-Developers-Nicholas-Zakas/dp/1118026691',
  },
  {
    title: 'Algorithms to Live By',
    author: 'Brian Christian',
    description:
      'A fascinating exploration of how computer algorithms can be applied to our everyday lives, helping to solve common decision-making problems and illuminate the workings of the human mind',
    link: 'https://www.amazon.com/Algorithms-Live-Computer-Science-Decisions/dp/1627790365',
  },
  {
    title: 'Cracking the Tech Career',
    author: 'Gayle Laakmann McDowell',
    description: 'Become the applicant Google cannot turn down',
    link: 'https://www.amazon.com/Cracking-Tech-Career-Insider-Microsoft/dp/1118968085',
  },
  {
    title: 'Grokking System Design',
    author: 'Design Gurus',
    description:
      'System design questions have become a standard part of the software engineering interview process. These interviews determine your ability to work with complex systems and the position and salary you will be offered by the interviewing company. Unfortunately, SDI is difficult for most engineers, partly because they lack experience developing large-scale systems and partly because SDIs are unstructured in nature. Even engineers who have some experience building such systems are not comfortable with these interviews, mainly due to the open-ended nature of design problems that do not have a standard answer.',
    link: 'https://designgurus.org/course/grokking-the-system-design-interview',
  },
  {
    title: 'Good to Great',
    author: 'Jim Collins',
    description:
      'Built to Last, the defining management study of the nineties, showed how great companies triumph over time and how long-term sustained performance can be engineered into the DNA of an enterprise from the verybeginning. But what about the company that is not born with great DNA? How can good companies, mediocre companies, even bad companies achieve enduring greatness?',
    link: 'https://www.amazon.com/Good-Great-Some-Companies-Others/dp/0066620996',
  },
  {
    title: 'Zero to One',
    author: 'Peter Thiel and Blake Masters',
    description:
      'The great secret of our time is that there are still uncharted frontiers to explore and new inventions to create. In Zero to One, legendary entrepreneur and investor Peter Thiel shows how we can find singular ways to create those new things.',
    link: 'https://www.amazon.com/Zero-One-Notes-Startups-Future/dp/0804139296',
  },
  {
    title: 'Outliers',
    author: 'Malcolm Gladwell',
    description:
      'In this stunning new book, Malcolm Gladwell takes us on an intellectual journey through the world of "outliers"--the best and the brightest, the most famous and the most successful. He asks the question: what makes high-achievers different?',
    link: 'https://www.amazon.com/Outliers-Story-Success-Malcolm-Gladwell/dp/0316017930',
  },
  {
    title: 'Performance Javascript',
    author: 'Nicholas C. Zakas',
    description:
      "If you're like most developers, you rely heavily on JavaScript to build interactive and quick-responding web applications. The problem is that all of those lines of JavaScript code can slow down your apps. This book reveals techniques and strategies to help you eliminate performance bottlenecks during development. You'll learn how to improve execution time, downloading, interaction with the DOM, page life cycle, and more.",
    link: 'https://www.amazon.com/High-Performance-JavaScript-Application-Interfaces/dp/059680279X',
  },
  {
    title: 'Blood, Sweat and Pixels',
    author: 'Jason Schreier',
    description:
      "Developing video games—hero's journey or fool's errand? The creative and technical logistics that go into building today's hottest games can be more harrowing and complex than the games themselves, often seeming like an endless maze or a bottomless abyss. In Blood, Sweat, and Pixels, Jason Schreier takes readers on a fascinating odyssey behind the scenes of video game development, where the creator may be a team of 600 overworked underdogs or a solitary geek genius. Exploring the artistic challenges, technical impossibilities, marketplace demands, and Donkey Kong-sized monkey wrenches thrown into the works by corporate, Blood, Sweat, and Pixels reveals how bringing any game to completion is more than Sisyphean—it's nothing short of miraculous.",
    link: 'https://www.amazon.com/Blood-Sweat-Pixels-Triumphant-Turbulent/dp/0062651234',
  },
  {
    title: 'Naked Economics',
    author: 'Charles Wheelan',
    description:
      "Naked Economics makes up for all of those Econ 101 lectures you slept through (or avoided) in college, demystifying key concepts, laying bare the truths behind the numbers, and answering those questions you have always been too embarrassed to ask. For all the discussion of Alan Greenspan in the media, does anyone know what the Fed actually does? And what about those blackouts in California? Were they a conspiracy on the part of the power companies? Economics is life. There's no way to understand the important issues without it. Now, with Charles Wheelan's breezy tour, there's no reason to fear this highly relevant subject. With the commonsensical examples and brilliantly acerbic commentary we've come to associate with The Economist, Wheelan brings economics to life. Amazingly, he does so with nary a chart, graph, or mathematical equation in sight―certainly a feat to be witnessed firsthand. Economics is a crucial subject. There's no way to understand the important issues without it. Now, with Charles Wheelan's breezy tour, there's also no reason to fear it.",
    link: 'https://www.amazon.com/Naked-Economics-Undressing-Dismal-Science/dp/0393049825'
  },
];
