import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import { KeyIcon, UserCircleIcon } from '@heroicons/react/outline';
import { classNames } from '../../helpers/className';
import StudentProflie from './StudentProfile';
import TeacherProfile from './TeacherProfile';
import Password from './Password';
import Announcements from './Announcements';
import { globalRole, globalUser } from '../../state';
import { useHookstate } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { useHistory } from 'react-router-dom';
import { GUEST_STUDENT_ID } from '../../constants';
interface Props {}

const subNavigation = [
  {
    name: 'Profile',
    href: '#Profile',
    icon: UserCircleIcon,
    forStudents: true,
    ssoEnabled: true,
  },
  {
    name: 'Account',
    href: '#Account',
    icon: UserCircleIcon,
    forTeachers: true,
    ssoEnabled: true,
  },
  {
    name: 'Password',
    href: '#Password',
    icon: KeyIcon,
    forStudents: true,
    forTeachers: true,
    ssoEnabled: false,
  },
];
const SettingsPage: React.FunctionComponent<Props> = (props: Props) => {
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const isTeacher = role.value === 'teacher';
  const user = useHookstate(globalUser);
  const [activeTab, setActiveTab] = useState(isTeacher ? 'Account' : 'Profile');
  const history = useHistory();
  useEffect(() => {
    setActiveTab(window.location.href.split('#')[1]);
  }, [window.location.href]);
  return (
    <Layout bg>
      <main className='relative'>
        <div className='max-w-screen-xl px-4 pb-6 mx-auto sm:px-6 lg:pb-16 lg:px-8'>
          {GUEST_STUDENT_ID === user.value.id ? (
            <div className='flex items-center justify-center p-4 text-center bg-red-100 border border-red-400 text-red-700'>
              <p className='text-sm'>
                You are currently logged in as a guest student. No Access to
                this page.
              </p>
            </div>
          ) : (
            <div className='overflow-hidden bg-white rounded-lg shadow'>
              <div className='divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x'>
                <aside className='py-6 lg:col-span-3'>
                  <nav className='space-y-1'>
                    {subNavigation.map((item) => {
                      if (
                        (item.forTeachers && isTeacher) ||
                        (item.forStudents && !isTeacher)
                      ) {
                        return (
                          <div
                            key={item.name}
                            onClick={() => history.push(item.href)}
                            className={classNames(
                              activeTab === item.name
                                ? 'bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700'
                                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                              'group border-l-4 px-3 py-2 flex items-center text-sm font-medium cursor-pointer'
                            )}
                            aria-current={
                              item.name === activeTab ? 'page' : undefined
                            }
                          >
                            <item.icon
                              className={classNames(
                                item.name === activeTab
                                  ? 'text-blue-500 group-hover:text-blue-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                              )}
                              aria-hidden='true'
                            />
                            <span className='truncate'>{item.name}</span>
                          </div>
                        );
                      }
                    })}
                  </nav>
                </aside>

                <div className='divide-y divide-gray-200 lg:col-span-9'>
                  {activeTab === 'Account' && <TeacherProfile />}
                  {activeTab === 'Profile' && <StudentProflie />}
                  {activeTab === 'Password' && <Password />}
                  {activeTab === 'Announcements' && <Announcements />}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default SettingsPage;
