import { AxiosResponse } from 'axios';
import { ILikes } from '../pages/Profile/Student';
import { IStudent } from '../types';
import a from './config';

const fetchStudentByObjectId = (studentId: string): Promise<AxiosResponse> =>
  a.get(`/students/${studentId}`);

const fetchTeacherById = (teacherId: number): Promise<AxiosResponse> =>
  a.get(`/teachers/${teacherId}`);

const updateStudentInfo = async (
  updatedInfo: IStudent
): Promise<AxiosResponse> => a.put('/students/update', updatedInfo);

const fetchClassesByStudentId = (
  studentId: number,
  page?: number,
  pageSize?: number,
  type?: string
): Promise<AxiosResponse> => {
  return a.get(`/cohorts`, {
    params: { studentId, page, pageSize, type },
  });
};

const fetchTeacherInfoById = (teacherId: number): Promise<AxiosResponse> => {
  return a.get(`/teachers/${teacherId})`);
};

const getTeacherClassCounter = (teacherId: number): Promise<AxiosResponse> => {
  return a.get(`/cohorts/counter/teacher/${teacherId}`);
};

const getStudentClassCounter = (studentId: string): Promise<AxiosResponse> => {
  return a.get('/cohorts/counter/student/' + studentId);
};

const uploadUserAvatar = (data: FormData): Promise<AxiosResponse> => {
  return a.post('/files/userAvatar', data);
};

const updateLikes = (data: ILikes): Promise<AxiosResponse> => {
  return a.put('/students/likes', data);
};

const fetchStudentByStudentId = (studentId: string): Promise<AxiosResponse> => {
  return a.get(`/students/${studentId}`);
};

const fetchCohorts = ({
  instructorId,
  page,
  pageSize,
  status,
  studentId,
  selectedTeachers,
  selectedDays
}: {
  instructorId?: number;
  page?: number;
  pageSize?: number;
  status?: string;
  studentId?: string;
  selectedTeachers: string[];
  selectedDays: number[];
}): Promise<AxiosResponse> => {
  return a.get('/cohorts', {
    params: { page, pageSize, status, instructorId, studentId, selectedTeachers, selectedDays },
  });
};

const fetchClassesByTeacherId = (
  instructorId: number,
  page?: number,
  pageSize?: number,
  type?: string
): Promise<AxiosResponse> => {
  return a.get('/cohorts', {
    params: { page, pageSize, type, instructorId },
  });
};

const getClassInfo = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> => {
  return a.get('/classes/info/student', { params: { classId, studentId } });
};

const handleGetStudentsByClassId = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> => {
  return a.get(`/classes/roster`, {
    params: { classId, studentId },
  });
};

const getGradingPageData = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> => {
  return a.get('/classes/gradingData', { params: { classId, studentId } });
};

const UserService = {
  fetchCohorts,
  fetchStudentByObjectId,
  fetchTeacherById,
  updateStudentInfo,
  fetchClassesByStudentId,
  fetchTeacherInfoById,
  uploadUserAvatar,
  updateLikes,
  fetchStudentByStudentId,
  fetchClassesByTeacherId,
  getClassInfo,
  handleGetStudentsByClassId,
  getGradingPageData,
  getTeacherClassCounter,
  getStudentClassCounter,
};

export default UserService;
