import React from 'react';
import AuthController from '../../services/auth';
import { globalUser } from '../../state/index';
import { useForm } from '@mantine/form';
import { Button, Input } from '@bctc/components';
import { notify } from '@bctc/components';
import { useAuth } from 'oidc-react';

interface Props {}

const checkPasswordForErrors = (password: string) => {
  if (password.length < 6) {
    return 'Your password must be at least 6 characters long';
  }
};

const Password: React.FC<Props> = (props) => {
  const form = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      oldPassword: checkPasswordForErrors,
      newPassword: checkPasswordForErrors,
      confirmNewPassword: (value, values) =>
        checkPasswordForErrors(value) ||
        (values.newPassword !== value ? 'Your passwords must match' : null),
    },
    validateInputOnChange: true,
  });
  async function handleSave() {
    const { data } = await AuthController.resetPassword({
      email: globalUser.value.profile.email,
      oldPassword: form.values.oldPassword,
      newPassword: form.values.newPassword,
    });
    if (!data) return;
    form.reset();
    notify({ description: 'Your password has been successfully changed!' });
  }
  const _auth = useAuth();

  if (_auth.userData) {
    return (
      <div className='px-4 py-6 sm:p-6 lg:pb-6'>
        <Button
          onClick={() =>
            window.location.replace(
              'https://sso.thefuturesphere.com/if/user/#/settings',
            )
          }
        >
          Change Password in SSO
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className='px-4 py-6 sm:p-6 lg:pb-6'>
        <div>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>
            Change Password
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            You may update your password here on the next login.
          </p>
        </div>
      </div>
      <div className='flex justify-center py-2'>
        <div className='w-5/6 md:w-1/2'>
          <div className='space-y-6 sm:space-y-5'>
            <Input
              label='Old Password'
              type='password'
              {...form.getInputProps('oldPassword')}
            />
            <Input
              label='New Password'
              type='password'
              {...form.getInputProps('newPassword')}
            />
            <Input
              label='Confirm New Password'
              type='password'
              {...form.getInputProps('confirmNewPassword')}
            />
          </div>
        </div>
      </div>
      <div className='flex justify-end px-4 py-4 mt-4 sm:px-6'>
        <button
          onClick={handleSave}
          type='submit'
          className='inline-flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white bg-blue-700 border border-transparent rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        >
          Save
        </button>
      </div>
    </>
  );
};

export default Password;
