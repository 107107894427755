/* eslint-disable react/prop-types */

import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import React from 'react';
import { Link } from 'react-router-dom';

export interface ILink {
  title: string;
  href: string;
  isCurrent?: boolean;
}

interface Props {
  links: ILink[];
}

const Breadcrumbs: React.FC<Props> = ({ links }) => {
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-4'>
        <li>
          <div>
            <Link
              to={links[0].href}
              className={classNames({
                'text-gray-300': links.length > 1,
                'hover:text-white': links.length > 1,
                'hover:text-gray-200': links.length === 1,
                'text-white': links.length === 1,
              })}
            >
              <HomeIcon className='flex-shrink-0 w-5 h-5' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </Link>
          </div>
        </li>
        {links.slice(1).map((page, i) => (
          <li key={i} title={page.title}>
            <div className='flex items-center'>
              <ChevronRightIcon
                className='flex-shrink-0 w-5 h-5 text-gray-300'
                aria-hidden='true'
              />
              <Link
                to={page.href}
                className={classNames(
                  'max-w-xs truncate ml-4 text-sm font-medium',
                  {
                    'text-gray-300': !page.isCurrent,
                    'hover:text-white': !page.isCurrent,
                    'hover:text-gray-200': page.isCurrent,
                    'text-white': page.isCurrent,
                  }
                )}
                aria-current={page.isCurrent ? 'page' : undefined}
              >
                {page.title}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
