export const useLoadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    console.log('load');
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.body.appendChild(script);
  });
};
