import React, { useState } from 'react';
import Layout from '../Layout';
import { globalUser } from '../../state';
import {
  NewspaperIcon,
  InformationCircleIcon,
  ClipboardCheckIcon,
  AnnotationIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import dayjs from 'dayjs';
import { useHookstate } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import NotificationService, {
  fetchNotifications,
} from '../../services/notification';

interface Props {}

const NotificationPage: React.FunctionComponent<Props> = (props: Props) => {
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const [notifications, setNotifications] = useState([]);
  const getNotifications = async () => {
    const { data: response } = await fetchNotifications(user.value.profile.id);
    if (!response) return;
    setNotifications(response.data);
  };
  const handleReadAllNotification = async () => {
    await NotificationService.markAllNotificationAsRead(user.value.profile.id);
    setNotifications([]);
  };
  const handleRead = async (notificationId: string) => {
    await NotificationService.markNotificationAsRead(notificationId);
    getNotifications();
  };
  const getLink = (notification): string => {
    switch (notification.type) {
      case 'SUBMISSION':
        return `/submission/${notification.relatedId}`;
      case 'GRADING':
        return `/class/${notification.relatedClass}/${notification.relatedClassIndex}`;
      case 'COMMENT':
        return `/class/${notification.relatedClass}/${notification.relatedClassIndex}`;
      case 'ANNOUNCEMENT':
        return `/announcements`;
    }
  };
  React.useEffect(() => {
    getNotifications();
  }, []);
  const handleTypeIcon = (type: string) => {
    switch (type) {
      case 'SUBMISSION':
        return (
          <NewspaperIcon
            className='flex-shrink-0 h-6 w-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'ANNOUNCEMENT':
        return (
          <InformationCircleIcon
            className='flex-shrink-0 h-6 w-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'GRADING':
        return (
          <ClipboardCheckIcon
            className='flex-shrink-0 h-6 w-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'COMMENT':
        return (
          <AnnotationIcon
            className='flex-shrink-0 h-6 w-6 text-blue-600'
            aria-hidden='true'
          />
        );
    }
  };
  return (
    <Layout bg>
      <div className='mx-4 md:mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-6xl lg:px-8 bg-white p-6 rounded-md'>
        {notifications && notifications.length ? (
          <div className='pb-5 pt-2 pl-2 space-y-6 sm:flex sm:space-y-0 sm:space-x-10'>
            <div className='flow-root'>
              <a
                onClick={() => handleReadAllNotification()}
                className='-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer'
              >
                <TrashIcon
                  className='flex-shrink-0 h-5 w-5 text-gray-500'
                  aria-hidden='true'
                />
                <span className='ml-2 text-gray-500 text-sm'>
                  Mark all as read
                </span>
              </a>
            </div>
          </div>
        ) : null}
        {notifications ? (
          notifications.length ? (
            <>
              <ul role='list' className='rounded-lg overflow-hidden'>
                {notifications.map((item) => (
                  <div key={item._id} className='relative mb-6 rounded-xs pl-2'>
                    <a
                      onClick={() => handleRead(item)}
                      href={getLink(item)}
                      className='-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer'
                    >
                      {handleTypeIcon(item.type)}
                      <div className='ml-4 w-full'>
                        <p className='mt-1 text-md text-gray-500'>
                          {item.message}
                        </p>
                        <p className='mt-1 text-xs text-gray-500 flex justify-between items-center'>
                          {dayjs(item.createAt).fromNow()}
                        </p>
                      </div>
                    </a>
                    <a
                      onClick={() => handleRead(item)}
                      className='cursor-pointer absolute right-4 bottom-0 text-blue-400 text-sm hover:text-blue-500'
                    >
                      read
                    </a>
                  </div>
                ))}
              </ul>
            </>
          ) : (
            <h1 className='bg-white rounded-xl text-center p-3 text-lg'>
              There are no notifications right now.
            </h1>
          )
        ) : (
          <h1 className='bg-white rounded-xl text-center p-3 text-lg text-gray-500'>
            Loading...
          </h1>
        )}
      </div>
    </Layout>
  );
};

export default NotificationPage;
