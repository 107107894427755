import { useHookstate } from '@hookstate/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Empty from '../../../components/Empty';
import { globalRole } from '../../../state';
import { CohortWithDetails } from '../../../types';
import ClassListItem from '../ClassListItem';
import { nanoid } from 'nanoid';
import { useState, useEffect } from 'react';
import { weekdaysName } from '../constants';
import { DropdownData } from '@bctc/components';
import { MultiDropdown } from '../../../components/MultiDropDown';

type Props = {
  loading: boolean;
  cohortsData: CohortWithDetails[];
  teacherNames: DropdownData[];
  onSelectedTeachersChange: (selectedTeachers: DropdownData['id'][]) => void;
  onSelectedDayChange: (selectedDays: DropdownData['id'][]) => void;
};

const CohortsTable: React.FC<Props> = ({
  loading,
  cohortsData,
  teacherNames,
  onSelectedTeachersChange,
  onSelectedDayChange,
}) => {
  const role = useHookstate(globalRole);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [selectedTeachersID, setSelectedTeachersID] = useState<
    DropdownData['id'][]
  >([]);

  const handleTeacherIDSelect = (selectedIds: DropdownData['id'][]) => {
    setSelectedTeachersID(selectedIds);
  };

  useEffect(() => {
    if (onSelectedTeachersChange) {
      onSelectedTeachersChange(selectedTeachersID);
    }
  }, [selectedTeachersID, onSelectedTeachersChange]);

  const [isDayDropdownOpen, setIsDayDropDownOpen] = useState(false);

  const toggleDayDropdown = () => {
    setIsDayDropDownOpen(!isDayDropdownOpen);
  };

  const [selectedDays, setSelectedDays] = useState<DropdownData['id'][]>([]);
  const handleSelectedDayChange = (selectedIds: DropdownData['id'][]) => {
    setSelectedDays(selectedIds);
  };
  useEffect(() => {
    if (onSelectedDayChange) {
      onSelectedDayChange(selectedDays);
    }
  }, [selectedDays, onSelectedDayChange]);
  const allDays: DropdownData[] = [
    { id: '0', title: 'Monday' },
    { id: '1', title: 'Tuesday' },
    { id: '2', title: 'Wednesday' },
    { id: '3', title: 'Thursday' },
    { id: '4', title: 'Friday' },
    { id: '5', title: 'Saturday' },
    { id: '6', title: 'Sunday' },
  ];

  return (
    <div>
      <table className='table-auto overflow-x-auto'>
        <thead>
            <tr className='border-t border-gray-200'>
              <th
                className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap w-1/100'
                scope='col'
              >
                <span className='lg:pl-2'>Class Title</span>
              </th>
              <th
                className='px-6 py-3 pl-0 pr-0 text-xs font-medium text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap w-1/100'
                scope='col'
              >
                <div className='relative w-40'>
                  <MultiDropdown
                    data={teacherNames}
                    onChange={handleTeacherIDSelect}
                    value={selectedTeachersID}
                    noOptionText='TEACHER'
                  ></MultiDropdown>
                </div>
              </th>
              <th
                className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap w-1/100'
                scope='col'
              >
                Students
              </th>

              <th
                className='py-3 pl-16 pr-4 text-xs font-medium tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap w-1/100'
                scope='col'
              >
                <div className='flex justify-end'>
                  <MultiDropdown
                    data={allDays}
                    onChange={handleSelectedDayChange}
                    value={selectedDays}
                    noOptionText='WEEKDAY / WEEKEND'
                  />
                </div>
              </th>
            </tr>
          </thead>
        {loading ? (
          <tbody className='bg-white divide-y divide-gray-100'>
            {Array(cohortsData.length)
              .fill(0)
              .map((_, i) => (
                <tr key={i} className='animate-pulse'>
                  <td className='px-6 py-4'>
                    <div className='w-48 h-4 bg-gray-200 rounded-md'></div>
                  </td>
                  <td className='px-6 py-4'>
                    <div className='w-32 h-4 bg-gray-200 rounded-md'></div>
                  </td>
                  <td className='px-6 py-4'>
                    <div className='w-24 h-4 bg-gray-200 rounded-md'></div>
                  </td>
                  <td className='px-6 py-4 text-right'>
                    <div className='w-128 h-4 bg-gray-200 rounded-md'></div>
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody className='bg-white divide-y divide-gray-100'>
            {cohortsData?.length && cohortsData ? (
              cohortsData?.map((cohort) => {
                return (
                  <ClassListItem {...cohort} key={nanoid()} role={role.value} />
                );
              })
            ) : (
              <Empty />
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CohortsTable;
