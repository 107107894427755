import React, { useEffect, useRef } from 'react';
import { editor } from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';

export interface CodingEditorProps {
  handleRunCode: (code: string) => void;
  runningCode: boolean;
  onCodeChange?: (code: string) => void;
  value?: string;
  editable: boolean;
  language?: string;
}

export const CodingEditor: React.FC<CodingEditorProps> = ({
  handleRunCode,
  runningCode,
  onCodeChange,
  value,
  editable,
  language,
}: CodingEditorProps) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

  useEffect(() => {
    self.MonacoEnvironment = {
      getWorker(_) {
        return new editorWorker();
      },
    };

    const container = document.getElementById('container');
    if (container) {
      if (!editorRef.current) {
        editor.defineTheme('FS', {
          base: 'vs',
          inherit: true,
          rules: [],
          colors: {
            'editor.background': '#f3f4f6',
          },
        });

        editorRef.current = editor.create(container, {
          roundedSelection: true,
          scrollBeyondLastLine: false,
          theme: 'FS',
          automaticLayout: true,
          fixedOverflowWidgets: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (editorRef.current && editorRef.current.getModel()) {
      editorRef.current.updateOptions({ readOnly: !editable });
      if (value !== editorRef.current.getValue()) {
        editorRef.current.setValue(value || '');
      }
      if (language) {
        editor.setModelLanguage(
          editorRef.current.getModel() as editor.ITextModel,
          language
        );
      }
    }
  }, [editable, value, language]);

  useEffect(() => {
    const subscription = editorRef.current?.onDidChangeModelContent(() => {
      onCodeChange(editorRef.current?.getValue() || '');
    });
    return () => subscription?.dispose();
  }, [onCodeChange]);

  return (
    <div className='relative h-full pt-5' id='container'>
      <button
        className='absolute z-10 px-4 py-2 font-bold bg-blue-500 text-white rounded bottom-5 right-5'
        onClick={() => handleRunCode(editorRef.current?.getValue() || '')}
        disabled={runningCode}
      >
        {runningCode ? 'Running...' : 'Run'}
      </button>
    </div>
  );
};
