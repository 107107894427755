import React, { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import AuthService from '../../services/auth';
import UserService from '../../services/user';
import {
  globalAuthToken,
  globalNotifications,
  globalRole,
  globalUser,
} from '../../state';
import { useHistory } from 'react-router-dom';
import { Persistence } from '@hookstate/persistence';
import { useState } from '@hookstate/core';

const getUserInfoFromToken = async (token: string) => {
  const userResponse = await AuthService.getUserInfoByToken(token);
  const { role, uid } = userResponse.data;

  if (role === 'student') {
    const response = await UserService.fetchStudentByObjectId(uid);
    return {
      role: role,
      user: response.data,
    };
  } else {
    const response = await UserService.fetchTeacherById(uid);
    response.data.cohorts = null;
    return {
      role: role,
      user: response.data,
    };
  }
};

export interface Props {}

const SSOCallbackPage: React.FC<Props> = (props: Props) => {
  const auth = useAuth();
  const notification = useState(globalNotifications);
  const history = useHistory();

  const authToken = useState(globalAuthToken);
  const roleState = useState(globalRole);
  const userState = useState(globalUser);

  useEffect(() => {
    if (auth.userData === null) {
      return;
    }
    const jwtToken = auth.userData.id_token;
    AuthService.loginWithSSO({
      token: jwtToken,
    })
      .then((response) => {
        if (!response.data) {
          if (response.data.data === 'Invalid token') {
            history.push('/login');
            notification.set({
              duration: 5000,
              title: 'Error',
              description: 'Invalid login token',
              show: true,
              type: 'error',
            });
            auth.signOut();
            return;
          }
          if (response.data.data === 'Please link with IDS!') {
            notification.set({
              duration: 5000,
              title: 'Error',
              description: 'Plesae login first to link with SSO!',
              show: true,
              type: 'warning',
            });
            window.location.replace('/sso/link');
            return;
          }
          if (response.data.data === 'User not found') {
            window.location.replace('/register');
            return;
            // todo: redirct to profile register page, use keycloak.tokenParsed to get user info
          }
          notification.set({
            duration: 5000,
            title: 'Error',
            description: response.data,
            show: true,
            type: 'error',
          });

          setTimeout(() => {
            auth.signOutRedirect();
            history.push('/login');
          }, 3000);
        } else {
          globalAuthToken.set(response.data);
          authToken.attach(Persistence('state.authToken'));
          getUserInfoFromToken(response.data)
            .then((info) => {
              globalRole.set(info.role);
              globalUser.set(info.user);
              roleState.attach(Persistence('state.role'));
              userState.attach(Persistence('state.user'));
              history.push('/');
            })
            .catch((error) => {
              console.error(error);
              notification.set({
                duration: 5000,
                title: 'Error',
                description: error,
                show: true,
                type: 'error',
              });
            });
          return;
        }
      })
      .catch((e) => {
        console.error(e);
        notification.set({
          duration: 5000,
          title: 'Error',
          description: e?.message || e,
          show: true,
          type: 'error',
        });
        alert('Error occured.' + e?.message || e + ' Please try again');
        history.push('/login');
      });
  }, [auth.userData]);

  return (
    <div>
      <h1>Signing in... Please wait. Do not refresh or go back.</h1>
    </div>
  );
};

export default SSOCallbackPage;
