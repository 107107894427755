import { useState } from '@hookstate/core';
import React from 'react';
import './loading.css';

const generateRandomRange = (min: number, max: number): number =>
  Math.floor(Math.random() * max) + min;

interface Props {}

const FullscreenLoading: React.FC<Props> = () => {
  const position = useState({ top: 50, left: 50 });
  const [loading, setLoading] = React.useState(false);
  const generateRandomPosition = () => {
    setLoading(true);
    const randomTop = generateRandomRange(10, 80);
    const randomLeft = generateRandomRange(10, 80);

    position.top.set(randomTop);
    position.left.set(randomLeft);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  return (
    <div className='loader-container'>
      <div
        className='carrier'
        style={{
          top: `${position.top.value}%`,
          left: `${position.left.value}%`,
        }}
      >
        <div
          className='preloader'
          onMouseOver={loading ? noop : generateRandomPosition}
        >
          <div className='preloader__ring'>
            <div className='preloader__sector'>F</div>
            <div className='preloader__sector'>u</div>
            <div className='preloader__sector'>t</div>
            <div className='preloader__sector'>u</div>
            <div className='preloader__sector'>r</div>
            <div className='preloader__sector'>e</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'>S</div>
            <div className='preloader__sector'>p</div>
            <div className='preloader__sector'>h</div>
            <div className='preloader__sector'>e</div>
            <div className='preloader__sector'>r</div>
            <div className='preloader__sector'>e</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
          </div>
          <div className='preloader__ring'>
            <div className='preloader__sector'>未</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'>来</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'>领</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'>域</div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
            <div className='preloader__sector'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullscreenLoading;
