import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useHookstate } from '@hookstate/core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import FullscreenLoading from '../../components/FullscreenLoading';
import AuthService from '../../services/auth';
import { globalNotifications } from '../../state';
import { useAuth } from 'oidc-react';

const SSOLink: React.FC = () => {
  const auth = useAuth();

  const stepNumber = useHookstate(0);
  const password = useHookstate('');

  if (auth.isLoading) {
    return <FullscreenLoading />;
  }

  if (auth.userData === null) {
    window.location.replace('/login');
    return;
  }

  const nextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const authLinkResult = await AuthService.linkWithSSO({
        token: auth.userData.id_token,
        password: password.value,
      });
      if (authLinkResult.data === 'Account connected to SSO') {
        globalNotifications.set({
          show: true,
          title: 'Success',
          description:
            'Your account has been linked successfully, please login again.',
          duration: 5000,
          type: 'success',
        });
        window.location.replace('/login');
        globalNotifications.set({
          show: true,
          title: 'Success',
          description:
            'Your account has been linked successfully, please login again.',
          duration: 5000,
          type: 'success',
        });
      } else {
        globalNotifications.set({
          show: true,
          title: 'Error',
          description: authLinkResult.data.data,
          duration: 5000,
          type: 'error',
        });
      }
    } catch (e) {
      console.error(e);
      globalNotifications.set({
        show: true,
        title: 'Error',
        description: e?.message || e,
        duration: 5000,
        type: 'error',
      });
    }
  };

  return (
    <div className='inset-0 absolute flex flex-col justify-center items-center w-full h-full z-0 gap-4'>
      <div className='-z-1 absolute inset-0'>
        <FullscreenLoading />
      </div>
      <div className='bg-white shadow rounded-lg max-w-xs'>
        <div
          className={`${
            stepNumber.value === 0 ? '' : 'hidden'
          } px-4 py-5 sm:p-6`}
        >
          <div className='flex'>
            <Link
              className='bg-white rounded-full h-6 w-6 hover:bg-gray-100 transition-all'
              to='/login'
            >
              <ArrowLeftIcon className='h-4 w-4 mx-auto my-1' />
            </Link>
            <h3 className='ml-2 text-lg leading-6 font-medium text-gray-900'>
              Link to an existing account.
            </h3>
          </div>
          <div className='mt-2 max-w-sm text-sm text-gray-500'>
            <p>
              Put the password you used to log into this account below. This is
              to ensure that you are the owner of this account:
            </p>
            <b>{auth.userData.profile.email}</b>
          </div>
          <form className='mt-5 sm:flex sm:items-center'>
            <div className='w-full sm:max-w-xs'>
              <label htmlFor='password' className='sr-only'>
                Password
              </label>
              <input
                value={password.value}
                onChange={(e) => password.set(e.target.value)}
                type='password'
                name='password'
                id='password'
                className='shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
                placeholder='Enter your password'
              />
            </div>
            <button
              onClick={nextStep}
              type='submit'
              className='mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
            >
              Link
            </button>
          </form>
          <div className='mt-2 max-w-sm text-sm text-gray-500'>
            <p>
              If this account does not belong to you, you can{' '}
              <a
                className='text-blue-800 cursor-pointer'
                onClick={() =>
                  auth.signOutRedirect({ redirectUri: window.location.origin })
                }
              >
                Logout
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSOLink;
