import axios from 'axios';
import { error } from '@bctc/components';
export const apiUrl =
  import.meta.env.VITE_API_URL || 'https://app.thefuturesphere.com';

const a = axios.create({
  baseURL: apiUrl,
  timeout: 2 * 60 * 1000,
});

a.interceptors.request.use((config) => {
  const token = getLocalToken();
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
});

const handleError = (response) => {
  if (response?.name === 'AbortError' && response?.name === 'CanceledError') {
    return response;
  }
  console.error(response);
  error(response.toString());
  return response;
};

a.interceptors.response.use((response) => response, handleError);
axios.interceptors.response.use((response) => response, handleError);

export const getLocalToken = (): string => {
  const localToken = localStorage.getItem('state.authToken');
  if (localToken) {
    const cleanedToken = localToken.replaceAll('"', '');
    return cleanedToken;
  }
  return '';
};

export const clearToken = (): void => {
  localStorage.removeItem('state.authToken');
  localStorage.removeItem('state.user');
  localStorage.removeItem('state.role');
};

export const clearThirdPartyToken = (): void => {
  localStorage.removeItem('discordThirdPartyRegister');
  localStorage.removeItem('githubThirdPartyRegister');
  localStorage.removeItem('googleThirdPartyRegister');
};

export default a;
