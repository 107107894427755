import { AxiosResponse } from 'axios';
import a from './config';

const getClassSubmission = (classId: string): Promise<AxiosResponse> =>
  a.get('/submissions/class', { params: { classId } });

const fetchSubmissionsByClassIndex = (
  classId: string,
  classIndex: number,
): Promise<AxiosResponse> =>
  a.get('/submissions/class/index', { params: { classId, classIndex } });

const getUngradedSubmissionsByClassId = (
  classId: string,
): Promise<AxiosResponse> =>
  a.get('/submissions/class/ungraded', { params: { classId } });

const getSubmissionsByStudent = (
  studentId: string,
  classId: string,
): Promise<AxiosResponse> =>
  a.get('/submissions/student', {
    params: { studentId, classId },
  });

const fetchSubmissionsById = (id: string): Promise<AxiosResponse> =>
  a.get('/submissions/id', { params: { id } });

const getClassSubmissionStats = (
  classId: string,
  classIndex: number,
): Promise<AxiosResponse> =>
  a.get('/submissions/stats/class', { params: { classId, classIndex } });

const sendRemindEmail = (payload: {
  classId: string;
  classIndex: number;
  studentIdList: string[];
}): Promise<AxiosResponse> =>
  a.post('/submissions/reminder', { data: payload });

const getNextSubmission = (
  classId: string,
  classIndex: number,
  currentSubmission: string,
): Promise<AxiosResponse> =>
  a.get('/submissions/next', {
    params: { classId, classIndex, currentSubmission },
  });

const getGradedCount = (
  classId: string,
  classIndex: number,
): Promise<AxiosResponse> =>
  a.get('/submissions/graded', {
    params: { classId, classIndex },
  });

const getAllSubmissionCount = (
  classId: string,
  classIndex: number,
): Promise<AxiosResponse> =>
  a.get('/submissions/all', {
    params: { classId, classIndex },
  });

export const giveScore = (
  submissionId: string,
  score: number,
): Promise<AxiosResponse> => {
  return a.put(`/cohorts/submission/${submissionId}`, { score });
};

export const giveFeedback = (
  submission: string,
  feedback: string,
): Promise<AxiosResponse> => {
  return a.post(`/cohorts/feedback`, { content: feedback, submission });
};

const SubmissionService = {
  getClassSubmission,
  fetchSubmissionsByClassIndex,
  fetchSubmissionsById,
  getUngradedSubmissionsByClassId,
  getClassSubmissionStats,
  getSubmissionsByStudent,
  sendRemindEmail,
  getNextSubmission,
  getGradedCount,
  getAllSubmissionCount,
};

export default SubmissionService;
