import React from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import Empty from '../../components/Empty';

interface Props {
  gradeStats?: {
    grade: number;
    classRank: number;
    latestSubmission: {
      stat: number;
      change: number;
    };
  };
}

const GeneralStats: React.FunctionComponent<Props> = ({
  gradeStats,
}: Props) => {
  return gradeStats ? (
    <dl className='grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x'>
      <div className='px-4 py-5 sm:p-6'>
        <dt className='font-semibold text-gray-600 text-xl'>Final Grade</dt>
        <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
          <div className='flex items-baseline text-xl font-semibold text-blue-600'>
            {gradeStats.grade}%
          </div>
        </dd>
      </div>
      <div className='px-4 py-5 sm:p-6'>
        <dt className='font-semibold text-gray-600 text-xl'>Latest Grade</dt>
        <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
          <div className='flex items-baseline text-xl font-semibold text-blue-600'>
            {gradeStats.latestSubmission.stat}%
          </div>

          {gradeStats.latestSubmission.change >= 0 ? (
            <div className='bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'>
              <ArrowSmUpIcon
                className='-ml-1 mr-0.5 self-center flex-shrink-0 h-5 w-5 text-green-500'
                aria-hidden='true'
              />
              {gradeStats.latestSubmission.change}%
            </div>
          ) : (
            <div className='bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'>
              <ArrowSmDownIcon
                className='-ml-1 mr-0.5 self-center flex-shrink-0 h-5 w-5 text-red-500'
                aria-hidden='true'
              />
              {gradeStats.latestSubmission.change}%
            </div>
          )}
        </dd>
      </div>
      <div className='px-4 py-5 sm:p-6'>
        <dt className='font-semibold text-gray-600 text-xl'>Class Rank</dt>
        <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
          <div className='flex items-baseline text-xl font-semibold text-blue-600'>
            {gradeStats.classRank}
          </div>
        </dd>
      </div>
    </dl>
  ) : (
    <Empty message='No overall and class ranking data.' />
  );
};

export default GeneralStats;
