import React, { useEffect, useState } from 'react';
import { CogIcon, UsersIcon } from '@heroicons/react/outline';
import Layout from '../Layout';
import { classNames } from '../../helpers/className';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import ProfilePage from './Profile';
import Calendar from './Calendar';
import StatusUpdates from './StatusUpdates';
import { useHookstate } from '@hookstate/core';
import { globalRole, globalUser } from '../../state';
import { Persistence } from '@hookstate/persistence';
import UserService from '../../services/user';
import { placeholderAvatar } from '../../constants/assets';
import { TeacherWithDetails } from '../../types';

interface Props {}

const tabIconSize = 18;

const tabs = [
  {
    name: 'Profile',
    href: '#',
    icon: <UsersIcon height={tabIconSize} />,
  },
  // {
  //   name: 'Status Updates',
  //   href: '#',
  //   icon: <StatusOnlineIcon height={tabIconSize} />,
  // },
  // { name: 'Calendar', href: '#', icon: <CalendarIcon height={tabIconSize} /> },
];

export interface ILikes {
  userId: string;
  liked: boolean;
  id: string;
}

const coverImageUrl =
  'https://bctc-files.nyc3.digitaloceanspaces.com/future-sphere-images/profile_bg.jpg';

const TeacherProfilePage: React.FunctionComponent<Props> = () => {
  const [activeTab, setActiveTab] = useState('Profile');
  const loading = useHookstate(true);
  const teacherUser = useHookstate<TeacherWithDetails | null>(null);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  globalRole.attach(Persistence('state.role'));
  const history = useHistory();
  const userId = history.location.pathname.split('/')[2];
  useEffect(() => {
    const getUserInfo = async () => {
      const { data: result } = await UserService.fetchTeacherById(+userId);
      if (!result) return;
      teacherUser.set(result);
      loading.set(false);
    };
    getUserInfo();
  }, [userId]);

  return (
    <Layout navOnly>
      <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last'>
        <div className='filter brightness-50'>
          <img
            className='h-36 w-full object-cover lg:h-48  '
            src={coverImageUrl}
            alt=''
          />
        </div>

        {loading.value ? (
          <div className='flex flex-col items-center justify-center p-40 text-xl text-gray-600'>
            <span>Loading...</span>
          </div>
        ) : teacherUser.value ? (
          <article>
            {/* Profile header */}
            <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
              <div className='-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5'>
                <div className='flex z-20 relative'>
                  <img
                    className='h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32'
                    src={
                      teacherUser.value?.profile.avatar
                        ? teacherUser.value?.profile.avatar
                        : placeholderAvatar
                    }
                    alt='User profile picture'
                  />
                </div>
                <div className='mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1'>
                  <div className='sm:hidden 2xl:block mt-6 min-w-0 flex-1'>
                    <h1 className='text-2xl font-bold text-gray-900 truncate'>
                      {teacherUser.value.profile.firstName}{' '}
                      {teacherUser.value.profile.lastName}
                    </h1>
                  </div>
                  <div className='mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4'>
                    {user.value.id === teacherUser.value.profile.id ? (
                      <Link
                        to='/settings'
                        type='button'
                        className='inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                      >
                        <CogIcon
                          className='-ml-1 mr-2 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                        <span>Settings</span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1'>
                <h1 className='text-2xl font-bold text-gray-900 truncate'>
                  {teacherUser.value.profile.firstName}{' '}
                  {teacherUser.value.profile.lastName}
                </h1>
              </div>
            </div>

            {/* Tabs */}
            <div className='mt-6 sm:mt-2 2xl:mt-5'>
              <div className='border-b border-gray-200'>
                <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
                  <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                    {tabs.map((tab) => (
                      <a
                        onClick={() => setActiveTab(tab.name)}
                        key={tab.name}
                        className={classNames(
                          activeTab === tab.name
                            ? 'border-blue-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'inline-flex items-center justify-center whitespace-nowrap py-4 px-4 border-b-2 font-medium text-sm'
                        )}
                        aria-current={
                          activeTab === tab.name ? 'page' : undefined
                        }
                      >
                        <span className='mr-2'>{tab.icon}</span>
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            {!loading.value && (
              <>
                {activeTab === 'Profile' && (
                  <ProfilePage profile={teacherUser.value.profile} />
                )}
                {activeTab === 'Status Updates' && <StatusUpdates />}
                {activeTab === 'Calendar' && <Calendar />}
              </>
            )}
          </article>
        ) : (
          <div className='flex flex-col items-center justify-center py-40 text-xl text-gray-600'>
            <span>404</span>
            <span>User not found</span>
          </div>
        )}
      </main>
    </Layout>
  );
};

export default TeacherProfilePage;
