import { AxiosResponse } from 'axios';
import a from './config';

const createSurvey = (payload: {
  classId: string;
  studentId: string;
  multipleChoice: { question: string; answer: number }[];
  text: { question: string; answer: string }[];
}): Promise<AxiosResponse> => a.post('/survey', payload);

const checkSurvey = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> => {
  return a.get('/survey/verify', {
    params: {
      classId,
      studentId,
    },
  });
};

const getCouponCode = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> => {
  return a.get('/survey/couponCode', {
    params: {
      classId,
      studentId,
    },
  });
};

const SurveyService = {
  createSurvey,
  checkSurvey,
  getCouponCode,
};

export default SurveyService;
