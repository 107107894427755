import AnnouncementService from '../../services/announcements';
import { globalUser } from '../../state';
import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import Modal from '../../components/Modal';
import dayjs from 'dayjs';
import RichTextEditor from 'react-rte';
import ReactHtmlParser from 'react-html-parser';
import { notify, Pagination as PaginationComponent } from '@bctc/components';

interface Props {}

interface ConfirmState {
  prompt: string;
  onSubmit: () => void;
}

const currentDate = dayjs().format('MMDDYY');

const ANNOUNCEMENT_LIMIT = 5;

const initialCreateForm = { title: '', content: '' };

const Announcements: React.FC<Props> = () => {
  const [confirmState, setConfirmState] = useState<ConfirmState | null>(null);
  const createForm = useHookstate({ ...initialCreateForm });
  const editForm = useHookstate({ ...initialCreateForm, id: '' });
  const [announcements, setAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalAnnouncements, setTotalAnnouncements] = useState(0);
  const totalPages = Math.ceil(totalAnnouncements / ANNOUNCEMENT_LIMIT);
  const [createRtfDescription, setCreateRTFDescription] = useState(
    RichTextEditor.createEmptyValue(),
  );
  const [editRtfDescription, setEditRTFDescription] = useState(
    RichTextEditor.createEmptyValue(),
  );
  const handleUpdateForm = (announcement: any) => {
    editForm.set({
      id: announcement.id,
      title: announcement.title,
      content: announcement.content,
    });
    setEditRTFDescription(
      RichTextEditor.createValueFromString(announcement.content, 'html'),
    );
  };

  const handleAdd = async () => {
    const { data } = await AnnouncementService.addAnnouncement({
      title: createForm.title.value,
      content: createForm.content.value,
      announcer: globalUser.value.profile.teacherId,
    });
    if (!data) {
      return;
    }
    notify({ description: 'Your announcement has been posted' });
    createForm.set(initialCreateForm);
    setCreateRTFDescription(RichTextEditor.createEmptyValue());
    changePage(1);
  };

  const handleRemove = async (id: string) => {
    const { data } = await AnnouncementService.removeAnnouncement(id);
    if (!data) return;
    notify({ description: 'Your announcement has been removed' });
    setConfirmState(null);
    changePage(1);
  };

  const handleEdit = async () => {
    const { data: response } = await AnnouncementService.editAnnouncement({
      id: editForm.id.value,
      title: editForm.title.value,
      content: editForm.content.value,
    });
    if (!response) return;
    notify({ description: 'Your announcement has been edited' });
    announcements[
      announcements.findIndex(({ id }) => id === editForm.id.value)
    ] = response;
    setAnnouncements({ ...announcements });
    editForm.id.set('');
  };

  const changePage = async (i: number) => {
    if (i < 1 || (i === totalPages && i !== 1)) return;
    const { data } = await AnnouncementService.getAnnouncements({
      pageSize: ANNOUNCEMENT_LIMIT,
      page: i,
    });
    if (!data) return;
    if (!data.data.length && data.total) {
      changePage(i - 1);
    } else {
      setAnnouncements(data.data);
      setTotalAnnouncements(data.total);
      setCurrentPage(i);
    }
  };

  useEffect(() => {
    changePage(1);
  }, []);
  return (
    <>
      <div className='py-6 px-4 sm:p-6'>
        <div>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Announcements
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            You can manage and create new announcements here
          </p>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='overflow-hidden border-t border-gray-200'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-100'>
                  {/* Mobile Portrait */}
                  <tr className='table-row sm:hidden'>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Category
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Data
                    </th>
                  </tr>
                  {/* Other Sizes */}
                  <tr className='hidden sm:table-row'>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Date/Time
                    </th>
                    <th
                      scope='col'
                      className='hidden lg:block px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Announcer
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Title
                    </th>
                    <th
                      scope='col'
                      className='hidden lg:block px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Description
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {announcements ? (
                    announcements.length ? (
                      announcements.map((v, i) => (
                        <React.Fragment key={v.id}>
                          {/* Mobile Portrait */}
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='table-row sm:hidden cursor-pointer'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              Time/Date
                            </td>
                            <td className='px-6 my-4 text-sm text-gray-500'>
                              <time dateTime={v.createdAt.toString()}>
                                {dayjs(v.createdAt).format('MMDDYY') ===
                                currentDate
                                  ? dayjs(v.createdAt).format('h:mm A')
                                  : dayjs(v.createdAt).format('MMM D, YYYY')}
                              </time>
                            </td>
                          </tr>
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='table-row sm:hidden cursor-pointer'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              Announcer
                            </td>
                            <td className='px-6 my-4 text-sm text-gray-500 max-w-48 truncate'>
                              {/* {v.announcer} */}
                              Staff
                            </td>
                          </tr>
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='table-row sm:hidden cursor-pointer'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              Title
                            </td>
                            <td className='px-6 my-4 text-sm text-gray-500 max-w-48 truncate'>
                              {v.title}
                            </td>
                          </tr>
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='table-row sm:hidden cursor-pointer'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              Content
                            </td>
                            <td className='px-6 my-4 text-sm text-gray-500 max-w-48 truncate'>
                              {v.content}
                            </td>
                          </tr>
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='table-row sm:hidden cursor-pointer mb-4'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              Action
                            </td>
                            <td
                              onClick={(e) => {
                                e.stopPropagation();
                                setConfirmState({
                                  prompt:
                                    'Are you sure you want to remove this announcement?',
                                  onSubmit: () => handleRemove(v.id),
                                });
                              }}
                              className='px-6 py-4 text-sm text-red-400 hover:text-red-600 transition-colors'
                            >
                              Remove
                            </td>
                          </tr>
                          {(currentPage + 1 === totalPages &&
                            i === (totalAnnouncements % 5) - 1) ||
                          i === 4 ? null : (
                            <tr className='table-row sm:hidden'>
                              <td className='py-2 bg-gray-50'></td>
                              <td className='bg-gray-50'></td>
                            </tr>
                          )}
                          {/* Other Sizes */}
                          <tr
                            onClick={() => handleUpdateForm(v)}
                            className='hidden sm:table-row cursor-pointer'
                          >
                            <td className='px-6 py-4 text-sm font-medium text-gray-500'>
                              <time dateTime={v.createdAt.toString()}>
                                {dayjs(v.createdAt).format('MMDDYY') ===
                                currentDate
                                  ? dayjs(v.createdAt).format('h:mm A')
                                  : dayjs(v.createdAt).format('MMM D, YYYY')}
                              </time>
                            </td>
                            <td className='hidden lg:table-cell px-6 my-4 text-sm text-gray-500 max-w-48 truncate'>
                              {/* {v.announcer} */}
                              Staff
                            </td>
                            <td className='px-6 my-4 text-sm text-gray-500 max-w-64 truncate'>
                              {v.title}
                            </td>
                            <td className='hidden lg:table-cell px-6 my-4 text-sm text-gray-500 max-w-64 truncate'>
                              {ReactHtmlParser(v.content)}
                            </td>
                            <td
                              onClick={(e) => {
                                e.stopPropagation();
                                setConfirmState({
                                  prompt:
                                    'Are you sure you want to remove this announcement?',
                                  onSubmit: () => handleRemove(v.id),
                                });
                              }}
                              className='px-6 py-4 text-sm text-red-400 hover:text-red-600 transition-colors'
                            >
                              Remove
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td className='px-6 py-4 text-sm text-gray-500'>
                          There currently aren&apos;t any announcements. You can
                          create one below.
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td className='px-6 py-4 text-sm text-gray-500'>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {totalPages > 1 ? (
                <div>
                  {announcements?.length > 0 ? (
                    <div className='bg-white p-2 flex items-center justify-between border-t border-gray-200'>
                      <PaginationComponent
                        itemsPerPage={ANNOUNCEMENT_LIMIT}
                        setPage={changePage}
                        page={currentPage}
                        total={totalAnnouncements}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className='py-6 px-4 sm:p-6'>
        <div>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Create new announcement
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            You can create a new announcement here to broadcast to all Future
            Sphere students.
          </p>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-6 p-6'>
        <div className='col-span-4 sm:col-span-4'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            Title
          </label>
          <input
            type='text'
            id='title'
            onChange={(e) => createForm.title.set(e.target.value)}
            value={createForm.title.value}
            className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm'
          />
        </div>
        <div className='col-span-4 sm:col-span-4'>
          <label
            htmlFor='description'
            className='block text-sm font-medium text-gray-700'
          >
            Description
          </label>
          <div className='mt-1'>
            {/* <RichTextEditor
              value={createRtfDescription}
              onChange={(e) => {
                // setCreateRTFDescription(e);
                // createForm.content.set(e.toString('html'));
              }}
              className='block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm focus:outline-none'
            /> */}
          </div>
          <p className='mt-2 text-sm text-gray-500'>
            Brief description for the announcement
          </p>
        </div>
      </div>
      <div className='mt-4 py-4 px-4 flex justify-end sm:px-6'>
        <button
          onClick={handleAdd}
          type='submit'
          className='ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        >
          Post Announcement
        </button>
      </div>
      <Modal
        open={!!editForm.id.value}
        close={() => editForm.id.set('')}
        title='Editing an announcement:'
        buttonText='Confirm'
        onSubmit={handleEdit}
      >
        <label
          htmlFor='title'
          className='block text-sm font-medium text-gray-700'
        >
          Title
        </label>
        <div className='mt-1'>
          <input
            type='text'
            id='title'
            className='shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
            placeholder='Announcement Title'
            value={editForm.title.value}
            onChange={(e) => editForm.title.set(e.target.value)}
          />
        </div>
        <label
          htmlFor='description'
          className='mt-3 block text-sm font-medium text-gray-700'
        >
          Description
        </label>
        <div className='mt-1'>
          <RichTextEditor
            value={editRtfDescription}
            onChange={(e) => {
              setEditRTFDescription(e);
              editForm.content.set(e.toString('html'));
            }}
            className='block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm focus:outline-none'
          />
        </div>
        <p className='mt-2 text-sm text-gray-500'>
          More information on what you are announcing
        </p>
      </Modal>
      <Modal
        open={!!confirmState}
        close={() => setConfirmState(null)}
        buttonText='Remove'
        onSubmit={confirmState?.onSubmit}
      >
        <div>
          <div className='mx-auto flex p-2 items-center justify-center h-16 w-16 rounded-full bg-red-100'>
            <TrashIcon className='text-red-600' />
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <h3
              className='text-lg leading-6 font-medium text-gray-900'
              id='modal-title'
            >
              Remove Announcement
            </h3>
            <div className='mt-2'>
              <p className='text-sm text-gray-500'>{confirmState?.prompt}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Announcements;
