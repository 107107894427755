import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { blue } from './constants';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { capitalize } from '@bctc/components';

interface Props {
  classInfo?: { classSession: string; grade: number; type: string }[];
}

const SubmissionsChart: React.FunctionComponent<Props> = ({
  classInfo,
}: Props) => {
  return !classInfo ? (
    <Loading />
  ) : classInfo.length ? (
    <div className='p-4 pt-0 pl-2'>
      <ResponsiveContainer width='100%' height={250}>
        <AreaChart data={classInfo}>
          <defs>
            <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={blue} stopOpacity={1} />
              <stop offset='95%' stopColor={blue} stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='4 4' />
          <YAxis scale='linear' unit='%' name='grades' />
          <Area
            type='linear'
            dataKey='grade'
            stroke={blue}
            fillOpacity={0.5}
            fill='url(#gradient)'
          />
          <Tooltip
            separator=''
            key='sessionNumber'
            formatter={(value, name, props) => [`${value}%`]}
            labelFormatter={(label) =>
              classInfo[label] &&
              `Session ${label + 1}'s ${capitalize(
                classInfo[label].type
              )} Grade:`
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Empty pt={0} />
  );
};

export default SubmissionsChart;
