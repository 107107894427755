import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import 'split-pane-react/esm/themes/default.css';
import { CodingFile, Profile, LiveSessionUser } from '@prisma/client';
import { globalRole, globalUser } from '../../state';
import { CodingEditor } from '../../components/CodingEditor';
import { useHookstate } from '@hookstate/core';
import SplitPane, { Pane } from 'split-pane-react';
import { debounce } from 'lodash';
import { LockClosedIcon, PencilAltIcon } from '@heroicons/react/outline';
import Terminal from '../../components/Terminal';
import { notify, error } from '@bctc/components';
import LiveSessionService from '../../services/liveSession';
import { Persistence } from '@hookstate/persistence';
import { io } from 'socket.io-client';
import { apiUrl, getLocalToken } from '../../services/config';
import { Link } from 'react-router-dom';
import { whiteLogoUrl } from '../../constants/assets';
import { useLoadScript } from '../../helpers/loadLib';
declare global {
  interface Window {
    Sk: any;
  }
}
interface Props {}

const LiveSessionPage: React.FC<Props> = () => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const fileId = window.location.pathname.split('/live/')[1];
  const [fileDetail, setFileDetail] = useState<
    (CodingFile & { owner: Profile }) | null
  >(null);
  const [spectatorList, setSpectatorList] = useState<
    { id: string; nickname: string }[]
  >([]);
  const [terminalContent, setTerminalContent] = useState<string>('');
  const [runningCode, setRunningCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [editFileName, setEditFileName] = useState<string>('');
  const [isEditingFileName, setIsEditingFileName] = useState<boolean>(false);
  const [verticalPanelSize, setVerticalPanelSize] = useState<
    (string | number)[]
  >(['50%', '50%']);
  const [rightHorizontalPanelSize, setRightHorizontalPanelSize] = useState<
    (string | number)[]
  >(['50%', '50%']);
  const [terminalTab, setTerminalTab] = useState<'graph' | 'output'>('graph');
  const [showOutputDot, setShowOutputDot] = useState<boolean>(false);
  const editable = useMemo(() => {
    if (!fileDetail || !user.value) return false;
    return (
      fileDetail.ownerId === user.value.profile.id ||
      (user.value.profile.teacherId !== null &&
        fileDetail.owner.teacherId === null)
    );
  }, [fileDetail, user]);
  const token = getLocalToken();
  const socket = io(apiUrl, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
  const navigation = useMemo(
    () => [
      { name: 'Home', href: '/' },
      { name: 'Classes', href: '/class' },
      { name: 'Files', href: '/files' },
      role.value == 'teacher'
        ? null
        : { name: 'My Grades', href: `/grades/${user?.value?.id}` },

      {
        name: 'Settings',
        href:
          role.value == 'teacher' ? '/settings#Account' : '/settings#Profile',
      },
      { name: 'Announcements', href: '/announcements' },
      { name: '🤖AI Homework Helper', href: '/homework-helper' },
    ],
    [role, user]
  );

  useEffect(() => {
    const loadScripts = async () => {
      try {
        await useLoadScript('/js/skulpt.min.js');
        await useLoadScript('/js/skulpt-stdlib.js');
        setScriptLoaded(true);
      } catch (error) {
        error({
          title: 'Failed to load skulpt',
        });
      }
    };

    loadScripts();
  }, []);

  const OnUserJoin = useCallback(
    (userId: string, nickname: string) => {
      if (userId === user.value.profile.id) return;

      notify({
        title: `${nickname} joined the session ~`,
      });

      setSpectatorList((prevList) => {
        if (prevList.find((spectator) => spectator.id === userId)) {
          return prevList;
        }
        return [...prevList, { id: userId, nickname }];
      });
    },
    [user.value]
  );

  const onUserLeft = useCallback(
    (userId: string) => {
      if (userId === user.value.profile.id) return;

      setSpectatorList((prevList) => {
        const _user = prevList.find((spectator) => spectator.id === userId);
        if (!_user) return prevList;
        notify({
          title: `${_user.nickname} left the session ~`,
        });

        return prevList.filter((spectator) => spectator.id !== userId);
      });
    },
    [user.value]
  );

  const fetchFile = useCallback(async () => {
    if (!fileId || !user) return;
    const { data } = await LiveSessionService.getFileById({ fileId });
    if (!data.spectators) return;
    const _spectatorList: {
      id: string;
      nickname: string;
    }[] = data.spectators.map(
      (spectator: LiveSessionUser & { user: Profile }) => {
        return { id: spectator.userId, nickname: spectator.user.firstName };
      }
    );
    if (
      !_spectatorList.find(
        (spectator) => spectator.id === user.value.profile.id
      )
    ) {
      _spectatorList.push({
        id: user.value.profile.id,
        nickname: user.value.profile?.firstName || '',
      });
    }
    setSpectatorList(_spectatorList);
    setCode(data.content);
    setFileDetail(data);
    socket.emit('JOIN_SESSION', fileId, user.value.profile.id);
  }, [fileId, user]);

  useEffect(() => {
    socket.on('USER_JOIN', OnUserJoin);
    socket.on('USER_LEFT', onUserLeft);
  }, [onUserLeft, OnUserJoin]);

  useEffect(() => {
    socket.on('CODE_CHANGE', (_fileId, content) => {
      if (editable) return;
      if (_fileId === fileId) {
        setCode((prevCode) => {
          return content !== prevCode ? content : prevCode;
        });
      }
    });
    return () => {
      socket.off('CODE_CHANGE');
    };
  }, [editable]);

  useEffect(() => {
    if (fileId && user.value && fileId !== lastFetchedFileId.current) {
      fetchFile();
      lastFetchedFileId.current = fileId;
      return () => {
        socket.emit('LEAVE_SESSION', fileId, user.value.profile.id);
      };
    }
  }, [fileId, user, fetchFile]);

  const lastFetchedFileId = useRef(null as null | string);

  const handleRunPythonCode = async (codeToRun: string) => {
    if (!codeToRun) return notify({ title: 'Please enter some code to run' });
    setRunningCode(true);
    if (window.Sk === undefined) return;
    const _output = [];
    window.Sk.configure({
      output: (text: string) => {
        if (terminalTab !== 'output') {
          setShowOutputDot(true);
        }
        _output.push(text);
      },
    });

    (window.Sk.TurtleGraphics || (window.Sk.TurtleGraphics = {})).target =
      'turtle-canvas';

    window.Sk.misceval
      .asyncToPromise(() => {
        return window.Sk.importMainWithBody('<stdin>', false, codeToRun, true);
      })
      .then(
        () => {
          setTerminalContent(_output.join(''));
          notify({ title: 'Code ran successfully' });
        },
        (err: any) => {
          setTerminalContent('ERR: ' + err.toString());
          if (terminalTab !== 'output') {
            setShowOutputDot(true);
          }
        }
      );
    setRunningCode(false);
  };

  const debouncedHandleCodeChange = useCallback(
    debounce((fileDetailId, code) => {
      socket.emit('CODE_CHANGE', fileDetailId, code);
    }, 100),
    []
  );

  const handleCodeChange = (code: string) => {
    if (!fileDetail || !editable) return;
    debouncedHandleCodeChange(fileDetail.id, code);
  };

  const requestNewJoinCode = async () => {
    if (!fileDetail) return;
    const { data } = await LiveSessionService.requestNewJoinCode({
      fileId: fileDetail.id,
    });
    if (data.error) return error({ title: data.error });
    setFileDetail((prevFileDetail) => {
      if (!prevFileDetail) return prevFileDetail;
      return { ...prevFileDetail, joinCode: data.joinCode };
    });
    navigator.clipboard.writeText(data.joinCode);
    notify({ title: 'Join code copied to clipboard' });
  };

  const handleChangeFileName = async () => {
    try {
      if (!fileDetail || !user.value)
        throw "Couldn't create file at the moment, please try logout and login back.";
      if (!editFileName)
        throw "File name can't be empty, please enter a file name";
      if (editFileName.length > 20)
        throw 'File name must be less than 20 characters';
      if (editFileName.includes(' ')) throw 'File name must not contain spaces';
      if (editFileName.includes('.')) throw "File name must not contain '.'";
      const { data } = await LiveSessionService.changeFileName({
        fileId: fileDetail.id,
        filename: editFileName,
        ownerId: user.value.profile.id,
      });
      if (data.error) return notify({ title: data.error });
      setFileDetail((prevFileDetail) => {
        if (!prevFileDetail) return prevFileDetail;
        return { ...prevFileDetail, filename: data.filename };
      });
      setEditFileName('');
      setIsEditingFileName(false);
    } catch (err) {
      error({
        title:
          typeof err === 'string'
            ? err
            : "Couldn't create file at the moment, please try again later.",
      });
    }
  };

  return (
    <>
      {isScriptLoaded ? (
        <div>
          <div className='hidden sm:block h-screen bg-gradient-to-r from-blue-900 to-cyan-600 '>
            <nav className='px-4 h-14 flex items-center space-x-2 xl:space-x-4'>
              <Link to='/'>
                <span className='sr-only'>Future Sphere</span>
                <img src={whiteLogoUrl} className='w-auto h-6' alt='Logo' />
              </Link>
              {navigation.map((item) => {
                if (item !== null) {
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`text-sm rounded-md text-white whitespace-nowrap bg-opacity-0 px-1 py-2 hover:bg-opacity-10`}
                    >
                      {item.name}
                    </Link>
                  );
                }
              })}
            </nav>
            <SplitPane
              split='vertical'
              sizes={verticalPanelSize}
              onChange={setVerticalPanelSize}
              sashRender={() => (
                <div className='h-full w-2 flex justify-center items-center bg-gray-200'>
                  <div className=' bg-gray-600 h-8 w-0.5 flex-shrink-0 opacity-60'></div>
                </div>
              )}
              style={{ height: 'calc( 100% - 56px)' }}
            >
              <Pane className='h-full'>
                <div className='h-full'>
                  {/* left side */}
                  <div className='h-full'>
                    {/* code editor */}
                    <div className='h-full bg-gray-50'>
                      <CodingEditor
                        value={code}
                        runningCode={runningCode}
                        handleRunCode={(code) => handleRunPythonCode(code)}
                        onCodeChange={(code: string) => handleCodeChange(code)}
                        editable={editable}
                        language={'python'}
                      />
                    </div>
                  </div>
                </div>
              </Pane>
              <Pane className='h-full'>
                <div className='h-full'>
                  <div className='h-full flex flex-col rightWrapper'>
                    <SplitPane
                      sashRender={() => (
                        <div className='h-ull w-full h-2 flex justify-center items-center bg-gray-200'>
                          <div className=' bg-gray-600 h-8 w-0.5 flex-shrink-0 opacity-60 rotate-90'></div>
                        </div>
                      )}
                      split='horizontal'
                      sizes={rightHorizontalPanelSize}
                      onChange={setRightHorizontalPanelSize}
                    >
                      <Pane>
                        <div className='h-full w-full bg-gray-50 pl-2 pt-2'>
                          <Terminal
                            terminalTab={terminalTab}
                            setTerminalTab={setTerminalTab}
                            terminalContent={terminalContent}
                            showOutputDot={showOutputDot}
                            setShowOutputDot={setShowOutputDot}
                          />
                        </div>
                      </Pane>
                      <Pane>
                        <div className='h-full w-full bg-gray-50 p-3'>
                          <div className='flex items-center gap-2'>
                            {isEditingFileName ? (
                              <div className='space-x-2'>
                                <input
                                  className='p-2 rounded-md text-sm'
                                  type='text'
                                  value={editFileName}
                                  onChange={(e) =>
                                    setEditFileName(e.target.value)
                                  }
                                />
                                <span className='text-gray-300'>.py</span>
                                <button
                                  className='text-gray-600 bg-white p-1 rounded-md'
                                  onClick={() => setIsEditingFileName(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className='text-gray-600 bg-mango-100 p-1 rounded-md'
                                  onClick={() => handleChangeFileName()}
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <div className='flex items-center'>
                                <p>{fileDetail?.filename || 'Loading...'}</p>
                                <button
                                  onClick={() => {
                                    setIsEditingFileName(true);
                                    setEditFileName(
                                      fileDetail?.filename.split('.')[0] || ''
                                    );
                                  }}
                                >
                                  <PencilAltIcon className='ml-1 h-5 w-5 text-gray-400' />
                                </button>
                              </div>
                            )}

                            {fileDetail?.joinCode ? (
                              <div className='p-1 px-2 flex items-center bg-white rounded-md'>
                                <LockClosedIcon className='h-5 w-5 text-gray-400' />
                                <p>{fileDetail.joinCode}</p>
                              </div>
                            ) : (
                              <button
                                onClick={() => requestNewJoinCode()}
                                className='bg-white rounded-md p-1 ml-2'
                              >
                                Request Join Code
                              </button>
                            )}
                          </div>

                          <div className='grid grid-cols-3 gap-3 mt-6'>
                            {spectatorList.map((spectator, index) => (
                              <p
                                key={index}
                                className='w-full col-span-1 bg-gray-100 rounded-md text-base p-1 text-gray-800'
                              >
                                {spectator.nickname}
                                {spectator.id === user.value.profile.id &&
                                  '(You)'}
                              </p>
                            ))}
                          </div>
                        </div>
                      </Pane>
                    </SplitPane>
                  </div>
                </div>
              </Pane>
            </SplitPane>
          </div>
          <div className='visible sm:hidden h-screen w-full flex justify-center items-center'>
            <h3 className='text-center w-full'>
              This page does not support mobile, please use labtop or ipad to
              see the content.
            </h3>
          </div>
        </div>
      ) : (
        <div className='visible sm:hidden h-screen w-full flex justify-center items-center'>
          <h3 className='text-center w-full'>Loading...</h3>
        </div>
      )}
    </>
  );
};

export default LiveSessionPage;
