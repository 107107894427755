import React, { useEffect } from 'react';
import { useAuth } from 'oidc-react';

const SSOSignInPage: React.FC = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.userData !== null) {
      window.location.replace('/');
      return;
    }
    auth.signIn();
  }, []);

  return (
    <div>
      <p>
        You are being redirected to the SSO login page. Please wait a moment..
      </p>
    </div>
  );
};

export default SSOSignInPage;
